
// Title of all templates.

// 0 Alice
//   & 
//   Bob
const title_0 = {
    web_ht: 280, // for real web
    tablet_ht: 170, // for real tablet

    mobile_ht_center: 240, // for real mobile
    mobile_ht_top: 240, // for real mobile that have background at the btm

    'WEB': {
        'stickytopnav': {  // menutype
            margin_top: 20,
            margin_btm: 20,
            margin_left: 40,
            margin_right: 40,
        },
        'btmnavbar': { // menutype
            margin_top: 40,
            margin_btm: 40,
            margin_left: 40,
            margin_right: 40,
        }
    },
    'TABLET': {
        'stickytopnav': { // menutype
            margin_top: 20,
            margin_btm: 20,
            margin_left: 0,
            margin_right: 0,
        },
        'btmnavbar': { // menutype
            margin_top: 40,
            margin_btm: 40,
            margin_left: 40,
            margin_right: 40,
        }
    },
    'MOBILE': {
        'stickytopnav': { // menutype
            margin_top: 0,
            margin_btm: 0,
            margin_left: 40,
            margin_right: 40,
        },
        'btmnavbar': { // menutype
            margin_top: 40,
            margin_btm: 40,
            margin_left: 40,
            margin_right: 40,
        }
    },

    websimulator_ht: 270 / 2.25,  // for simulator web
    tabletsimulator_ht: 170 / 2.5, // for simulator tablet, doesnt exist 
    mobilesimulator_ht: 350 / 2.5, // for simulator mobile
    title_layer1: 0.375,
    title_layer2: 0.25,
    title_layer3: 0.375,
}

// 1 Alice 
//   & Bob
const title_1 = {
    web_ht: 230,
    tablet_ht: 180,

    mobile_ht_center: 180,
    mobile_ht_top: 210,

    'WEB': {
        'stickytopnav': { // menutype
            margin_top: 20,
            margin_btm: 20,
            margin_left: 40,
            margin_right: 40,
        },
        'btmnavbar': { // menutype
            margin_top: 40,
            margin_btm: 40,
            margin_left: 40,
            margin_right: 40,
        }
    },
    'TABLET': {
        'stickytopnav': { // menutype
            margin_top: 20,
            margin_btm: 20,
            margin_left: 0,
            margin_right: 0,
        },
        'btmnavbar': { // menutype
            margin_top: 40,
            margin_btm: 40,
            margin_left: 0,
            margin_right: 0,
        }
    },
    'MOBILE': {
        'stickytopnav': { // menutype
            margin_top: 0,
            margin_btm: 0,
            margin_left: 40,
            margin_right: 40,
        },
        'btmnavbar': { // menutype
            margin_top: 0,
            margin_btm: 0,
            margin_left: 40,
            margin_right: 40,
        }
    },

    websimulator_ht: 230 / 2.25,
    tabletsimulator_ht: 200 / 2.5,
    mobilesimulator_ht: 350 / 3,
    title_layer1: 0.5,
    title_layer2: 0.5,
}

// 2 Alice & Bob
const title_2 = {
    web_ht: 190,
    tablet_ht: 180,

    mobile_ht_center: 160,
    mobile_ht_top: 210,

    'WEB': {
        'stickytopnav': {  // menutype
            margin_top: 40,
            margin_btm: 40,
            margin_left: 0,
            margin_right: 0
        },
        'btmnavbar': { // menutype
            margin_top: 40,
            margin_btm: 40,
            margin_left: 0,
            margin_right: 0,
        }
    },
    'TABLET': {
        'stickytopnav': { // menutype
            margin_top: 0,
            margin_btm: 0,
            margin_left: 0,
            margin_right: 0,
        },
        'btmnavbar': { // menutype
            margin_top: 40,
            margin_btm: 40,
            margin_left: 0,
            margin_right: 0,
        }
    },
    'MOBILE': {
        'stickytopnav': { // menutype
            margin_top: 0,
            margin_btm: 0,
            margin_left: 40,
            margin_right: 40,
        },
        'btmnavbar': { // menutype
            margin_top: 0,
            margin_btm: 0,
            margin_left: 40,
            margin_right: 40,
        }
    },

    websimulator_ht: 190 / 2.25,
    tabletsimulator_ht: 180 / 2.5,
    mobilesimulator_ht: 160 / 2.5,
}

const RSVPQNS_TEMPLATES = {
    'Multiple Choice': [
        [
            'Dietary Restrictions', // question
            0, // 0-multiple choice, 1-text
            'Dietary Restrictions', // short form of question for column name
            'all', // who can view? all or (category&indiviual)
            [], // categories tag
            [], // guestid
            [], // no use yet for future
            'dietaryrestrictions_uid', // qn uid
            [ // store all options
                [
                    'option1', // option text
                    [
                        ["1", 0, "1", "followupqn_id1", "1", "12", "13", "14"], // followup question
                        ["2", 0, "2", "followupqn_id2", "21", "27", "26", "25"]
                    ]
                ],
                ['option2', []],
                ['option3', []],
                ['option4', []]
            ]
        ]
    ],
    'Open-ended': [
        [
            'Wedding Advice', // question in txt
            1, // question type 0-multiple choice, 1-openended
            'Wedding Adv', // col name
            'all', // all or (category or individual)
            [], // tags
            [], // guestids 
            [], // might be needed in the future
            'weddingadvice_uid', // qn uid
        ],
        ['Special Request', 1, 'Special Request', 'all', [], [], [], 'specialrequest_uid'],
        ['Honey Moon Destinations', 1, 'Honey Moon Suggestion', 'all', [], [], [], 'honeymoon_uid'],
        ['Songs to add into the banquet playlist', 1, 'Wedding Songs', 'all', [], [], [], 'weddingsongs_uid']
    ]
}
export {
    title_0,
    title_1,
    title_2,
    RSVPQNS_TEMPLATES
}