import React, { useState } from 'react'
import { useEffect } from 'react'
import { INVITATION_READNAVAPP } from '../../../../../../config/api'
import { Checkdevice, Portalmodal } from '../../../../../../components'
import { useRef } from 'react'
import { STARTUP_NAME, WEBSITE_URL, guestbookbool, production } from '../../../../../../config/constants'
import { GALLERY_ICON, ITINERARY_ICON, RSVP_ICON, SEATINGS_ICON, RSVPTELEPHONE_ICON, GUESTBOOK_ICON, SMALLERMORE_ICON, ADD_ICON, CROSS_ICON, DRAG_ICON, SOCIALMEDIA_ICON, REGISTRY_ICON, PROMOTE_ICON } from '../../../../../../config/svgicons'
import { iconwrapper } from '../../../../../../config/commonfunctions'
import { inputdescribe_dom, input_dom, partialtitle_dom, radiobtn_dom, twobtn_dom } from '../Commonelements/Commonelements'
import { HexColorInput, HexColorPicker } from 'react-colorful'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Explain from '../Explain'
import axios from 'axios'
import './Navigation.css'

const MAXNAVAPP = 6
const input_css = { marginLeft: 0, marginRight: 0, width: '100%', margin: 0 }
const TITLE = 'Navigation'
const TEXT = 'Navigation menu/bar to browse your wedding website.'
const iconcss = { width: 30, height: 30, margin: 4, userSelect: 'none', pointerEvents: 'none', verticalAlign: 'middle', fill: 'currentColor', overflow: 'hidden' }
const smalliconcss = { width: 25, height: 25, margin: 4, userSelect: 'none', pointerEvents: 'none', verticalAlign: 'middle', fill: 'currentColor', overflow: 'hidden' }
const ALL_MENU_ITEMS = guestbookbool
    ? [
        'RSVP',
        'Gallery',
        'Contact',
        'Itinerary',
        'Seatings',
        'Guestbook',
        'Share',
        'More',
        'Promote',
        'Registry'
    ]
    : [
        'RSVP',
        'Gallery',
        'Contact',
        'Itinerary',
        'Seatings',
        'Share',
        'More',
        'Promote',
        'Registry'
    ]

const MENU_ITEMS_REQUIRED_LINK = guestbookbool
    ? [
        'Itinerary',
        'Seatings',
        'Guestbook'
    ]
    : [
        'Itinerary',
        'Seatings',
    ]

const MORE_MENU_ITEMS = guestbookbool
    ? [
        'RSVP',
        'Gallery',
        'Contact',
        'Itinerary',
        'Seatings',
        'Guestbook',
        'Promote',
        'Share',
        'Promote',
        'Registry'
    ]
    : [
        'RSVP',
        'Gallery',
        'Contact',
        'Itinerary',
        'Seatings',
        'Promote',
        'Share',
        'Promote',
        'Registry'
    ]

function Navigation(props) {
    const { menutype = 'btmnavbar', menuicongate, updateMenuicongate, menustyle, updateMenumore, updateMenustyle, updateMenutype, updateMenuitems, token, menuitemsedited, menuseq, updateMenuseq, fc, templatecolor, updateSpecificcompff, updateBackup, clearBackup, btmbarff, priff, secff, onSave, restoreBackup } = props

    const [currapp, setCurrapp] = useState('') // current selected app
    const [currappportal, setCurrappportal] = useState(false)
    const [currlinks, setCurrlinks] = useState([])
    const [menuitems, setMenuitems] = useState({})
    const [addcompportal, setAddcompportal] = useState(false)
    const [bgcolor, setBgcolor] = useState('')
    const [color, setColor] = useState('')
    const [bordercolor, setBordercolor] = useState('')
    const [colorvariety, setColorvariety] = useState(['#333333', '#ffffff', '#f5f5dc'])
    const [selectednavportion, setSelectednavportion] = useState(-1)
    const [addmoreportal, setAddmoreportal] = useState(false)
    const [menumore, setMenumore] = useState([])
    const [showsave, setShowsave] = useState(false)
    const moreref = useRef(null)
    const device = Checkdevice()

    const portalmidcss = (device === 'WEB' || device === 'TABLET')
        ? { position: 'fixed', top: 'calc(50% - 10px)', transform: 'translate(-50%, -50%)', width: 300, height: 'max-content', margin: '10px 0px', right: 0 }
        : { position: 'fixed', width: '100%', height: '100%', margin: 0, top: '50%' }

    useEffect(() => {
        const { backgroundColor, color, borderColor } = menustyle
        setBgcolor(backgroundColor)
        setColor(color)
        setBordercolor(borderColor)

        var new_colorvariety = colorvariety.slice()
        if (colorvariety.indexOf(templatecolor) === -1) { // not found
            new_colorvariety = [templatecolor, ...new_colorvariety]
        }
        if (colorvariety.indexOf(fc) === -1) { // not found
            new_colorvariety = [fc, ...new_colorvariety]
        }
        setColorvariety(new_colorvariety)

    }, [props.menustyle, props.templatecolor, props.fc])

    useEffect(() => {
        setMenuitems(props.menuitems)
    }, [props.menuitems])

    useEffect(() => {
        setMenumore(props.menumore)
    }, [props.menumore])

    useEffect(() => {
        if (currapp === 'Itinerary' || currapp === 'Seatings' || currapp === 'Guestbook') {
            try {
                let options = {
                    method: 'POST',
                    url: INVITATION_READNAVAPP,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                    data: { app: currapp }
                }
                axios(options).then((item) => {
                    const { items } = item.data
                    setCurrlinks(items)
                }).catch((e) => { })
            }
            catch (e) { }
        }
        else {
            setCurrlinks([])
        }
    }, [currapp])

    const onDragendmore = (result) => {
        const { source, destination } = result
        if (!destination) { return }
        if (!showsave) { updateBackup(); setShowsave(true); }

        let curr_menumore = menumore.slice()
        const [removed] = curr_menumore.splice(source.index, 1);
        curr_menumore.splice(destination.index, 0, removed);

        updateMenumore(curr_menumore)
        setMenumore(curr_menumore)
    }

    const onDragenddisplay = (result) => {
        const { source, destination } = result
        if (!destination) { return }
        if (!showsave) { updateBackup(); setShowsave(true); }

        let curr_menuseq = menuseq.slice()
        const [removed] = curr_menuseq.splice(source.index, 1);
        curr_menuseq.splice(destination.index, 0, removed);

        updateMenuseq(curr_menuseq)
    }

    const LOGO_MAP = {
        'RSVP': iconwrapper(RSVP_ICON, iconcss),
        'Gallery': iconwrapper(GALLERY_ICON, iconcss),
        'Itinerary': iconwrapper(ITINERARY_ICON, iconcss),
        'Contact': iconwrapper(RSVPTELEPHONE_ICON, iconcss),
        'Seatings': iconwrapper(SEATINGS_ICON, iconcss),
        'Guestbook': iconwrapper(GUESTBOOK_ICON, iconcss),
        'Share': iconwrapper(SOCIALMEDIA_ICON, iconcss),
        'More': iconwrapper(SMALLERMORE_ICON, iconcss),
        'Registry': iconwrapper(REGISTRY_ICON, iconcss),
        'Promote': iconwrapper(PROMOTE_ICON, iconcss),
    }

    const SMALLLOGO_MAP = {
        'RSVP': iconwrapper(RSVP_ICON, smalliconcss),
        'Gallery': iconwrapper(GALLERY_ICON, smalliconcss),
        'Itinerary': iconwrapper(ITINERARY_ICON, smalliconcss),
        'Contact': iconwrapper(RSVPTELEPHONE_ICON, smalliconcss),
        'Seatings': iconwrapper(SEATINGS_ICON, smalliconcss),
        'Guestbook': iconwrapper(GUESTBOOK_ICON, smalliconcss),
        'Share': iconwrapper(SOCIALMEDIA_ICON, smalliconcss),
        'More': iconwrapper(SMALLERMORE_ICON, smalliconcss),
        'Registry': iconwrapper(REGISTRY_ICON, smalliconcss),
        'Promote': iconwrapper(PROMOTE_ICON, smalliconcss),
    }

    const Navmenuitem = ({ item, onClose, onClickval, onClickid, iconcolor, drag_dom, menuicongate }) => {

        const logobg_color = bgcolor

        const logo_btn = <button
            style={{ height: 'calc( 100% - 30px )', width: '100%', border: 'none', borderBottom: `1px dashed ${color}`, margin: 0, padding: 0, backgroundColor: logobg_color, display: 'flex', flexDirection: 'column', placeContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: 5 }}
            value={item}
            onClick={onClickval}
        >
            {menuicongate ? <div id={item} style={{ color: iconcolor, pointerEvents: 'none' }}>{LOGO_MAP[item]}</div> : null}
            <div id={item} style={{ color: iconcolor, paddingTop: 5, pointerEvents: 'none' }}>{item}</div>
        </button>

        const close_btn = <button
            onClick={onClose}
            style={{ top: 0, left: 0, position: 'absolute', backgroundColor: logobg_color, border: 'none', width: 'max-content', display: 'flex', placeContent: 'center', alignItems: 'center', margin: 5, padding: 0 }}
        >
            {iconwrapper(CROSS_ICON, { width: 20, height: 20, display: 'flex', placeContent: 'center', color: 'var(--mainpink)' })}
        </button>

        return <div style={{ display: 'flex', flexDirection: 'row', width: 90, minWidth: 90, placeContent: 'center', position: 'relative', background: 'transparent', position: 'relative', overflow: 'hidden', borderRadius: 10, overflow: 'hidden', margin: '0px 5px' }} >
            {close_btn}
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 135, alignItems: 'center', placeContent: 'center', border: `1px dashed ${color}`, borderRadius: 10, overflow: 'hidden', backgroundColor: logobg_color }}>
                {logo_btn}
                {drag_dom}
            </div>
        </div>

    }

    const getMenulinks = (urlhandler, passcode) => {
        if (currapp === 'Itinerary') {
            return WEBSITE_URL + '/timelinelookup/' + urlhandler + (passcode.length > 0 ? ('/passcode=' + passcode) : '')
        }
        else if (currapp === 'Seatings') {
            return WEBSITE_URL + '/seatingchartlookup/' + urlhandler + (passcode.length > 0 ? ('/passcode=' + passcode) : '')
        }
        else if (currapp === 'Guestbook') {
            return WEBSITE_URL + '/guestbook/' + urlhandler + (passcode.length > 0 ? ('/' + passcode) : '')
        }
    }

    const add_portal = addcompportal
        ? <Portalmodal
            portaltype={'commonportal'}
            open={addcompportal}
            onClose={() => setAddcompportal(false)}
            floatingclose={true}
            backdropvisible={true}
            modalstyle={{ width: 195, height: 'max-content' }}
            floatingclosecolor='#eb5d7b'
        >
            <div className='ieditnavdropdown'>
                {ALL_MENU_ITEMS.map((item, index) => {
                    return menuseq.indexOf(item) > -1
                        ? null
                        : <button
                            key={'portalitem' + index}
                            className='ieditnavdropdownbtn'
                            onClick={(e) => {
                                var new_menuseq = menuseq.slice()
                                new_menuseq.push(e.target.value)
                                updateMenuseq(new_menuseq)
                                setAddcompportal(false)
                            }}
                            value={item}
                        >
                            <div className='ieditnavbarportallogo'>{SMALLLOGO_MAP[item]}</div>
                            <div className='ieditnavbaritemtext'>{item}</div>
                        </button>
                })}
            </div>
        </Portalmodal>
        : null

    const radio_old = <div className='Details_grp'>
        {radiobtn_dom(
            menutype === 'btmnavbar' ? true : false,
            () => {
                if (!showsave) { updateBackup(); setShowsave(true); }
                updateMenutype(menutype === 'btmnavbar' ? 'none' : 'btmnavbar')
            },
            menutype === 'btmnavbar' ? `Navigation Enabled` : `Navigation Disabled`,
            { margin: 0 }
        )}
    </div>

    const updatenavbar = (e) => {
        const { value } = e.target
        if (!showsave) { updateBackup(); setShowsave(true); }
        updateMenutype(value)
    }

    const radio = <div className='Details_grp'>
        <div className='ieditnavrow'>
            <button
                style={{ borderRadius: '10px 0px 0px 10px', borderRight: 'none' }} className={`iedtinavbtnnavtype ${menutype === 'none' ? 'iedtinavbtnnavtype_selected' : ''}`}
                value={'none'}
                onClick={updatenavbar}
            >
                None
            </button>
            <button
                className={`iedtinavbtnnavtype ${menutype === 'btmnavbar' ? 'iedtinavbtnnavtype_selected' : ''}`}
                value={'btmnavbar'}
                onClick={updatenavbar}
            >
                Bottom Navigation Bar
            </button>
            <button
                style={{ borderRadius: '0px 10px 10px 0px', borderLeft: 'none' }}
                className={`iedtinavbtnnavtype ${menutype === 'stickytopnav' ? 'iedtinavbtnnavtype_selected' : ''}`}
                value={'stickytopnav'}
                onClick={updatenavbar}
            >
                Top Navigation Bar
            </button>
        </div>

    </div>


    const onAddmenuseq = (e) => {
        if (menuseq.length < MAXNAVAPP) {
            if (!showsave) { updateBackup(); setShowsave(true); }
            var new_menuseq = menuseq.slice()
            new_menuseq.push(e.target.value)
            updateMenuseq(new_menuseq)
            setAddcompportal(false)
        }
    }

    const addcomp_portal = <Portalmodal
        portaltype="commonportal"
        open={addcompportal}
        onClose={() => setAddcompportal(false)}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={portalmidcss}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        <div className='Rsvpexplorer_portal'>
            <div className='Rsvpexplorer_portaltitle'>Add Component</div>
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                {ALL_MENU_ITEMS.filter((x) => { return !menuseq.includes(x) }).map((item, i) => {
                    return <button
                        className='Portalinnerelem'
                        key={item + '_' + i}
                        value={item}
                        style={{
                            borderRadius: (ALL_MENU_ITEMS.filter((x) => { return !menuseq.includes(x) })).length === 1 && i === 0
                                ? 10
                                : i === 0
                                    ? '10px 10px 0px 0px'
                                    : i === ALL_MENU_ITEMS.length - 1
                                        ? '0px 0px 10px 10px'
                                        : 0,
                        }}
                        onClick={onAddmenuseq}>
                        <div className='ieditnavbarportallogo' style={{ marginRight: 20 }}>{SMALLLOGO_MAP[item]}</div>
                        <div className='ieditnavbaritemtext' style={{ width: 'calc( 100% - 40px )' }}>{item}</div>
                    </button>
                })}
            </div>
        </div>
    </Portalmodal>

    const selectcomp_portal = <Portalmodal
        portaltype="commonportal"
        open={currappportal}
        onClose={() => setCurrappportal(false)}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={portalmidcss}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        <div className='Rsvpexplorer_portal'>
            <div className='Rsvpexplorer_portaltitle'>Select Component</div>
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                {MENU_ITEMS_REQUIRED_LINK.map((item, i) => {
                    return <button
                        className='Portalinnerelem'
                        key={item + '_' + i}
                        value={item}
                        style={{
                            borderRadius: (ALL_MENU_ITEMS.filter((x) => { return !menuseq.includes(x) })).length === 1 && i === 0
                                ? 10
                                : i === 0
                                    ? '10px 10px 0px 0px'
                                    : i === ALL_MENU_ITEMS.length - 1
                                        ? '0px 0px 10px 10px'
                                        : 0,
                        }}
                        onClick={(e) => {
                            setCurrapp(e.target.value);
                            setCurrappportal(false)
                            setCurrlinks([])
                        }}>
                        <div className='ieditnavbarportallogo' style={{ marginRight: 20 }}>{SMALLLOGO_MAP[item]}</div>
                        <div className='ieditnavbaritemtext' style={{ width: 'calc( 100% - 40px )' }}>{item}</div>
                        <div>
                            {(item === 'RSVP' || item === 'Gallery' || item === 'Contact')
                                ? 'Auto Linkage'
                                : (item === 'Guestbook' || item === 'Itinerary' || item === 'Seatings')
                                    ? menuitems[item] && menuitems[item][menuitems[item]['linkorurl']].length > 0 // ? menuitems[item] && menuitems[item].length > 0
                                        ? 'Linked'
                                        : 'Not Linked'
                                    : ''
                            }
                        </div>
                    </button>
                })}
            </div>
        </div>
    </Portalmodal>

    const selectmore_portal = <Portalmodal
        portaltype="commonportal"
        open={addmoreportal}
        onClose={() => setAddmoreportal(false)}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={portalmidcss}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        <div className='Rsvpexplorer_portal'>
            <div className='Rsvpexplorer_portaltitle'>Update "More"</div>
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                {MORE_MENU_ITEMS.filter((x) => { return !menumore.includes(x) }).map((item, i) => {
                    return <button
                        className='Portalinnerelem'
                        key={item + '_' + i}
                        value={item}
                        style={{
                            borderRadius: (MORE_MENU_ITEMS.filter((x) => { return !menumore.includes(x) })).length === 1 && i === 0
                                ? 10
                                : i === 0
                                    ? '10px 10px 0px 0px'
                                    : i === MORE_MENU_ITEMS.length - 1
                                        ? '0px 0px 10px 10px'
                                        : 0
                        }}
                        onClick={(e) => {
                            if (!showsave) { updateBackup(); setShowsave(true); }
                            var new_menuseq = menumore.slice()
                            new_menuseq.push(e.target.value)
                            updateMenumore(new_menuseq)
                            setAddmoreportal(false)
                        }}>
                        <div className='ieditnavbarportallogo' style={{ marginRight: 20 }}>{SMALLLOGO_MAP[item]}</div>
                        <div className='ieditnavbaritemtext' style={{ width: 'calc( 100% - 40px )' }}>{item}</div>
                    </button>
                })}
            </div>
        </div>
    </Portalmodal>

    const onSeqremove = (i) => {
        if (!showsave) { updateBackup(); setShowsave(true); }

        var new_menuseq = menuseq.slice()
        new_menuseq.splice(i, 1)
        updateMenuseq(new_menuseq)
    }

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? '#707070' : 'lightgrey',
        height: 'max-content'
    });

    const getListStyleDisplay = isDraggingOver => ({
        background: isDraggingOver ? '#707070' : bgcolor,
        height: 'max-content'
    });

    const navdisplay = <div className='Details_grp'>
        {partialtitle_dom('Navigation Display ( Max 6 )', { margin: 0 })}
        {inputdescribe_dom(`Add and edit navigation components to your RSVP website.`, { margin: 0 })}
        {menutype === 'stickytopnav'
            ? null
            : <button
                key={menuicongate + 'menuicongate'}
                className={menuicongate ? 'iediticonbtn_selected' : 'iediticonbtn_unselected'}
                onClick={() => {
                    if (!showsave) { updateBackup(); setShowsave(true); }
                    updateMenuicongate(!menuicongate)
                }}
            >
                {menuicongate ? 'Disable Icon' : 'Enable Icon'}
            </button>}
        <DragDropContext onDragEnd={onDragenddisplay}>
            <Droppable droppableId={'droppable'} direction="horizontal">
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{ ...getListStyleDisplay(snapshot.isDraggingOver), fontFamily: btmbarff, display: 'flex', flexDirection: 'row', overflowX: 'auto', position: 'relative', width: 'max-content', padding: 10, border: `1px solid ${bordercolor}`, height: 'max-content', width: '100%', backgroundColor: bgcolor }}
                    >
                        {menuseq.map((item, i) => <Draggable
                            key={item + '_' + i}
                            draggableId={item}
                            index={i}
                        >
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                >
                                    <Navmenuitem
                                        key={i}
                                        item={item}
                                        menuicongate={menuicongate && menutype === 'btmnavbar'}
                                        iconcolor={color}
                                        onClickval={(e) => { // for button
                                            if (e.target.value === 'More') {
                                                moreref.current.scrollIntoView()
                                            }
                                            else if (e.target.value) {
                                                setCurrapp(e.target.value)
                                            }
                                        }}
                                        onClickid={(e) => { // for div
                                            if (e.target.id === 'More') {
                                                moreref.current.scrollIntoView()
                                            }
                                            else if (e.target.id) {

                                                setCurrapp(e.target.id.toString())
                                            }
                                        }}
                                        onClose={() => {
                                            onSeqremove(i)
                                        }}
                                        drag_dom={
                                            <span {...provided.dragHandleProps} style={{ width: '50%', display: 'flex', placeContent: 'center', alignItems: 'center', padding: 0, margin: 0, height: 30, backgroundColor: bgcolor, color: color, }}>
                                                {iconwrapper(DRAG_ICON, { width: 20, height: 20, display: 'flex', placeContent: 'center', margin: 5 })}
                                            </span>
                                        }
                                    />

                                </div>
                            )}
                        </Draggable>)}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
        <button style={{ backgroundColor: 'transparent', alignContent: 'center', border: 'none', placeContent: 'center', display: 'flex', margin: '10px 0px' }} onClick={() => { setAddcompportal(true) }}>
            {iconwrapper(ADD_ICON, { width: 20, height: 20, display: 'flex', placeContent: 'center', fill: 'var(--maindark)' })}
        </button>
    </div>


    const linkorurl_dom = menuitems && menuitems[currapp] && currapp === 'Itinerary'
        ? <div className="Details_grp" style={{ display: 'flex', flexDirection: 'row' }}>
            <button
                value={'link'}
                className={`schedules_leftbtn ${menuitems[currapp]['linkorurl'] === 'link' ? 'schedules_btnselected' : ''}`}
                onClick={() => {
                    if (!showsave) { updateBackup(); setShowsave(true); }
                    var curr_menuitems = JSON.parse(JSON.stringify(menuitems))
                    curr_menuitems[currapp]['linkorurl'] = curr_menuitems[currapp]['linkorurl'] === 'link' ? 'url' : 'link'
                    updateMenuitems(curr_menuitems)
                    setMenuitems(curr_menuitems)
                }}
            >
                {`${STARTUP_NAME} Link`}
            </button>
            <div style={{ height: 60, backgroundColor: '#707070', width: 1 }} />
            <button
                value={'url'}
                className={`schedules_rightbtn ${menuitems[currapp]['linkorurl'] === 'url' ? 'schedules_btnselected' : ''}`}
                onClick={() => {
                    if (!showsave) { updateBackup(); setShowsave(true); }
                    var curr_menuitems = JSON.parse(JSON.stringify(menuitems))
                    curr_menuitems[currapp]['linkorurl'] = curr_menuitems[currapp]['linkorurl'] === 'url' ? 'link' : 'url'
                    updateMenuitems(curr_menuitems)
                    setMenuitems(curr_menuitems)
                }}
            >
                External Url
            </button>
        </div>
        : null

    const titleurllink_dom = (menuitems && menuitems[currapp] && currapp === 'Itinerary')
        ? <div className="Details_grp">
            {input_dom(
                'Title',
                'Title of your event',
                menuitems[currapp]['title'],
                (event) => {
                    if (!showsave) { updateBackup(); setShowsave(true); }
                    const val = event.target.value;
                    let new_menuitems = JSON.parse(JSON.stringify(menuitems))
                    new_menuitems[currapp]['title'] = val
                    updateMenuitems(new_menuitems)
                    setMenuitems(new_menuitems)
                },
                'itinerarytitle_id',
                {},
                false,
                null,
                {},
                input_css)}
        </div>
        : null

    const link_dom = currlinks.length > 0 && (currapp === 'Itinerary' || currapp === 'Seatings' || currapp === 'Guestbook')
        ? <>
            {titleurllink_dom}
            {linkorurl_dom}
            <div className="ieditnavbarurlitemroot">
                {currlinks.map((linkdata) => {
                    const { passcode, urlhandler, _id } = linkdata // _id
                    return <div
                        key={_id}
                        className={`ieditnavbarurlitem ${menuitems[currapp]['link'] === getMenulinks(urlhandler, passcode) ? 'ieditnavbarurlitemselected' : ''}`}
                        style={{ width: 'fit-content', minWidth: '100%' }}
                        onClick={() => {

                            const { passcode, urlhandler } = linkdata // _id

                            if (!showsave) { updateBackup(); setShowsave(true); }

                            let finalurl
                            var curr_menuitems = menuitems

                            if (currapp === 'Itinerary') {
                                finalurl = WEBSITE_URL + '/timelinelookup/' + urlhandler + (passcode.length > 0 ? ('/passcode=' + passcode) : '')
                                curr_menuitems['Itinerary']['link'] = curr_menuitems['Itinerary']['link'] === finalurl ? '' : finalurl
                            }
                            else if (currapp === 'Seatings') {
                                finalurl = WEBSITE_URL + '/seatingchartlookup/' + urlhandler + (passcode.length > 0 ? ('/passcode=' + passcode) : '')
                                curr_menuitems['Seatings']['link'] = curr_menuitems['Seatings']['link'] === finalurl ? '' : finalurl
                            }
                            else if (currapp === 'Guestbook') {

                                finalurl = WEBSITE_URL + '/guestbook/' + urlhandler + (passcode.length > 0 ? `/${passcode}` : '')
                                curr_menuitems['Guestbook']['link'] = curr_menuitems['Guestbook']['link'] === finalurl ? '' : finalurl
                            }
                            updateMenuitems(curr_menuitems)
                            setMenuitems(curr_menuitems)

                        }}
                    >
                        {currapp === 'Itinerary' ? WEBSITE_URL + '/timelinelookup/' + urlhandler + '/passcode=' + passcode : null}
                        {currapp === 'Seatings' ? WEBSITE_URL + '/seatingchartlookup/' + urlhandler + '/passcode=' + passcode : null}
                        {currapp === 'Guestbook' ? WEBSITE_URL + '/guestbook/' + urlhandler + '/' + passcode : null}
                    </div>
                })}
            </div>
        </>
        : currapp.length !== 0
            ? <div className="ieditnavbarurlitemroot">Do not requrie link . {currapp}</div>
            : null

    const url_dom = currapp.length !== 0
        ? <div style={{ display: 'flex', flexDirection: 'column' }}>
            {titleurllink_dom}
            {linkorurl_dom}

            {menuitems && menuitems[currapp] && menuitems[currapp]['linkorurl'] === 'url'
                ? input_dom(
                    '',
                    'https://somelink.com',
                    menuitems['Itinerary']['link'],
                    (event) => {
                        if (!showsave) { updateBackup(); setShowsave(true); }
                        const val = event.target.value;
                        let new_menuitems = JSON.parse(JSON.stringify(menuitems))
                        new_menuitems['Itinerary']['link'] = val
                        updateMenuitems(new_menuitems)
                        setMenuitems(new_menuitems)
                    },
                    'itinerarytitle_id',
                    {},
                    false,
                    null,
                    {},
                    input_css)
                : null}

        </div>
        : null

    const navcompedit = <div className='Details_grp'>
        {partialtitle_dom('Navigation Link', { margin: 0 })}
        {inputdescribe_dom(`Assign link to navigation component.`, { margin: 0 })}
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            {(currapp && currapp.length > 0)
                ? <button
                    style={{ width: 78, minWidth: 78, height: 135, borderRadius: 10, backgroundColor: 'white', border: '1px dashed var(--maindark)', display: 'flex', flexDirection: 'column', placeContent: 'center', alignItems: 'center', whiteSpace: 'pre-wrap', textAlign: 'center', border: 0, margin: '5px 5px 0px 0px', border: '1px solid var(--maindark)' }}
                    onClick={() => { setCurrappportal(true) }}
                >
                    <div>{LOGO_MAP[currapp]}</div>
                    <div>{currapp}</div>
                </button>
                : <button
                    style={{ width: 78, minWidth: 78, marginRight: 5, height: 135, marginTop: 5, borderRadius: 10, backgroundColor: 'white', border: '1px dashed var(--maindark)', display: 'flex', flexDirection: 'column', placeContent: 'center', alignItems: 'center', whiteSpace: 'pre-wrap', textAlign: 'center' }}
                    onClick={() => { setCurrappportal(true) }}
                >
                    <div>Click</div>
                    <div>To</div>
                    <div>Select</div>
                </button>}
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5, width: 'calc( 100% - 83px )' }}>
                {menuitems && Object.keys(menuitems).length > 0 && (currapp === 'Itinerary' || currapp === 'Seatings' || currapp === 'Guestbook') // only itinerary, seatings and guestbook have linkorurl
                    ? { 'link': link_dom, 'url': url_dom }[menuitems[currapp]['linkorurl']]
                    : currapp.length !== 0
                        ? <div className="ieditnavbarurlitemroot">Do not requrie link . {currapp}</div>
                        : null}
            </div>

        </div>
    </div>

    const navmoreedit = <div className='Details_grp' ref={moreref}>
        {partialtitle_dom('More', { margin: 0 })}
        {inputdescribe_dom(`Decide on what to be shown in 'More'`, { margin: 0 })}
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <button style={{ width: 78, minWidth: 78, height: 135, borderRadius: 10, backgroundColor: 'white', border: '1px dashed var(--maindark)', display: 'flex', flexDirection: 'column', placeContent: 'center', alignItems: 'center', whiteSpace: 'pre-wrap', textAlign: 'center', border: 0, margin: '5px 5px 0px 0px', border: '1px solid var(--maindark)' }}>
                <div>{LOGO_MAP['More']}</div>
                <div>More</div>
            </button>
            <div style={{ margin: '5px 0px', display: 'flex', flexDirection: 'column', width: 'calc( 100% - 83px )' }}>
                <div style={{ overflow: 'auto', display: 'flex', flexDirection: 'column', border: '1px solid var(--maindark)', borderRadius: 10, width: '100%' }}>
                    <DragDropContext onDragEnd={onDragendmore}>
                        <Droppable droppableId={'droppable'}>
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    {menumore.map((item, i) => {
                                        return <Draggable
                                            key={item + '_' + i}
                                            draggableId={item}
                                            index={i}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <div style={{ padding: 10, borderBottom: '1px solid var(--subgrey)', display: 'flex', flexDirection: 'row', placeContent: 'space-between', alignItems: 'center', backgroundColor: 'white' }}>
                                                        {/* close icon */}
                                                        <button
                                                            style={{ width: 'max-content', height: 'max-content', padding: 0, margin: '0px 10px 0px 0px', backgroundColor: 'transparent', border: 'none', color: 'var(--mainpink)' }}
                                                            onClick={() => {
                                                                if (!showsave) { updateBackup(); setShowsave(true); }

                                                                var new_menumore = menumore.slice()
                                                                new_menumore.splice(i, 1)
                                                                updateMenumore(new_menumore)
                                                            }}
                                                        >
                                                            {iconwrapper(CROSS_ICON, { width: 20, height: 20, display: 'flex', placeContent: 'center', margin: 5 })}
                                                        </button>

                                                        {/* RSVP / Gallery / ... */}
                                                        <div style={{ width: '100%' }}>{item}</div>

                                                        {/* drag icon */}
                                                        <span id='Rsvpexplorer_dragicon' {...provided.dragHandleProps} >
                                                            {iconwrapper(DRAG_ICON, { width: 20, height: 20, display: 'flex', placeContent: 'center', margin: 5 })}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <button
                        id='close_btn'
                        style={{ height: '100%', width: '100%', border: 'none', backgroundColor: 'transparent', display: 'flex', placeContent: 'center', alignItems: 'center', margin: '10px 0px' }}
                        onClick={() => setAddmoreportal(true)}
                    >
                        {iconwrapper(ADD_ICON, { width: 20, height: 20, display: 'flex', placeContent: 'center' })}
                    </button>
                </div>
            </div>
        </div>
    </div>

    {/* 

    <div style={{width:100}}>
        {menuitems[item]
            ? 'Linked'
            : (currapp === 'Itinerary' || currapp === 'Seatings' || currapp === 'Guestbook')
                ? 'Not Link'
                : '-'}
    </div>

    */}

    const palette_dom = (update, color,) => {
        return <div className='Detailsfont_container' style={{ margin: '5px 10px 10px 10px' }}>
            <div className='Detailsfont_left'>
                <HexColorPicker
                    style={{ height: 200, width: 200 }}
                    color={color}
                    onChange={(c) => update(c)}
                />
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, alignItems: 'center' }}>
                    <div style={{ paddingRight: 3, fontSize: 18 }}>#</div>
                    <HexColorInput
                        color={color}
                        onChange={(c) => update(c)}
                        style={{ width: '100%', borderRadius: 8, padding: '3px 5px', border: '1px solid var(--maindark)' }}
                    />
                </div>
            </div>
            <ul className='Detailsfont_right'>
                {colorvariety.map((c, i) => {
                    return <div key={i + 'color1'}
                        style={{ border: color === c ? '2px solid var(--mainpink)' : '2px solid transparent', padding: 1, width: 'max-content', height: 'max-content', display: 'flex', placeContent: 'center', alignItems: 'center', borderRadius: 5 }}>
                        <li
                            style={{ width: 20, height: 20, backgroundColor: c, border: 'none', cursor: 'pointer', borderRadius: 3, border: color === c ? 'none' : '1px solid #dddddd' }}
                            onClick={() => update(c)}
                        />
                    </div>
                })}
            </ul>
        </div>
    }

    const onColorstylechange = (c, key) => {

        if (!showsave) { updateBackup(); setShowsave(true); }

        if (key === 'backgroundColor') {
            setBgcolor(c);
        }
        else if (key === 'color') {
            setColor(c)
        }
        else if (key === 'borderColor') {
            setBordercolor(c)
        }

        var new_menustyle = menustyle
        new_menustyle[key] = c
        updateMenustyle(new_menustyle)
    }

    const navcolor = <div className='Details_grp'>
        {partialtitle_dom('Navigation Color', { margin: 0 })}
        {inputdescribe_dom(`Navigation color functions`, { margin: 0 })}
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
            <button
                key={'titlebtn_0'}
                className={`Details_settingbtn ${selectednavportion === 0 ? 'Details_selectedbtn' : selectednavportion !== -1 ? 'Details_selectednone' : ''}`}
                style={{ width: 100, height: 45 }}
                value={0}
                onClick={(e) => { setSelectednavportion(selectednavportion === parseInt(e.target.value) ? -1 : parseInt(e.target.value)) }}
            >
                Background
            </button>
            {menutype === 'btmnavbar'
                ? <button
                    key={'titlebtn_1'}
                    className={`Details_settingbtn ${selectednavportion === 1 ? 'Details_selectedbtn' : selectednavportion !== -1 ? 'Details_selectednone' : ''}`}
                    style={{ width: 100, height: 45 }}
                    value={1}
                    onClick={(e) => { setSelectednavportion(selectednavportion === parseInt(e.target.value) ? -1 : parseInt(e.target.value)) }}
                >
                    Icon
                </button>
                : null}
            <button
                key={'titlebtn_2'}
                className={`Details_settingbtn ${selectednavportion === 2 ? 'Details_selectedbtn' : selectednavportion !== -1 ? 'Details_selectednone' : ''}`}
                style={{ width: 100, height: 45, }}
                value={2}
                onClick={(e) => { setSelectednavportion(selectednavportion === parseInt(e.target.value) ? -1 : parseInt(e.target.value)) }}
            >
                Border
            </button>
        </div>

        {[
            <div className='Details_lineacontainer'>
                {partialtitle_dom('Background', { margin: '10px 10px 0px 10px' })}
                {inputdescribe_dom(`Background color of your navigation bar.`, { marginLeft: 10 })}
                {palette_dom((c) => onColorstylechange(c, 'backgroundColor'), bgcolor)}
            </div>,
            <div className='Details_lineacontainer'>
                {partialtitle_dom('Icon', { margin: '10px 10px 0px 10px' })}
                {inputdescribe_dom(`Icon color of your navigation bar.`, { marginLeft: 10 })}
                {palette_dom((c) => onColorstylechange(c, 'color'), color)}
            </div>,
            <div className='Details_lineacontainer'>
                {partialtitle_dom('Border', { margin: '10px 10px 0px 10px' })}
                {inputdescribe_dom(`Border color of your navigation bar.`, { marginLeft: 10 })}
                {palette_dom((c) => onColorstylechange(c, 'borderColor'), bordercolor)}
            </div>,
        ][selectednavportion]}



        {/*
        <div className='Detailsfont_container'>
            <div className='Detailsfont_left'>
                <HexColorPicker style={{ height: 200, width: 200 }} color={props.fc} onChange={(c) => props.updateFc(c)} />
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, alignItems: 'center' }}>
                    <div style={{ paddingRight: 3, fontSize: 18 }}>#</div>
                    <HexColorInput color={props.fc} onChange={(c) => props.updateFc(c)} style={{ width: '100%', borderRadius: 8, padding: '3px 5px', border: '1px solid var(--maindark)' }} />
                </div>
            </div>
            <ul className='Detailsfont_right'>
                {colors_arr.map((color, i) => {
                    return <div key={i + 'color1'} style={{ padding: 1, width: 'max-content', height: 'max-content', display: 'flex', placeContent: 'center', alignItems: 'center', border: props.fc === color ? '2px solid var(--mainpink)' : '2px solid transparent', borderRadius: 5 }}>
                        <li style={{ width: 20, height: 20, backgroundColor: color, border: 'none', cursor: 'pointer', borderRadius: 3 }} onClick={() => props.updateFc(color)} />
                    </div>
                })}
            </ul>
        </div> 
        */}

    </div>

    const navff = <div className='Details_grp'>
        {partialtitle_dom('Navigation Font-family', { margin: 0 })}
        {inputdescribe_dom(`Font-family of navigation component. Default is set to 'Primary Font'`, { margin: 0 })}
        {twobtn_dom(
            <>
                <div style={{ pointerEvents: 'none', overflow: 'hidden', }} >Primary Font</div>
                <div style={{ pointerEvents: 'none', overflow: 'hidden', fontSize: 12 }} >{` ( ${priff} ) `} </div>
            </>,
            <>
                <div style={{ pointerEvents: 'none', overflow: 'hidden', }} >Secondary Font</div>
                <div style={{ pointerEvents: 'none', overflow: 'hidden', fontSize: 12 }} >{` ( ${secff} ) `} </div>
            </>,
            'Primary Font', 'Secondary Font',
            btmbarff === priff ? 'Primary Font' : 'Secondary Font',
            () => {
                if (!showsave) { updateBackup(); setShowsave(true); }
                updateSpecificcompff()
            },
            { height: 55 }
        )}
    </div>

    const btmbar = <div
        className='iedit_swaproot'
        style={{
            'MOBILE': { position: 'fixed', bottom: 0, left: 0, width: '100%' },
            'TABLET': { position: 'fixed', bottom: 10, left: 0, width: (props.wt * 0.45) - 1 },
            'WEB': { position: 'fixed', bottom: 0, left: 0, width: (props.wt * 0.45) - 1 }
        }[device]}
    >
        <button
            className='iedit_cancel'
            style={{ opacity: !showsave ? 0.5 : 1 }}
            disabled={!showsave}
            onClick={() => {
                restoreBackup()
                clearBackup()
                setShowsave(false)
            }}>
            Revert
        </button>
        <button
            className='iedit_save'
            style={{ opacity: !showsave ? 0.5 : 1 }}
            disabled={!showsave}
            onClick={() => {
                onSave().then((message) => {
                    if (message === 'SUCCESS') { setShowsave(false) }
                })
            }}
        >
            Save
        </button>
    </div>

    return <div className='Ieditcomp_div'
        style={{
            position: 'relative',
            height: {
                'WEB': 'calc((100% - 70px) - 115px)',
                'TABLET': 'calc((100% - 70px) - 115px)',
                'MOBILE': 'calc(100% - 70px)'
            }[device],
            marginBottom: {
                'MOBILE': 65
            }[device]
        }}
    >
        <Explain title={TITLE} txt={TEXT} />
        {radio}
        {menutype === 'btmnavbar'
            ? <>
                {navdisplay}
                {navcolor}
                {navff}
                {navcompedit}
                {navmoreedit}
                {addcomp_portal}
                {selectcomp_portal}
                {selectmore_portal}
            </>
            : menutype === 'stickytopnav'
                ? <>
                    {navdisplay}
                    {navcolor}
                    {navff}
                    {navcompedit}
                    {navmoreedit}
                    {addcomp_portal}
                    {selectcomp_portal}
                    {selectmore_portal}
                </>
                : null
        }

        {btmbar}
    </div>



}
export default Navigation