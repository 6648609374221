import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Retrieve_personal_info, decryptHashed, iconwrapper } from '../../config/commonfunctions' // getS3fullpath_withbucketname
import {
    // READ_COMPAREWEDDINGVENUE,
    READ_COMPAREWEDDINGVENUEUSER,
    USER_FAV_A_VENDOR, USER_UNFAV_A_VENDOR,
    READ_COMPAREWEDDINGVENUENONUSER,
    READ_COMPAREWEDDINGVENUENONUSER_TIKTOK,
    READ_COMPAREWEDDINGVENUENONUSER_INSTAGRAM,
    READ_COMPAREWEDDINGVENUENONUSER_THREAD,
    READ_COMPAREWEDDINGVENUENONUSER_FACEBOOK,
    READ_COMPAREWEDDINGVENUENONUSER_YOUTUBE,
    READ_COMPAREWEDDINGVENUENONUSER_REDDIT,
    READ_COMPAREWEDDINGVENUENONUSER_PINTEREST,
    READ_COMPAREWEDDINGVENUENONUSER_LINKEDIN,
    READ_COMPAREWEDDINGVENUENONUSER_TWITTER,
    READ_COMPAREWEDDINGVENUENONUSER_TELEGRAM,
    READ_COMPAREWEDDINGVENUENONUSER_HWZ
} from '../../config/api'
import Scatterplot from '../../charts/Scatterplot/Scatterplot'
import { Checkdevice, Portalmodal, Processmoney, ScreenHeight, ScreenWidth } from '../../components'
import axios from 'axios'
import Slider from 'rc-slider';
import { ARROWDOWNWITHOUTCIRCLE_ICON, CROSS_ICON, HEARTFILL_ICON, THICKSORT_ICON, THICKSORTPRICE_ICON, TICKSORTREVIEWNUM_ICON } from '../../config/svgicons'
import DatePicker from "react-datepicker";
import Enquirycontent from '../../components/Enquirycontent/Enquirycontent'
import { EMPTYSPACE, production, SEARCH_FILTER_WEDDING, STAR_ICON } from '../../config/constants'
import pako from 'pako';
import "react-datepicker/dist/react-datepicker.css";
import 'rc-slider/assets/index.css';
import './Comparevenue.css'

const VENUETYPE_TAGS = SEARCH_FILTER_WEDDING['Venue']['Venue Type'].map((item) => item.header)

const daymap = { 'mon': 'Mon', 'tue': 'Tue', 'wed': 'Wed', 'thur': 'Thur', 'fri': 'Fri', 'sat': 'Sat', 'sun': 'Sun' }
const mtharr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']

var CustomDatepicker = React.forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" id='Comparevenue_weddingdatebtn' ref={ref} onClick={onClick}>
        {value.length > 0 ? 'Wedding Date : ' + value : 'Wedding Date'}
    </button>
));

const emptyspace = 5
const debug = false

const Range = Slider.Range;
const banquetstep = 100
const buffetstep = 10

const mobileportalcss = { width: '100%', height: '100%', backgroundColor: 'white', top: '50%', left: '50%', right: '', transform: 'translate(-50%,-50%)', overflowY: 'auto' }
const webtabletportalcss = { width: 600, height: 650, backgroundColor: 'white', top: '50%', left: '50%', right: '', transform: 'translate(-50%,-50%)' }

const mobileportalcssscatterplot = { width: '100%', height: '100%', top: '50%', left: '50%', right: '', transform: 'translate(-50%,-50%)', overflowY: 'auto', backgroundColor: 'transparent' }
const webtabletportalcssscatterplot = { width: 'max-content', height: 'max-content', maxHeight: '100%', overflowY: 'scroll', overflowX: 'hidden', top: '50%', left: '50%', right: '', transform: 'translate(-50%,-50%)', backgroundColor: 'white' }

const ICON_CSS = { width: 20, height: 20, display: 'flex', placeContent: 'center', backgroundColor: 'white' }

const ALL_SHARE_SAME_CAPACITY = '0'
const PRICE_POINT_TO_UNIQUE_CAPACITY = '1'

const DATA_SHORTFORM_MAP = {
    "ALLL1": ['l1', 'l1', 'l1', 'l1', 'l1', 'l1', 'l1', 'l1', 'l1', 'l1', 'l1', 'l1'],
    "ALLD1": ['d1', 'd1', 'd1', 'd1', 'd1', 'd1', 'd1', 'd1', 'd1', 'd1', 'd1', 'd1'],
}

const TIER_DIAMOND_POSITION = [
    { y: 2 }, // nothing
    { y: 0 }, // bronze
    { y: -13 }, // silver
    { y: -27 } // gold
]

const ALLL1 = 'ALLL1' // ALL LUNCH 
const ALLD1 = 'ALLD1' // ALL DINNER

function checkshortformformonth(rawmthstr) {
    return rawmthstr === ALLL1 || rawmthstr === ALLD1 ? DATA_SHORTFORM_MAP[rawmthstr] : rawmthstr.split(',')
}

const scatterplot_css_map = {
    'WEB': {
        height: (data, scatterht, priceviewmode) => {
            let tempmap = {}
            if (priceviewmode === 'ALL_PRICES') {
                for (let i = 0; i < data.length; i++) {
                    tempmap[data[i].id] = 1
                }
                let datalen = Object.keys(tempmap).length
                if (datalen < 100) {
                    return scatterht
                }
                else if (datalen < 150) {
                    return scatterht * 2
                }
                else if (datalen < 200) {
                    return scatterht * 3
                }
                else if (datalen < 250) {
                    return scatterht * 4
                }
                else if (datalen < 300) {
                    return scatterht * 5
                }
                else {
                    return scatterht * 4.5
                }
            }
            else {

                let datalen = data.length
                if (datalen < 50) {
                    return scatterht
                }
                else if (datalen < 110) {
                    return scatterht * 3
                }
                else if (datalen < 150) {
                    return scatterht * 4
                }
                else if (datalen < 200) {
                    return scatterht * 5
                }
                else if (datalen < 250) {
                    return scatterht * 6
                }
                else if (datalen < 300) {
                    return scatterht * 7
                }
                else {
                    return scatterht * 4.5
                }
            }
        },
        radius: 4
    },
    'TABLET': {
        height: (data, scatterht, priceviewmode) => {
            let tempmap = {}
            if (priceviewmode === 'ALL_PRICES') {
                for (let i = 0; i < data.length; i++) {
                    tempmap[data[i].id] = 1
                }
                let datalen = Object.keys(tempmap).length
                if (datalen < 100) {
                    return scatterht
                }
                else if (datalen < 150) {
                    return scatterht * 2
                }
                else if (datalen < 200) {
                    return scatterht * 2.5
                }
                else if (datalen < 250) {
                    return scatterht * 3
                }
                else if (datalen < 300) {
                    return scatterht * 3.5
                }
                else {
                    return scatterht * 5
                }
            }
            else {
                let datalen = data.length
                if (datalen < 50) {
                    return scatterht
                }
                else if (datalen < 110) {
                    return scatterht * 3
                }
                else if (datalen < 150) {
                    return scatterht * 4
                }
                else if (datalen < 200) {
                    return scatterht * 5
                }
                else if (datalen < 250) {
                    return scatterht * 6
                }
                else if (datalen < 300) {
                    return scatterht * 7
                }
                else {
                    return scatterht * 4.5
                }
            }
        },
        radius: 5
    },
    'MOBILE': {
        height: (data, scatterht, priceviewmode) => {
            // if (priceviewmode === 'ALL_PRICES') {
            if (data.length < 100) {
                return scatterht
            }
            else if (data.length < 125) {
                return scatterht * 1.5
            }
            else if (data.length < 150) {
                return scatterht * 2.5
            }
            else if (data.length < 200) {
                return scatterht * 3.5
            }
            else {
                return scatterht * 4
            }
            // }
            // else {

            // }
        },
        radius: (datalen) => {
            if (datalen < 100) return 3
            else return 2
        }
    }
}

const TABLE_CAPACITY_ARR = ['up to 5', '6-10', '11-20', '21-30', '31-40', '41-50', '51-60', '61-70', '71-80', '81-90', '91-100', '100 and above']
const TABLE_CAPACITY_VALUE_ARR = {
    'up to 5': [0, 5],
    '6-10': [6, 10],
    '11-20': [11, 20],
    '21-30': [21, 30],
    '31-40': [31, 40],
    '41-50': [41, 50],
    '51-60': [51, 60],
    '61-70': [61, 70],
    '71-80': [71, 80],
    '81-90': [81, 90],
    '91-100': [91, 100],
    '100 and above': [100, 999]
}

const PAX_CAPACITY_ARR = ['up to 100', '101-250', '251-500', '500-1000', '1000 and above']
const PAX_CAPACITY_VALUE_ARR = {
    'up to 100': [0, 100],
    '101-250': [101, 250],
    '251-500': [251, 500],
    '500-1000': [500, 1000],
    '1000 and above': [1000, 99999]
}

function roundup(oldval) {
    let newval = parseInt(oldval)
    if (oldval > 9999) {
        newval = Math.ceil(oldval / 10000) * 10000
    }
    else if (oldval > 999) {
        newval = Math.ceil(oldval / 1000) * 1000
    }
    else if (oldval > 99) {
        newval = Math.ceil(oldval / 100) * 100
    }
    else {
        newval = 100
    }
    return parseInt(newval)
}

function rounddownbanquet(oldval) {
    let newval = parseInt(oldval)
    if (newval <= 1000) {
        return 0
    }
    else if (newval <= 2000) {
        return 1000
    }
    return newval
}

const NOSORT = 0
const PRICESORT = 1
const ALPHABETSORT = 2
const REVIEWNUMSORT = 3
const TIERSORT = 4

function Comparevenue(props) {

    const [allorselected, setAllorselected] = useState('All Venues') // All Venues or Selected - all raw data, or selected data for chart/list
    const [rawdata, setRawdata] = useState([]) // raw data
    const [processeddata, setProcesseddata] = useState([]) // processed data
    const [data, setData] = useState([]) // scatterplot data
    const [avgdata, setAvgdata] = useState([]) // avg data, will have the final avg depends on Dinner, or Lunch tag
    const [uniquedetailmap, setUniquedetailmap] = useState(new Map())
    const [uniquedetaildata, setUniquedetaildata] = useState({})
    const [displayname_username_map, setDisplayname_username_map] = useState({})
    const [favourite, setFavourite] = useState([]) // [ [ vti_id, vt, secvt ], [ vti_id, vt, secvt], ... ]
    const [selecteddata, setSelecteddata] = useState([])
    const [rightpanel, setRightpanel] = useState('FILTER') // FILTER, VENDOR
    const [customise, setCustomise] = useState('') // PRICE, TABLE
    const [tags, setTags] = useState(['Lunch', 'Dinner', ...VENUETYPE_TAGS]) // Dinner, Lunch
    const [vendorusername_data_map, setVendorusername_data_map] = useState({})

    // sorted data
    const [sort, setSort] = useState(NOSORT) // 0 : no sort, 1 : price sort, 2 : alphabet sort

    // sort by alpha, price, reviewnum
    const [alphasort, setAlphasort] = useState(0) // 0 : no sort, 1 : ascending, 2 : descending
    const [pricesort, setPricesort] = useState(0) // 0 : no sort, 1 : ascending, 2 : descending
    const [reviewnumsort, setReviewnumsort] = useState(0) // 0 : no sort, 1 : ascending, 2 : descending

    // ALL_PRICES - all package prices, AVG_PRICE - average price 
    const [priceviewmode, setPriceviewmode] = useState('ALL_PRICES')

    // login user data
    const [loggeduserdata, setLoggeduserdata] = useState({
        'name': '',
        'spousename': '',
        'date_w': '',
        'date_e': '',
        'email': '',
        'contact': '',
        'usertype': '', // wedding, event or guest
        'location': ''
    })

    // banquet price
    const [minbanquetprice, setMinbanquetprice] = useState(0)
    const [maxbanquetprice, setMaxbanquetprice] = useState(0)
    const [minbanquetwindowprice, setMinbanquetwindowprice] = useState(0)
    const [maxbanquetwindowprice, setMaxbanquetwindowprice] = useState(0) // for scatterplot window
    const [maxbanquetsliderprice, setMaxbanquetsliderprice] = useState(0) // for slider

    // buffet price
    const [minbuffetprice, setMinbuffetprice] = useState(0)
    const [maxbuffetprice, setMaxbuffetprice] = useState(0)
    const [minbuffetwindowprice, setMinbuffetwindowprice] = useState(0)
    const [maxbuffetwindowprice, setMaxbuffetwindowprice] = useState(0) // for scatterplot window
    const [maxbuffetsliderprice, setMaxbuffetsliderprice] = useState(0) // for slider


    // show all capacity
    const [allcapacity, setAllcapacity] = useState(false)

    // capacity range for banquet
    const [mintablerange, setMintablerange] = useState(12)
    const [maxtablerange, setMaxtablerange] = useState(50)

    // capacity range for buffet
    const [minpaxrange, setMinpaxrange] = useState(30)
    const [maxpaxrange, setMaxpaxrange] = useState(100)

    const [rangebanquetpriceminmax, setRangebanquetpriceminmax] = useState([0, 5000])
    const [rangebuffetpriceminmax, setRangebuffetpriceminmax] = useState([0, 1000])

    const [rangetableminmax, setRangetableminmax] = useState([mintablerange, maxtablerange])
    const [rangepaxminmax, setRangepaxminmax] = useState([minpaxrange, maxpaxrange])

    const [allpossiblecultures, setAllpossiblecultures] = useState([])
    const [allpossiblemealtypes, setAllpossiblemealtypes] = useState([]) // banquet, buffet
    const [allpossibleyears, setAllpossibleyears] = useState([])
    const [selectedculture, setSelectedculture] = useState('chinese') //  chinese, malay, indian, international
    const [selectedmealtype, setSelectedmealtype] = useState('banquet') //  banquet, buffet
    const [culturemealtypemap, setCulturemealtypemap] = useState({})
    const [strday, setStrday] = useState('mon') // mon, tue, wed, thur, fri, sat, sun
    const [strmth, setStrmth] = useState('0') // 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11
    const [stryear, setStryear] = useState('2025') // 2024, 2025

    const [tablecapacity, setTablecapacity] = useState(TABLE_CAPACITY_ARR)
    const [paxcapacity, setPaxcapacity] = useState(PAX_CAPACITY_ARR)

    const [scatterkey, setScatterkey] = useState('')

    // help guide
    const [showhelp, setShowhelp] = useState(false)

    // side bar for tablet
    const [showsidebar, setShowsidebar] = useState(false)

    // is touchscreen
    const [touchscreen, setTouchscreen] = useState(false)

    // for mobile only
    const [showmobilefilter, setShowmobilefilter] = useState(false)
    const [showmobileselection, setShowmobileselection] = useState(false)
    const [showrecommendotherdevice, setShowrecommendotherdevice] = useState(false)

    const [firsttime, setFirsttime] = useState(true)
    const [displaystate, setDisplaystate] = useState('LOADING')

    const [leftmain, setLeftmain] = useState('') // List, Chart

    // date
    const [date, setDate] = useState('')

    // expand selected card
    const [expandcard, setExpandcard] = useState(false)

    // enquire all vendors
    const [showenquireall, setShowenquireall] = useState(false)

    // show enquire portal
    const [showenquire, setShowenquire] = useState(false)

    // for both single enquire and all selected enquire
    const [enquiredata, setEnquiredata] = useState([])

    //sprite vendor venue profiel image position
    const [profileimgpositionarr, setPorfileimgpositionarr] = useState([])

    const [showscatterplotportal, setShowscatterportal] = useState(false) // open portal 
    const [scatterportalvendorobj, setScatterportalvendorobj] = useState({})

    // to show extra feature if you are not user
    const [showinput, setShowinput] = useState(false)
    const [showextrafeature, setShowextrafeature] = useState(false)
    const [venueextrafeaturecode, setVenueextrafeaturecode] = useState('')

    // // tablet device, shot right panel 
    // const [showtabletrightpanel, setShowtabletrightpanel] = useState(false)

    const device = Checkdevice()

    const wt = ScreenWidth()
    const ht = ScreenHeight()

    // const scatterht = { 'WEB': (ht - 50), 'TABLET': (ht - 90), 'MOBILE': (ht - 120) }[device]
    const scatterht = { 'WEB': (ht - 50), 'TABLET': (ht - 50), 'MOBILE': (ht - 220) }[device] // 100 more than prev
    const panelwt = { 'WEB': 320, 'TABLET': 320, 'MOBILE': '100%' }[device]
    const mainwt = wt - panelwt
    const mainht = scatterht + 10

    const { token, _id, acct_type } = Retrieve_personal_info()

    function extractVenuefavourite(favourite, arr_rawdata) {
        let new_favourite = favourite.slice()
        for (let i = 0; i < favourite.length; i++) {
            for (let j = 0; j < arr_rawdata.length; j++) {
                const { displayname, vendortypeinterface_id } = arr_rawdata[j]
                if (vendortypeinterface_id === favourite[i][0] && favourite[i].length === 3) {
                    new_favourite[i].push(displayname)
                    break
                }
            }
        }
        return new_favourite
    }




    function decompressdata(compresseddata) {
        function base64ToUint8Array(base64) {
            const binaryString = atob(base64); // Decode base64 to binary string
            const len = binaryString.length;
            const bytes = new Uint8Array(len);

            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }

            return bytes;
        }
        try {
            const uint8Array = base64ToUint8Array(compresseddata);
            const decompressedData = pako.ungzip(uint8Array, { to: 'string' });
            return JSON.parse(decompressedData);
        } catch (error) {
            console.error('Decompression failed', error);
        }
    }

    useEffect(() => {

        if (acct_type === 'USER') {
            let options = {
                method: 'POST',
                url: READ_COMPAREWEDDINGVENUEUSER,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
                data: { _id, acct_type }
            }
            axios(options).then((result) => {
                const { status } = result
                if (status === 200) {
                    const { user, positionarr } = result.data
                    const { favourite, rawdata } = result.data.items // data
                    const { compresseddata } = rawdata
                    let data = decompressdata(compresseddata)

                    let arr_rawdata = []
                    for (let i = 0; i < data.length; i++) {
                        arr_rawdata.push(JSON.parse(decryptHashed(data[i])))
                    }

                    // remove all data that do not have venue properties
                    let temp_arr_rawdata = []
                    for (let i = 0; i < arr_rawdata.length; i++) {
                        if (Object.keys(arr_rawdata[i]).includes('venue')) {
                            temp_arr_rawdata.push(arr_rawdata[i])
                        }
                    }

                    setFavourite(extractVenuefavourite(favourite, temp_arr_rawdata))
                    setRawdata(temp_arr_rawdata)
                    setLoggeduserdata(JSON.parse(JSON.stringify(user)))
                    setPorfileimgpositionarr(positionarr)
                }
            })
        }
        else {
            const currentURL = window.location.href; // Full current URL including query parameters

            // Create a URL object
            const url = new URL(currentURL);

            // Extract query parameters
            const s = url.searchParams.get('s');

            const SOCIALMEDIA_LINK_MAP = {
                tt: READ_COMPAREWEDDINGVENUENONUSER_TIKTOK,
                i: READ_COMPAREWEDDINGVENUENONUSER_INSTAGRAM,
                th: READ_COMPAREWEDDINGVENUENONUSER_THREAD,
                f: READ_COMPAREWEDDINGVENUENONUSER_FACEBOOK,
                y: READ_COMPAREWEDDINGVENUENONUSER_YOUTUBE,
                r: READ_COMPAREWEDDINGVENUENONUSER_REDDIT,
                p: READ_COMPAREWEDDINGVENUENONUSER_PINTEREST,
                l: READ_COMPAREWEDDINGVENUENONUSER_LINKEDIN,
                t: READ_COMPAREWEDDINGVENUENONUSER_TWITTER,
                te: READ_COMPAREWEDDINGVENUENONUSER_TELEGRAM,
                hwz: READ_COMPAREWEDDINGVENUENONUSER_HWZ,
            }
            let link = READ_COMPAREWEDDINGVENUENONUSER
            
            if (s && s.length > 0) {
                try {
                    link = SOCIALMEDIA_LINK_MAP[s]
                }
                catch (e) {
                    link = READ_COMPAREWEDDINGVENUENONUSER
                }
            }

            let options = {
                method: 'POST',
                url: link,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
                data: { _id, acct_type }
            }
            axios(options).then((result) => {
                const { status } = result
                if (status === 200) {
                    const { positionarr } = result.data
                    const { rawdata } = result.data.items // data
                    const { compresseddata } = rawdata
                    let data = decompressdata(compresseddata)
                    let arr_rawdata = []
                    for (let i = 0; i < data.length; i++) {
                        arr_rawdata.push(JSON.parse(decryptHashed(data[i])))
                    }
                    setRawdata(arr_rawdata)
                    const t = decryptHashed(positionarr).split(',')
                    setPorfileimgpositionarr(t)
                }
            })
        }

        // check if device is touchscreen or not
        setTouchscreen('ontouchstart' in window)

        if (device === 'MOBILE') {
            setLeftmain('List')
            document.getElementById('root').style.overflow = 'hidden';
            document.getElementById('root').style.backgroundColor = '#ffffff';
            return () => {
                document.getElementById('root').style.overflow = '';
                document.getElementById('root').style.backgroundColor = '';
            }
        }
        else {
            setLeftmain('Chart')
        }


    }, [])

    const assemblerawdata = () => {
        if (rawdata && rawdata.length > 0) {
            let local_rawdata = JSON.parse(JSON.stringify(rawdata))
            let allpossiblecultures = []
            let allpossiblemealtypes = []
            let allpossibleyears = []
            let culturemealtypemap = {}
            let vendorusername_data_map = {}
            for (let i = 0; i < rawdata.length; i++) {

                if (rawdata[i].hasOwnProperty('venue')) {
                    const currcultures = Object.keys(rawdata[i]['venue'])
                    const { username, link, tags, displayname, vendortypeinterface_id } = rawdata[i]

                    if (!vendorusername_data_map.hasOwnProperty(username)) {
                        vendorusername_data_map[username] = { username, displayname, vendortypeinterface_id, tags, link, prices: { 'banquet': new Set(), 'buffet': new Set() } }
                    }

                    for (let j = 0; j < currcultures.length; j++) {

                        // obtain all culture
                        const currculture = currcultures[j]
                        if (!allpossiblecultures.includes(currculture)) {
                            allpossiblecultures.push(currculture)
                        }

                        if (!(Object.keys(culturemealtypemap).includes(currculture))) {
                            culturemealtypemap[currculture] = new Set()
                        }

                        // obtain all meal type
                        const currmealtypes = Object.keys(rawdata[i]['venue'][currculture])
                        for (let k = 0; k < currmealtypes.length; k++) {

                            const currmealtype = currmealtypes[k]

                            culturemealtypemap[currculture].add(currmealtype)

                            if (!allpossiblemealtypes.includes(currmealtype)) { allpossiblemealtypes.push(currmealtype) }

                            // obtain all years
                            const curryears = Object.keys(rawdata[i]['venue'][currculture][currmealtype])
                            for (let l = 0; l < curryears.length; l++) {
                                const curryear = curryears[l]
                                if (!allpossibleyears.includes(curryear)) {
                                    allpossibleyears.push(curryear)
                                }

                                // redeclare the data within
                                const daygroups = Object.keys(rawdata[i]['venue'][currculture][currmealtype][curryear])
                                for (let m = 0; m < daygroups.length; m++) {
                                    const singledaygroup = rawdata[i]['venue'][currculture][currmealtype][curryear][daygroups[m]]
                                    const { lmonth, dmonth } = singledaygroup
                                    local_rawdata[i]['venue'][currculture][currmealtype][curryear][daygroups[m]]['lmonth'] = checkshortformformonth(lmonth)
                                    local_rawdata[i]['venue'][currculture][currmealtype][curryear][daygroups[m]]['dmonth'] = checkshortformformonth(dmonth)

                                    // get all the prices and add to vendorusername_data_map.prices
                                    const { cmd } = singledaygroup

                                    // loop all the prices only
                                    let arr_lunch = [...new Set(local_rawdata[i]['venue'][currculture][currmealtype][curryear][daygroups[m]]['lmonth'])]
                                    let arr_dinner = [...new Set(local_rawdata[i]['venue'][currculture][currmealtype][curryear][daygroups[m]]['dmonth'])]
                                    let temp_keys = [...arr_lunch, ...arr_dinner]

                                    for (let n = 0; n < temp_keys.length; n++) {
                                        if (temp_keys[n] !== 'lmonth' && temp_keys[n] !== 'dmonth' && temp_keys[n] !== 'table' && temp_keys[n] !== 'cmd') {
                                            if (local_rawdata[i]['venue'][currculture][currmealtype][curryear][daygroups[m]][temp_keys[n]]) {
                                                let prices = local_rawdata[i]['venue'][currculture][currmealtype][curryear][daygroups[m]][temp_keys[n]].split(',')

                                                // Remove empty strings
                                                prices = prices.filter(element => element !== "");

                                                if (cmd === ALL_SHARE_SAME_CAPACITY) {
                                                    for (let l = 0; l < prices.length; l++) {
                                                        vendorusername_data_map[username]['prices'][currmealtype].add(parseInt(prices[l]))
                                                    }
                                                }
                                                else if (cmd === PRICE_POINT_TO_UNIQUE_CAPACITY) {
                                                    for (let l = 0; l < prices.length; l++) {
                                                        let realprices = prices[l].split(':')[0]

                                                        vendorusername_data_map[username]['prices'][currmealtype].add(parseInt(realprices))
                                                    }
                                                }
                                            }

                                        }
                                    }

                                }

                            }

                        }

                    }

                }

            }

            // convert prices to array
            let all_userdatamap_keys = Object.keys(vendorusername_data_map)
            for (let i = 0; i < all_userdatamap_keys.length; i++) {
                vendorusername_data_map[all_userdatamap_keys[i]]['prices']['banquet'] = [...vendorusername_data_map[all_userdatamap_keys[i]]['prices']['banquet']].sort(function (a, b) { return parseInt(a) - parseInt(b) })
                vendorusername_data_map[all_userdatamap_keys[i]]['prices']['buffet'] = [...vendorusername_data_map[all_userdatamap_keys[i]]['prices']['buffet']].sort(function (a, b) { return parseInt(a) - parseInt(b) })
            }
            setVendorusername_data_map(JSON.parse(JSON.stringify(vendorusername_data_map)))
            setCulturemealtypemap(culturemealtypemap) // culture change, mealtype will change as well
            setAllpossiblecultures(allpossiblecultures)
            setAllpossiblemealtypes(allpossiblemealtypes) // banquet, buffet
            setAllpossibleyears(allpossibleyears.sort(function (a, b) { return parseInt(a) - parseInt(b) }))
            setProcesseddata(local_rawdata)
            updateminmaxprice(local_rawdata)
            const { data, cmd } = genscatterdata(priceviewmode, local_rawdata, selectedmealtype, selectedculture, tags, strday, strmth, stryear, tablecapacity, paxcapacity, showsidebar, vendorusername_data_map)
            updatescatterdata(cmd, data)
            setFirsttime(false)

        }
    }

    const assembleselecteddata = (selecteddata) => {
        let selected_usernames = []
        if (selecteddata && selecteddata.length > 0) {
            for (let i = 0; i < selecteddata.length; i++) {
                selected_usernames.push(selecteddata[i].username)
            }
        }

        let selectedrawdata = []

        for (let i = 0; i < rawdata.length; i++) {
            const { username } = rawdata[i]
            if (selected_usernames.includes(username)) {
                selectedrawdata.push(rawdata[i])
            }
        }

        if (selectedrawdata && selectedrawdata.length > 0) {
            let local_rawdata = JSON.parse(JSON.stringify(selectedrawdata))
            let allpossiblecultures = []
            let allpossiblemealtypes = []
            let allpossibleyears = []
            let culturemealtypemap = {}
            let vendorusername_data_map = {}

            for (let i = 0; i < selectedrawdata.length; i++) {
                const { username, link, tags, displayname, vendortypeinterface_id } = selectedrawdata[i]


                const currcultures = Object.keys(selectedrawdata[i]['venue'])

                if (!vendorusername_data_map.hasOwnProperty(username)) {
                    vendorusername_data_map[username] = { username, displayname, vendortypeinterface_id, tags, link, prices: { 'banquet': new Set(), 'buffet': new Set() } }
                }

                for (let j = 0; j < currcultures.length; j++) {

                    // obtain all culture
                    const currculture = currcultures[j]
                    if (!allpossiblecultures.includes(currculture)) {
                        allpossiblecultures.push(currculture)
                    }

                    if (!(Object.keys(culturemealtypemap).includes(currculture))) {
                        culturemealtypemap[currculture] = new Set()
                    }

                    // obtain all meal type
                    const currmealtypes = Object.keys(selectedrawdata[i]['venue'][currculture])
                    for (let k = 0; k < currmealtypes.length; k++) {

                        const currmealtype = currmealtypes[k]

                        culturemealtypemap[currculture].add(currmealtype)

                        if (!allpossiblemealtypes.includes(currmealtype)) { allpossiblemealtypes.push(currmealtype) }

                        // obtain all years
                        const curryears = Object.keys(selectedrawdata[i]['venue'][currculture][currmealtype])
                        for (let l = 0; l < curryears.length; l++) {
                            const curryear = curryears[l]
                            if (!allpossibleyears.includes(curryear)) {
                                allpossibleyears.push(curryear)
                            }

                            // redeclare the data within
                            const daygroups = Object.keys(selectedrawdata[i]['venue'][currculture][currmealtype][curryear])
                            for (let m = 0; m < daygroups.length; m++) {
                                const singledaygroup = selectedrawdata[i]['venue'][currculture][currmealtype][curryear][daygroups[m]]
                                const { lmonth, dmonth } = singledaygroup
                                local_rawdata[i]['venue'][currculture][currmealtype][curryear][daygroups[m]]['lmonth'] = checkshortformformonth(lmonth)
                                local_rawdata[i]['venue'][currculture][currmealtype][curryear][daygroups[m]]['dmonth'] = checkshortformformonth(dmonth)

                                // get all the prices and add to vendorusername_data_map.prices
                                const { cmd } = singledaygroup

                                // loop all the prices only
                                let arr_lunch = [...new Set(local_rawdata[i]['venue'][currculture][currmealtype][curryear][daygroups[m]]['lmonth'])]
                                let arr_dinner = [...new Set(local_rawdata[i]['venue'][currculture][currmealtype][curryear][daygroups[m]]['dmonth'])]
                                let temp_keys = [...arr_lunch, ...arr_dinner]

                                for (let n = 0; n < temp_keys.length; n++) {
                                    if (temp_keys[n] !== 'lmonth' && temp_keys[n] !== 'dmonth' && temp_keys[n] !== 'table' && temp_keys[n] !== 'cmd') {

                                        let prices = local_rawdata[i]['venue'][currculture][currmealtype][curryear][daygroups[m]][temp_keys[n]].split(',')

                                        // Remove empty strings
                                        prices = prices.filter(element => element !== "");

                                        if (cmd === ALL_SHARE_SAME_CAPACITY) {
                                            for (let l = 0; l < prices.length; l++) {
                                                vendorusername_data_map[username]['prices'][currmealtype].add(parseInt(prices[l]))
                                            }
                                        }
                                        else if (cmd === PRICE_POINT_TO_UNIQUE_CAPACITY) {
                                            for (let l = 0; l < prices.length; l++) {
                                                let realprices = prices[l].split(':')[0]

                                                vendorusername_data_map[username]['prices'][currmealtype].add(parseInt(realprices))
                                            }
                                        }
                                    }
                                }


                            }

                        }



                    }

                }
            }




            // convert prices to array
            let all_userdatamap_keys = Object.keys(vendorusername_data_map)


            for (let i = 0; i < all_userdatamap_keys.length; i++) {

                if ([...vendorusername_data_map[all_userdatamap_keys[i]]['prices']['banquet']].length > 0) {
                    vendorusername_data_map[all_userdatamap_keys[i]]['prices']['banquet'] = [...vendorusername_data_map[all_userdatamap_keys[i]]['prices']['banquet']].sort(function (a, b) { return parseInt(a) - parseInt(b) })
                }
                if ([...vendorusername_data_map[all_userdatamap_keys[i]]['prices']['buffet']].length > 0) {
                    vendorusername_data_map[all_userdatamap_keys[i]]['prices']['buffet'] = [...vendorusername_data_map[all_userdatamap_keys[i]]['prices']['buffet']].sort(function (a, b) { return parseInt(a) - parseInt(b) })
                }
            }

            setVendorusername_data_map(JSON.parse(JSON.stringify(vendorusername_data_map)))
            setCulturemealtypemap(culturemealtypemap) // culture change, mealtype will change as well
            setAllpossiblecultures(allpossiblecultures)
            setAllpossiblemealtypes(allpossiblemealtypes) // banquet, buffet
            setAllpossibleyears(allpossibleyears.sort(function (a, b) { return parseInt(a) - parseInt(b) }))
            setProcesseddata(local_rawdata)
            updateminmaxprice(local_rawdata)
            const { data, cmd } = genscatterdata(priceviewmode, local_rawdata, selectedmealtype, selectedculture, tags, strday, strmth, stryear, tablecapacity, paxcapacity, showsidebar, vendorusername_data_map)
            updatescatterdata(cmd, data)
        }
        else {
            setDisplaystate('NODATA')
            setData([])
        }
    }

    useEffect(() => {
        if (firsttime) {
            assemblerawdata()
        }
    }, [rawdata])

    useEffect(() => {
        if (!firsttime) {
            if (allorselected === 'All Venues') {
                assemblerawdata()
            }
            else if (allorselected === 'Selected') {
                assembleselecteddata(selecteddata)
            }
        }
    }, [allorselected])

    useEffect(() => {
        if (data.length > 0) {
            let local_uniquedetaildata = {}
            let local_uniquedetailmap = new Map()
            let local_displayname_username_map = {}
            for (let i = 0; i < data.length; i++) {
                const data_username = data[i]['username']
                if (!local_uniquedetailmap.has(data_username)) {
                    local_uniquedetailmap.set(data_username, [])
                    for (let x = 0; x < rawdata.length; x++) {
                        let rawdata_username = rawdata[x]['username']
                        if (rawdata_username === data_username) {
                            local_uniquedetaildata[data_username] = rawdata[x]
                            local_uniquedetaildata[data_username]['y'] = rawdata[x]['displayname']
                        }
                    }
                }
                local_displayname_username_map[data[i]['y']] = data_username
                local_uniquedetailmap.get(data_username).push(data[i])
            }
            setUniquedetailmap(local_uniquedetailmap)
            setUniquedetaildata(local_uniquedetaildata)

            setDisplayname_username_map(local_displayname_username_map)
        }
    }, [data])

    function updateminmaxprice(rawdata) {
        if (rawdata) {
            // let rangebanquetpriceminmax = [0, 5000]
            // let rangebuffetpriceminmax = [0, 200]

            // let minbanquetprice = 0
            // let maxbanquetprice = 5000

            // let minbuffetprice = 0
            // let maxbuffetprice = 200


            let table_set = new Set()
            let pax_set = new Set()
            let buffetprice_set = new Set()
            let banquetprice_set = new Set()

            for (let i = 0; i < rawdata.length; i++) {

                if (rawdata[i].hasOwnProperty('venue')) {
                    let cultures = Object.keys(rawdata[i]['venue'])

                    for (let k = 0; k < cultures.length; k++) {

                        let culture = cultures[k]
                        let mealtypes = Object.keys(rawdata[i]['venue'][culture])

                        for (let l = 0; l < mealtypes.length; l++) {

                            let mealtype = mealtypes[l]

                            let years = Object.keys(rawdata[i]['venue'][culture][mealtype])

                            for (let m = 0; m < years.length; m++) {

                                let year = years[m]

                                let daygrps = Object.keys(rawdata[i]['venue'][culture][mealtype][year])

                                for (let n = 0; n < daygrps.length; n++) {

                                    let daygrp = daygrps[n]

                                    const { lmonth, dmonth, cmd } = rawdata[i]['venue'][culture][mealtype][year][daygrp]
                                    // red
                                    if (cmd === ALL_SHARE_SAME_CAPACITY) {

                                        const { table } = rawdata[i]['venue'][culture][mealtype][year][daygrp]
                                        table.split(',').map((t) => t.split('-')).flat().map((item) => table_set.add(item))

                                        let keys = Object.keys(rawdata[i]['venue'][culture][mealtype][year][daygrp])
                                        keys.splice(keys.indexOf('lmonth'), 1)
                                        keys.splice(keys.indexOf('dmonth'), 1)
                                        keys.splice(keys.indexOf('table'), 1)
                                        keys.splice(keys.indexOf('cmd'), 1)
                                        for (let z = 0; z < keys.length; z++) {
                                            if (rawdata[i]['venue'][culture][mealtype][year][daygrp][keys[z]]) {
                                                let temp = rawdata[i]['venue'][culture][mealtype][year][daygrp][keys[z]].split(',')
                                                for (let y = 0; y < temp.length; y++) {
                                                    if (mealtype === 'banquet') {
                                                        banquetprice_set.add(roundup(temp[y]))
                                                    }
                                                    else if (mealtype === 'buffet') {
                                                        buffetprice_set.add(roundup(temp[y]))
                                                    }
                                                }
                                            }
                                        }


                                    }
                                    else if (cmd === PRICE_POINT_TO_UNIQUE_CAPACITY) {

                                        // lunch
                                        for (let o = 0; o < lmonth.length; o++) {
                                            let ptr_lmonth = lmonth[o]

                                            if (rawdata[i]['venue'][culture][mealtype][year][daygrp][ptr_lmonth]) {
                                                let price_pax_pair_arr = rawdata[i]['venue'][culture][mealtype][year][daygrp][ptr_lmonth].split(',').map(item => item.split(':'))
                                                for (let v = 0; v < price_pax_pair_arr.length; v++) {

                                                    let curr_price = roundup(price_pax_pair_arr[v][0])
                                                    let curr_capacity_ptr = price_pax_pair_arr[v][1]

                                                    if (rawdata[i]['venue'][culture][mealtype][year][daygrp][curr_capacity_ptr]) {
                                                        let curr_cap_arr = rawdata[i]['venue'][culture][mealtype][year][daygrp][curr_capacity_ptr].split(',').map((item) => item.split('-')).flat()

                                                        // add capacity and price (based on mealtype)
                                                        if (mealtype === 'banquet') {
                                                            for (let i = 0; i < curr_cap_arr.length; i++) {
                                                                table_set.add(curr_cap_arr[i])
                                                            }
                                                            banquetprice_set.add(curr_price)
                                                        }
                                                        else if (mealtype === 'buffet') {
                                                            for (let i = 0; i < curr_cap_arr.length; i++) {
                                                                pax_set.add(curr_cap_arr[i])
                                                            }
                                                            buffetprice_set.add(curr_price)
                                                        }
                                                    }


                                                }

                                            }

                                        }

                                        // dinner
                                        for (let p = 0; p < dmonth.length; p++) {
                                            let ptr_dmonth = dmonth[p]
                                            if (rawdata[i]['venue'][culture][mealtype][year][daygrp][ptr_dmonth]) {

                                                let price_pax_pair_arr = rawdata[i]['venue'][culture][mealtype][year][daygrp][ptr_dmonth].split(',').map(item => item.split(':'))
                                                for (let v = 0; v < price_pax_pair_arr.length; v++) {

                                                    let curr_price = roundup(price_pax_pair_arr[v][0])
                                                    let curr_capacity_ptr = price_pax_pair_arr[v][1]

                                                    if (rawdata[i]['venue'][culture][mealtype][year][daygrp][curr_capacity_ptr]) {
                                                        let curr_cap_arr = rawdata[i]['venue'][culture][mealtype][year][daygrp][curr_capacity_ptr].split(',').map((item) => item.split('-')).flat()

                                                        // add capacity and price (based on mealtype)
                                                        if (mealtype === 'banquet') {
                                                            for (let i = 0; i < curr_cap_arr.length; i++) {
                                                                table_set.add(curr_cap_arr[i])
                                                            }
                                                            banquetprice_set.add(curr_price)
                                                        }
                                                        else if (mealtype === 'buffet') {
                                                            for (let i = 0; i < curr_cap_arr.length; i++) {
                                                                pax_set.add(curr_cap_arr[i])
                                                            }
                                                            buffetprice_set.add(curr_price)

                                                        }
                                                    }

                                                }
                                            }
                                        }
                                    }

                                }

                            }

                        }

                    }
                }

            }

            let arr_tableset = [...table_set].sort(function (a, b) { return parseInt(a) - parseInt(b) })
            let arr_paxset = [...pax_set].sort(function (a, b) { return parseInt(a) - parseInt(b) })
            let arr_banquetpriceset = [...banquetprice_set].sort(function (a, b) { return parseInt(a) - parseInt(b) })
            let arr_buffetpriceset = [...buffetprice_set].sort(function (a, b) { return parseInt(a) - parseInt(b) })

            arr_banquetpriceset[0] = rounddownbanquet(arr_banquetpriceset[0])
            let localminbanquetprice = arr_banquetpriceset[0]
            let localmaxbanquetprice = arr_banquetpriceset.length === 1 ? (arr_banquetpriceset[0] + 1000) : arr_banquetpriceset[arr_banquetpriceset.length - 1]
            setRangebanquetpriceminmax([localminbanquetprice, localmaxbanquetprice])
            setMinbanquetprice(localminbanquetprice)
            setMaxbanquetprice(localmaxbanquetprice)
            setMinbanquetwindowprice(localminbanquetprice)
            setMaxbanquetwindowprice(localmaxbanquetprice)

            // setRangebuffetpriceminmax([arr_buffetpriceset[0], arr_buffetpriceset[arr_buffetpriceset.length - 1]])
            // setMinbuffetprice(arr_buffetpriceset[0])
            setRangebuffetpriceminmax([0, arr_buffetpriceset[arr_buffetpriceset.length - 1]])
            setMinbuffetprice(0)
            setMaxbuffetprice(arr_buffetpriceset[arr_buffetpriceset.length - 1])
            setMinbuffetwindowprice(0)
            setMaxbuffetwindowprice(arr_buffetpriceset[arr_buffetpriceset.length - 1])



        }
    }

    function getCapacity(username, lunchordinner, selectedmealtype, selectedculture, strday, strmth, stryear) {
        // given these params, return capacity object of : { 1290: ["10-20", "20-50"]}
        let capacity = {}
        for (let i = 0; i < processeddata.length; i++) {

            if (username === processeddata[i].username) {
                if (
                    processeddata[i]['venue'][selectedculture] &&
                    processeddata[i]['venue'][selectedculture][selectedmealtype] &&
                    processeddata[i]['venue'][selectedculture][selectedmealtype][stryear]
                ) {
                    let daygrp_keys = Object.keys(processeddata[i]['venue'][selectedculture][selectedmealtype][stryear])
                    let daygrp_key = ''
                    for (let x = 0; x < daygrp_keys.length; x++) {
                        let days_arr = daygrp_keys[x].split(',')
                        if (days_arr.indexOf(strday) > -1) {
                            daygrp_key = daygrp_keys[x]
                        }
                    }
                    if (processeddata[i]['venue'][selectedculture][selectedmealtype][stryear][daygrp_key]) {
                        const { cmd, lmonth, dmonth } = processeddata[i]['venue'][selectedculture][selectedmealtype][stryear][daygrp_key]

                        function getpricecaparr(mth) {
                            let singlemth = mth[parseInt(strmth)]
                            let pricestr = processeddata[i]['venue'][selectedculture][selectedmealtype][stryear][daygrp_key][singlemth]
                            let pricearr = pricestr.length > 0 ? pricestr.split(',') : []
                            let price_caparr_map = {}
                            if (cmd === ALL_SHARE_SAME_CAPACITY) { // 0
                                let table = processeddata[i]['venue'][selectedculture][selectedmealtype][stryear][daygrp_key]['table'].split(',')
                                for (let w = 0; w < pricearr.length; w++) {
                                    let price = pricearr[w]
                                    if (!Object.keys(price_caparr_map).includes(price)) { price_caparr_map[price] = [] }
                                    for (let p = 0; p < table.length; p++) {
                                        price_caparr_map[price].push(table[p])
                                    }
                                }
                            }
                            else if (cmd === PRICE_POINT_TO_UNIQUE_CAPACITY) { // 1
                                for (let w = 0; w < pricearr.length; w++) {
                                    const splitted_pricearr_w = pricearr[w].split(':')
                                    const price = splitted_pricearr_w[0]
                                    const capacityaddr = splitted_pricearr_w[1]
                                    const cap_arr = processeddata[i]['venue'][selectedculture][selectedmealtype][stryear][daygrp_key][capacityaddr].split(',')
                                    if (!Object.keys(price_caparr_map).includes(price)) { price_caparr_map[price] = [] }
                                    if (cap_arr.length > 0 && price_caparr_map[price].length > 0) {
                                        price_caparr_map[price] = [...price_caparr_map[price], ...cap_arr]
                                    }
                                    else {
                                        price_caparr_map[price] = cap_arr
                                    }
                                }
                            }
                            return price_caparr_map
                        }
                        capacity = getpricecaparr(lunchordinner === 'Dinner' ? dmonth : lmonth)

                    }

                }
            }

        }
        return capacity
    }

    function genscatterdata(
        priceviewmode,
        rawdata,
        selectedmealtype, selectedculture, tags, strday, strmth, stryear, tablecapacity, paxcapacity
    ) {

        function packscatterdata_allprices(lunchordinner, originaldata, key, x_itemprice) {

            const { displayname, username, vendortype_id, vendortypeinterface_id, vendortype, secondaryvendortype, profileimg_url, ais, aiss, s, ss, tier, level, review_num } = originaldata

            let transformval
            if (tags.includes('Dinner') && tags.includes('Lunch')) {
                transformval = lunchordinner === 'Dinner' ? `translateY(-4px)` : `translateY(4px)`
            }
            else {
                transformval = `translateY(0px)`
            }

            let capacitymap = getCapacity(
                username,
                lunchordinner,
                selectedmealtype, selectedculture,
                strday, strmth, stryear
            )

            let combinetags = [...new Set([...originaldata['tags'], ...tags])]

            return {
                id: key,
                datalevel: key + '_`datalevel',
                x: x_itemprice,
                y: displayname,
                color: lunchordinner === 'Dinner' ? 'var(--skyblue)' : 'var(--mainpink)',
                transform: transformval,
                mealofday: lunchordinner,
                culture: selectedculture,
                username: username,
                vendortype_id: vendortype_id,
                vendortypeinterface_id: vendortypeinterface_id,
                vendortype: vendortype,
                secondaryvendortype: secondaryvendortype,
                profileimg_url: profileimg_url,
                tags: combinetags,
                capacity: capacitymap,
                mealtype: selectedmealtype,
                s, ss, ais, aiss,
                tier, level,
                review_num
            }

        }

        function packscatterdata_avg(mealofday, originaldata, avgprice, cmd) {
            const { displayname, username, vendortype_id, vendortypeinterface_id, vendortype, secondaryvendortype, profileimg_url, ais, aiss, s, ss, tier, level, review_num } = originaldata

            let combinetags = [...new Set([...originaldata['tags'], ...tags])]

            return {
                id: displayname,
                key: 'username' + username,
                x: avgprice,
                y: displayname,
                color: 'var(--maindark)',
                transform: `translateY(0px)`,
                culture: selectedculture,
                mealofday: mealofday,
                username: username,
                vendortype_id: vendortype_id,
                vendortypeinterface_id: vendortypeinterface_id,
                vendortype: vendortype,
                secondaryvendortype: secondaryvendortype,
                profileimg_url: profileimg_url,
                tags: combinetags,
                cmd: cmd,
                mealtype: selectedmealtype,
                s, ss, ais, aiss,
                tier, level,
                review_num
            }

        }

        function getPricecheck(itemprice, cmd) {
            let pricecheck = false
            let x_itemprice
            if (cmd === ALL_SHARE_SAME_CAPACITY) {
                let int_itemprice = parseInt(itemprice)
                x_itemprice = int_itemprice
                pricecheck = selectedmealtype === 'banquet'
                    ? parseInt(rangebanquetpriceminmax[0]) < int_itemprice && int_itemprice < parseInt(rangebanquetpriceminmax[1])
                    : parseInt(rangebuffetpriceminmax[0]) < int_itemprice && int_itemprice < parseInt(rangebuffetpriceminmax[1])
            }
            else if (cmd === PRICE_POINT_TO_UNIQUE_CAPACITY) {
                let arr_itemprice = itemprice.split(':')
                x_itemprice = Math.ceil(parseInt(arr_itemprice[0]))
                pricecheck = selectedmealtype === 'banquet'
                    ? parseInt(rangebanquetpriceminmax[0]) < x_itemprice && x_itemprice < parseInt(rangebanquetpriceminmax[1])
                    : parseInt(rangebuffetpriceminmax[0]) < x_itemprice && x_itemprice < parseInt(rangebuffetpriceminmax[1])


                // pricecheck = parseInt(rangebuffetpriceminmax[0]) < x_itemprice && x_itemprice < parseInt(rangebuffetpriceminmax[1])
                // pricecheck = x_itemprice < rangebuffetpriceminmax[1] && x_itemprice > rangebuffetpriceminmax[0]
            }
            return { pricecheck: pricecheck, x_itemprice: x_itemprice }
        }

        function getCapacitycheck(itemprice, cmd, data, daygrp_key, tablecapacity, paxcapacity) {
            let items = {}
            if (cmd === ALL_SHARE_SAME_CAPACITY) {
                if (data[selectedculture][selectedmealtype][stryear][daygrp_key]['table']) {
                    let quantity_arr = data[selectedculture][selectedmealtype][stryear][daygrp_key]['table'].split(',')
                    quantity_arr = quantity_arr.map((item) => item.split('-'))
                    items = selectedmealtype === 'banquet'
                        ? getTablecheck(tablecapacity, quantity_arr)
                        : getPaxcheck(paxcapacity, quantity_arr)
                }
                else {
                    return { capacitycheck: false, capacity: null }
                }
            }
            else if (cmd === PRICE_POINT_TO_UNIQUE_CAPACITY) {
                let arr_itemprice = itemprice.split(':')
                let quantity_addr = arr_itemprice[1]
                if (data[selectedculture][selectedmealtype][stryear][daygrp_key][quantity_addr]) {
                    let quantity_arr = data[selectedculture][selectedmealtype][stryear][daygrp_key][quantity_addr].split(',')
                    quantity_arr = quantity_arr.map((item) => item.split('-'))
                    items = selectedmealtype === 'banquet'
                        ? getTablecheck(tablecapacity, quantity_arr)
                        : getPaxcheck(paxcapacity, quantity_arr)
                }
                else {
                    return { capacitycheck: false, capacity: null }
                }

            }
            return items // { capacitycheck: items[], capacity: capacity }
        }

        // get all data that is of user selected culture - chinese, malay, indian ...
        let tempdata_ = []
        for (let x = 0; x < rawdata.length; x++) {
            if (
                rawdata[x].hasOwnProperty('venue') &&
                Object.keys(rawdata[x]['venue']).includes(selectedculture) &&
                rawdata[x]['venue'][selectedculture]
            ) {
                tempdata_.push(rawdata[x])
            }
        }

        // for all tags
        let tempdata = []
        for (let i = 0; i < tags.length; i++) {
            if (tags[i] === 'Lunch' || tags[i] === 'Dinner') continue

            for (let j = 0; j < tempdata_.length; j++) {
                if (tempdata_[j]['tags'].includes(tags[i])) {
                    tempdata.push(tempdata_[j])
                }
            }
        }

        if (debug) console.log('tempdata', tempdata)

        // get all data that is of user selected meal type - banquet, buffet only
        let tempdata1 = []
        for (let x = 0; x < tempdata.length; x++) {
            if (
                Object.keys(tempdata[x]['venue'][selectedculture]).includes(selectedmealtype)
                && tempdata[x]['venue'][selectedculture][selectedmealtype]
            ) {
                tempdata1.push(tempdata[x])
            }
        }
        if (debug) console.log('tempdata1', tempdata1)
        if (debug) console.log('stryear', stryear)

        // get all data that is of user selected year - 2023, 2024, 2025 ...
        let tempdata2 = []
        for (let x = 0; x < tempdata1.length; x++) {
            if (
                tempdata1[x]
                && Object.keys(tempdata1[x]['venue']).includes(selectedculture)
                && Object.keys(tempdata1[x]['venue'][selectedculture]).includes(selectedmealtype)
                && Object.keys(tempdata1[x]['venue'][selectedculture][selectedmealtype]).includes(stryear)
                && tempdata1[x]['venue'][selectedculture][selectedmealtype][stryear]
            ) {
                tempdata2.push(tempdata1[x])
            }
        }

        if (debug) console.log('selectedculture', selectedculture)
        if (debug) console.log('tempdata2', tempdata2)

        // get all data that is of user selected day grp 
        let tempdata3 = [] // same size as daygrp_keys
        let daygrp_keys = [] // same size as tempdata3
        for (let x = 0; x < tempdata2.length; x++) {

            const daygrp_arr = Object.keys(tempdata2[x]['venue'][selectedculture][selectedmealtype][stryear])
            for (let y = 0; y < daygrp_arr.length; y++) {
                if (daygrp_arr[y].split(',').includes(strday)) {
                    tempdata3.push(tempdata2[x])
                    daygrp_keys.push(daygrp_arr[y])
                }
            }
        }

        if (debug) console.log('tempdata3', tempdata3)
        if (debug) console.log('daygrp_keys', daygrp_keys)
        if (debug) console.log('----')

        let tempdata4 = []
        let avgdata = []
        if (priceviewmode === 'ALL_PRICES') {
            // if (true) {

            // PRICE CHECK AND CAPACITY CHECK
            for (let x = 0; x < tempdata3.length; x++) {

                const daygrp_key = daygrp_keys[x]
                const { lmonth, dmonth, cmd } = tempdata3[x]['venue'][selectedculture][selectedmealtype][stryear][daygrp_key]

                if (lmonth && tags.includes('Lunch')) {
                    let selected_mth = lmonth[strmth]

                    let arr_price = tempdata3[x]['venue'][selectedculture][selectedmealtype][stryear][daygrp_key][selected_mth].split(',')

                    for (let l = 0; l < arr_price.length; l++) {
                        const { pricecheck, x_itemprice } = getPricecheck(arr_price[l], cmd)
                        const { capacitycheck, capacity } = getCapacitycheck(arr_price[l], cmd, tempdata3[x]['venue'], daygrp_key, tablecapacity, paxcapacity)
                        if (pricecheck && capacitycheck) {
                            const packeddata = packscatterdata_allprices('Lunch', tempdata3[x], l + '_lunch' + x, x_itemprice, capacity)
                            tempdata4.push(packeddata)
                        }

                    }
                }

                if (dmonth && tags.includes('Dinner')) {
                    let selected_mth = dmonth[strmth]
                    let arr_price = tempdata3[x]['venue'][selectedculture][selectedmealtype][stryear][daygrp_key][selected_mth].split(',')
                    for (let l = 0; l < arr_price.length; l++) {

                        const { pricecheck, x_itemprice } = getPricecheck(arr_price[l], cmd)
                        const { capacitycheck, capacity } = getCapacitycheck(arr_price[l], cmd, tempdata3[x]['venue'], daygrp_key, tablecapacity, paxcapacity)

                        if (pricecheck && capacitycheck) {
                            const packeddata = packscatterdata_allprices('Dinner', tempdata3[x], l + '_dinner' + x, x_itemprice, capacity)
                            tempdata4.push(packeddata)
                        }

                    }
                }
            }
        }
        else if (priceviewmode === 'AVG_PRICE') {
            // if (true) {

            // SORT AVG PRICE THEN
            // AND PRICE CHECK
            // calculate avg price
            for (let x = 0; x < tempdata3.length; x++) {

                const daygrp_key = daygrp_keys[x]
                const { lmonth, dmonth, cmd } = tempdata3[x]['venue'][selectedculture][selectedmealtype][stryear][daygrp_key]
                let totalsum = 0
                let totaldenom = 0
                let mealofdaystr = ''
                if (lmonth && tags.includes('Lunch')) {
                    let selected_mth = lmonth[strmth]
                    let arr_price = tempdata3[x]['venue'][selectedculture][selectedmealtype][stryear][daygrp_key][selected_mth].split(',')
                    for (let i = 0; i < arr_price.length; i++) {
                        let p = arr_price[i].split(':')[0]
                        if (p > 0) {
                            totalsum += parseInt(p)
                        }
                        else {
                            totalsum += 0
                        }
                    }
                    totaldenom += parseInt(arr_price.length)
                    mealofdaystr += 'Lunch'
                }

                if (dmonth && tags.includes('Dinner')) {
                    let selected_mth = dmonth[strmth]
                    let arr_price = tempdata3[x]['venue'][selectedculture][selectedmealtype][stryear][daygrp_key][selected_mth].split(',')
                    for (let i = 0; i < arr_price.length; i++) {
                        let p = arr_price[i].split(':')[0]
                        if (p > 0) {
                            totalsum += parseInt(p)
                        }
                        else {
                            totalsum += 0
                        }
                    }
                    totaldenom += parseInt(arr_price.length)
                    mealofdaystr = mealofdaystr.length > 0 ? mealofdaystr + ' & Dinner' : 'Dinner'
                }

                const packeddata = packscatterdata_avg(mealofdaystr, tempdata3[x], totalsum / totaldenom, cmd)
                avgdata.push(packeddata)

            }

            // ensure price is within range
            let new_avgdata = []
            for (let i = 0; i < avgdata.length; i++) {
                const { cmd, x } = avgdata[i]
                const int_itemprice = parseInt(x)
                let pricecheck = false

                if (cmd === ALL_SHARE_SAME_CAPACITY) {
                    pricecheck = selectedmealtype === 'banquet'
                        ? parseInt(rangebanquetpriceminmax[0]) < int_itemprice && int_itemprice < parseInt(rangebanquetpriceminmax[1])
                        : parseInt(rangebuffetpriceminmax[0]) < int_itemprice && int_itemprice < parseInt(rangebuffetpriceminmax[1])
                }
                else if (cmd === PRICE_POINT_TO_UNIQUE_CAPACITY) {
                    pricecheck = selectedmealtype === 'banquet'
                        ? parseInt(rangebanquetpriceminmax[0]) < int_itemprice && int_itemprice < parseInt(rangebanquetpriceminmax[1])
                        : parseInt(rangebuffetpriceminmax[0]) < int_itemprice && int_itemprice < parseInt(rangebuffetpriceminmax[1])
                }
                if (pricecheck) {
                    new_avgdata.push(avgdata[i])
                }

            }

            avgdata = new_avgdata.slice()

        }

        // default sort : 

        // else if(sort===TIERSORT){
        tempdata4.sort((a, b) => a.tier - b.tier)
        avgdata.sort((a, b) => a.tier - b.tier)
        // }

        //  or sort
        if (sort === PRICESORT) {
            tempdata4.sort((a, b) => a.x - b.x).reverse()
            avgdata.sort((a, b) => a.x - b.x).reverse()
        }
        else if (sort === ALPHABETSORT) {
            tempdata4.sort((a, b) => a.y.localeCompare(b.y)).reverse()
            avgdata.sort((a, b) => a.y.localeCompare(b.y)).reverse()
        }
        else if (sort === REVIEWNUMSORT) {
            tempdata4.sort((a, b) => a.review_num - b.review_num).reverse()
            avgdata.sort((a, b) => a.review_num - b.review_num).reverse()
        }


        // AND SORT
        // // sort by price
        // if (pricesort === 1) {
        //     tempdata4.sort((a, b) => a.x - b.x).reverse()
        //     avgdata.sort((a, b) => a.x - b.x).reverse()
        // }
        // else if (pricesort === 2) {
        //     tempdata4.sort((a, b) => b.x - a.x).reverse()
        //     avgdata.sort((a, b) => b.x - a.x).reverse()
        // }

        // // sort by alphabetical order
        // if (alphasort === 1) {
        //     tempdata4.sort((a, b) => a.y.localeCompare(b.y)).reverse()
        //     avgdata.sort((a, b) => a.y.localeCompare(b.y)).reverse()
        // }
        // else if (alphasort === 2) {
        //     tempdata4.sort((a, b) => b.y.localeCompare(a.y)).reverse()
        //     avgdata.sort((a, b) => b.y.localeCompare(a.y)).reverse()
        // }

        // // sort by total number of reviews
        // if (reviewnumsort === 1) {
        //     tempdata4.sort((a, b) => a.review_num - b.review_num).reverse()
        //     avgdata.sort((a, b) => a.review_num - b.review_num).reverse()
        // }
        // else if (reviewnumsort === 2) {
        //     tempdata4.sort((a, b) => b.review_num - a.review_num).reverse()
        //     avgdata.sort((a, b) => b.review_num - a.review_num).reverse()
        // }

        let cmd = (priceviewmode === 'ALL_PRICES' && tempdata4.length === 0) || (priceviewmode === 'AVG_PRICE' && avgdata.length === 0) ? 'NODATA' : ''

        // let totalavgprice = 0
        // for (let i = 0; i < avgdata.length; i++) {
        //     totalavgprice += parseInt(avgdata[i]['x'])
        // }
        // console.log('totalavgprice', totalavgprice)
        // console.log('avg price int : ', totalavgprice / parseInt(avgdata.length))
        return {
            data: priceviewmode === 'ALL_PRICES' ? tempdata4 : [...tempdata4, ...avgdata],
            cmd: cmd
        }
    }

    function updatescatterdata(cmd, data) {
        if (cmd === 'NODATA') {
            setDisplaystate('NODATA')
            setData([])
        }
        else {
            setDisplaystate('')
            setData(data)
        }
    }

    useEffect(() => {
        const new_key = tags.toString() + rangebanquetpriceminmax.toString() + rangebuffetpriceminmax.toString() + rangetableminmax.toString() + rangepaxminmax.toString() + strday + selectedmealtype + selectedculture + strmth + stryear + wt + ht + JSON.stringify(selecteddata) + showsidebar + allcapacity + tablecapacity.toString() + paxcapacity.toString() + leftmain.length
        setScatterkey(new_key)
    }, [data])

    useEffect(() => {
        if (!firsttime) {
            const { data, cmd } = genscatterdata(priceviewmode, processeddata, selectedmealtype, selectedculture, tags, strday, strmth, stryear, tablecapacity, paxcapacity)
            updatescatterdata(cmd, data)
        }
    }, [rangebanquetpriceminmax, rangebuffetpriceminmax, wt, ht, showsidebar, tablecapacity, paxcapacity])

    useEffect(() => {
        if (!firsttime) {
            const { data, cmd } = genscatterdata(priceviewmode, processeddata, selectedmealtype, selectedculture, tags, strday, strmth, stryear, tablecapacity, paxcapacity)
            updatescatterdata(cmd, data)
        }
    }, [sort, pricesort, alphasort, reviewnumsort])

    useEffect(() => {
        if (!firsttime) {
            const { data, cmd } = genscatterdata(priceviewmode, processeddata, selectedmealtype, selectedculture, tags, strday, strmth, stryear, tablecapacity, paxcapacity)
            updatescatterdata(cmd, data)
        }
    }, [priceviewmode])

    const circleclicked = (d) => {
        if (acct_type === 'USER') {
            setSelecteddata((selecteddata) => {
                let new_selectedata = JSON.parse(JSON.stringify(selecteddata))
                let gotnew = true
                for (let i = 0; i < new_selectedata.length; i++) { if (d.username === new_selectedata[i].username) { gotnew = false; break; } }
                if (gotnew) {
                    const { username, y, vendortype_id, vendortypeinterface_id, vendortype, secondaryvendortype, profileimg_url, tags, tier } = d
                    new_selectedata.push({
                        username: username,
                        displayname: y,
                        vendortype_id: vendortype_id,
                        vendortypeinterface_id: vendortypeinterface_id,
                        vendortype: vendortype,
                        secondaryvendortype: secondaryvendortype,
                        profileimg_url: profileimg_url,
                        tags: tags,
                        tier: tier,
                    })
                }
                return new_selectedata
            })
            setRightpanel('SELECTED')
        }
    }

    const paxitem_click = (e) => {
        const { value } = e.target
        if (paxcapacity.includes(value)) {
            setPaxcapacity((old_paxcapacity) => {
                let indextormv = old_paxcapacity.indexOf(value)
                let new_paxcapacity = old_paxcapacity.slice()
                new_paxcapacity.splice(indextormv, 1)
                return new_paxcapacity
            })
        }
        else {
            setPaxcapacity((old_paxcapacity) => {
                let new_paxcapacity = old_paxcapacity.slice()
                new_paxcapacity.push(value)
                return new_paxcapacity
            })
        }
    }

    const tableitem_click = (e) => {

        const { value } = e.target

        const resequencearr = (tablecapacity) => { // place the capacity item string based on the sequence of TABLE_CAPACITY_ARR
            let index_tablecapacity = []
            for (let i = 0; i < tablecapacity.length; i++) {
                index_tablecapacity.push(TABLE_CAPACITY_ARR.indexOf(tablecapacity[i]))
            }
            let new_tablecapacity = index_tablecapacity.sort(function (a, b) { return parseInt(a) - parseInt(b) }).map((itemindex) => TABLE_CAPACITY_ARR[itemindex])

            return new_tablecapacity
        }

        if (tablecapacity.includes(value)) {
            setTablecapacity((old_tablecapacity) => {
                let indextormv = old_tablecapacity.indexOf(value)
                let new_tablecapacity = old_tablecapacity.slice()
                new_tablecapacity.splice(indextormv, 1)
                return resequencearr(new_tablecapacity)
            })
        }
        else {
            setTablecapacity((old_tablecapacity) => {
                let new_tablecapacity = old_tablecapacity.slice()
                new_tablecapacity.push(value)
                return resequencearr(new_tablecapacity)
            })
        }
    }

    const enquire_click = (username) => {
        setShowenquire(true)
        setEnquiredata([vendorusername_data_map[username]])
        setShowscatterportal(false)
    }

    const enquireall_click = () => {
        setShowenquireall(true)
        let local_enquiredata = []
        for (let i = 0; i < selecteddata.length; i++) {
            const { username } = selecteddata[i]
            local_enquiredata.push(vendorusername_data_map[username])
        }
        setEnquiredata(JSON.parse(JSON.stringify(local_enquiredata)))
    }

    const deleteselecteditem = (index) => {
        let local_selecteddata = JSON.parse(JSON.stringify(selecteddata)).slice()
        local_selecteddata.splice(index, 1)
        setSelecteddata(local_selecteddata)
        if (allorselected === 'Selected') { assembleselecteddata(local_selecteddata) }
    }

    const updateViewingmode = (e) => {
        const { value } = e.target
        setPriceviewmode(value)
    }

    const ENQUIRY_PORTAL = <Portalmodal
        portaltype={'commonportal'}
        open={showenquire}
        onClose={() => { setShowenquire(false); setEnquiredata([]) }}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={{ 'WEB': webtabletportalcss, 'TABLET': webtabletportalcss, 'MOBILE': mobileportalcss }[device]}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        <Enquirycontent
            from="Comparevenue"
            userdata={loggeduserdata}
            data={enquiredata}
            allowchat={false}
            allowtelegram={false}
            allowwhatsapp={false}
            allowemail={true}
            oneormany={'ONE'} // send to only one vendor
            vt={'Venue'} // use to determine 
            svt={''}
        />
    </Portalmodal>

    const ENQUIRYALL_PORTAL = <Portalmodal
        portaltype={'commonportal'}
        open={showenquireall}
        onClose={() => { setShowenquireall(false); setEnquiredata([]) }}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={{ 'WEB': webtabletportalcss, 'TABLET': webtabletportalcss, 'MOBILE': mobileportalcss }[device]}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        <Enquirycontent
            from='Comparevenue'
            userdata={loggeduserdata}
            data={enquiredata}
            allowchat={false}
            allowtelegram={false}
            allowwhatsapp={false}
            allowemail={true}
            oneormany={'MANY'} // send to many vendors
            vt={'Venue'}
            svt={''}
        />
    </Portalmodal>

    const HELP_PORTAL = <Portalmodal
        portaltype={'commonportal'}
        open={showhelp}
        onClose={() => { setShowhelp(false) }}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={{ 'WEB': webtabletportalcss, 'TABLET': webtabletportalcss, 'MOBILE': mobileportalcss }[device]}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        <div className='Comparevenue_mobileportal' >
            <h2 style={{ position: 'absolute', left: 0, margin: 10 }}>Help</h2>
            <div className='Comparevenue_columnitem' style={{ padding: '60px 10px 10px 10px' }}>
                <div>
                    This tool for you to assess different package/pricelist offers from various venues.
                    You can explore and compare each venue's package/pricelist according to price and the capacity for hosting guests.
                    The venue corresponding to each package/pricelist retains the authority to determine the actual price.
                </div>
                <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column' }}>
                    <div>Things to take note of : </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5 }}>
                        <div id='blackline' style={{ width: 1, height: 'auto', borderLeft: '5px solid var(--maindark)' }} />
                        <div id='dark' style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ padding: '0px 0px 0px 10px' }}>Price indicates the cost of a single table for 10 pax.</div>
                            <div style={{ padding: '5px 0px 0px 10px' }}>Capacity indicates the venue's minimum and maximum capacity, which the venue is able to host.</div>
                        </div>
                    </div>
                </div>

                {acct_type === 'USER'
                    ? null
                    : <div className='Comparevenue_inputfeatureroot'>
                        {showinput
                            ? <>
                                <div>Extra Feature Code: </div>
                                <input
                                    className='Comparevenue_enterfeatureinput'
                                    value={venueextrafeaturecode}
                                    onChange={(e) => setVenueextrafeaturecode(e.target.value)}
                                />
                                <button
                                    className='Comparevenue_enterfeature'
                                    onClick={(e) => {
                                        if (venueextrafeaturecode === '1314') {
                                            setShowextrafeature(true)
                                        }
                                    }}

                                >
                                    Enter Feature Code
                                </button>
                            </>
                            : <button
                                className='Comparevenue_enterfeature'
                                onClick={() => setShowinput(true)}
                            >
                                Sneak peek at the latest feature.
                            </button>}
                    </div>}

            </div>
        </div>
    </Portalmodal>

    const genvendorobj = (scatterportalvendorobj, uniquedetaildata) => {
        // return <>{JSON.stringify(uniquedetaildata)}</>
        if (scatterportalvendorobj && scatterportalvendorobj[0]) {
            const username = scatterportalvendorobj[0]

            const {
                displayname, tier, link, profileimg_url, secondaryvendortype, tags,
                vendortype, vendortype_id, vendortypeinterface_id, venue
                // color, culture, datalevel, id, mealofday, profileimg_url, secondaryvendortype, tags, username, vendortype, vendortype_id, vendortypeinterface_id 
            } = uniquedetaildata[username]

            let dinnerprice_arr = []
            let lunchprice_arr = []
            console.log('scatterportalvendorobj', scatterportalvendorobj)
            for (let i = 0; i < scatterportalvendorobj[1].length; i++) {
                const { x, mealofday } = scatterportalvendorobj[1][i]
                if (mealofday === 'Dinner') dinnerprice_arr.push(x)
                if (mealofday === 'Lunch') lunchprice_arr.push(x)
            }

            dinnerprice_arr = dinnerprice_arr.sort(function (a, b) { return parseInt(a) - parseInt(b) }).map(item => item)
            lunchprice_arr = lunchprice_arr.sort(function (a, b) { return parseInt(a) - parseInt(b) }).map(item => item)

            let isselected = false
            for (let x = 0; x < selecteddata.length; x++) {
                if (selecteddata[x].username === username) {
                    isselected = true
                    break
                }
            }

            const mealcard_dom = (lunchordinner, mealarr) => {
                let capacitymap = getCapacity(
                    username,
                    lunchordinner,
                    selectedmealtype, selectedculture,
                    strday, strmth, stryear
                )

                console.log('mealarr', mealarr)
                console.log('capacitymap', capacitymap)

                return mealarr.length > 0
                    ? <>
                        <div className='Comparevenue_selecteddatacardtitle' style={{ margin: '10px 0px 0px' }}>{`${lunchordinner} ( ${daymap[strday] + ' / ' + mtharr[strmth] + ' / ' + stryear} )`}</div>
                        <div className='Comparevenue_btmcardrow' style={{ display: 'flex', flexDirection: 'row', overflowX: mealarr.length > 3 ? 'scroll' : 'auto' }}>
                            {mealarr.map((price, index) => {
                                return <div key={index + 'pricepoint'} style={{ minWidth: 100, width: 100, display: 'flex', flexDirection: 'column', border: '1px solid var(--maindark)', marginRight: 5 }}>
                                    <div style={{ width: '100%', borderBottom: '1px solid var(--maindark)', padding: 5, display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ fontSize: 10 }}>Price</div>
                                        <div>{'$ ' + price}</div>
                                    </div>
                                    <div style={{ width: '100%', padding: 5, display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ fontSize: 10 }}>Capacity</div>
                                        <div>
                                            {(capacitymap && capacitymap[price])
                                                ? capacitymap[price].map((item, y) => {
                                                    return Array.from(new Set(item.split(','))).map((eachcap, z) => {
                                                        try {
                                                            return <div key={index + '_' + item + '_' + y + '_' + z}>{eachcap}</div>
                                                        }
                                                        catch (e) { return null }
                                                    })
                                                })
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </>
                    : null
            }

            const mealoption_dom = () => {
                return <>
                    <div className='Comparevenue_selecteddatacardtitle' style={{ margin: '10px 0px 0px' }}>Menu</div>
                    <div className='Comparevenue_btmcardrow' style={{ textTransform: 'capitalize' }}>{selectedculture + ' ' + selectedmealtype}</div>
                </>
            }

            const avgprice_dom = (dn) => {

                let tempdata = {}
                for (let i = 0; i < data.length; i++) {
                    if (data[i].y === dn) {
                        tempdata = data[i]
                        break
                    }
                }
                const { x, mealtype, culture, mealofday } = tempdata
                return <div className='Comparevenue_avgpriceportalenquire'>

                    <div className='Scatterplot_tooltiptop' style={{ marginTop: 5, textTransform: 'capitalize' }}>Menu</div>
                    <div style={{ textTransform: 'capitalize' }}>{culture + ' ' + mealtype}</div>

                    <div className='Scatterplot_tooltiptop' style={{ marginTop: 5, display: 'flex', flexDirection: 'column' }}>
                        <div>{mealofday}</div>
                    </div>
                    {selectedmealtype === 'Buffet'
                        ? <div>{'$ ' + x + '++ ( Avg Price per pax )'}</div>
                        : <div>{'$ ' + x + '++ ( Avg Price per table )'}</div>}


                </div>

            }

            return <div
                className={`Comparevenue_colcard Comparevenue_colcard${device}`}
                key={displayname}
                style={isselected ? { border: '2px solid var(--mainpink)', backgroundColor: 'var(--subpink)', marginBottom: 0 } : { border: '1px solid var(--maindark)', backgroundColor: '#ffffff', marginBottom: 0 }}
            >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: 'max-content', height: '100%', border: '1px solid var(--subgrey)', borderRadius: 60 }}>
                        <div
                            style={{
                                width: 40, height: 40, borderRadius: 40,
                                backgroundImage: `url('https://asset.weavebliss.com/assets/sprites/${production ? 'p' : 'up'}/venue.png')`,
                                backgroundPositionY:
                                    ((parseInt(profileimgpositionarr.indexOf(username)) * -60)
                                        + (parseInt(profileimgpositionarr.indexOf(username)) * -10)) * (4 / 6)
                                    + 'px',
                                backgroundPositionX: 0,
                                backgroundSize: '40px',
                                backgroundRepeat: 'no-repeat',
                                backgroundClip: 'padding-box'
                            }}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5 }}>
                        <div className='Comparevenue_selecteddatacardtitle' style={{ marginTop: 0 }}>Venue</div>
                        <div style={{ whiteSpace: 'break-spaces', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <div>{displayname}</div>
                            {tier === 0
                                ? null
                                : <div
                                    className='Comparevenue_diamondroot'
                                    style={{
                                        backgroundImage: `url('https://asset.weavebliss.com/assets/sprites/diamonds.png')`,
                                        backgroundPositionY: TIER_DIAMOND_POSITION[tier].y + 'px'
                                    }}
                                />}
                        </div>

                    </div>
                </div>
                {priceviewmode === 'ALL_PRICES'
                    ? <div className='Comparevenue_colcardbtm'>
                        {mealoption_dom()}
                        {mealcard_dom('Lunch', lunchprice_arr)}
                        {mealcard_dom('Dinner', dinnerprice_arr)}
                    </div>
                    : <div className='Comparevenue_colcardbtm'>
                        {avgprice_dom(displayname)}
                    </div>}
                <div className='Comparevenue_colcardrow'>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        <Link id={scatterportalvendorobj[0]} style={{ width: 0, height: 0, visibility: 'hidden' }} to={'/vendorlookup/' + scatterportalvendorobj[0]} target="_blank" />
                        <Link id={scatterportalvendorobj[0] + '_review'} style={{ width: 0, height: 0, visibility: 'hidden' }} to={'/vendorlookup/' + scatterportalvendorobj[0] + '/review'} target="_blank" />
                        <Link id={scatterportalvendorobj[0] + '_pricelist'} style={{ width: 0, height: 0, visibility: 'hidden' }} to={'/vendorlookup/' + scatterportalvendorobj[0] + '/pricelist'} target="_blank" />
                        {(vendorusername_data_map && vendorusername_data_map[username] && vendorusername_data_map[username]['link'])
                            ? <a id={scatterportalvendorobj[0] + '_a'} href={vendorusername_data_map[username]['link']} style={{ width: 0, height: 0, visibility: 'hidden' }} target="_blank" ></a>
                            : null}
                        <button className='Comparevenue_viewvendor' onClick={() => { document.getElementById(scatterportalvendorobj[0] + '_a').click() }} >Website</button>
                        <div className='Comparevenue_border' />
                        <button className='Comparevenue_viewvendor' onClick={() => { document.getElementById(scatterportalvendorobj[0] + '_review').click() }} >Review</button>
                        <div className='Comparevenue_border' />
                        <button className='Comparevenue_viewvendor' onClick={() => { document.getElementById(scatterportalvendorobj[0] + '_pricelist').click() }} >Pricelist</button>
                        <div className='Comparevenue_border' />
                        <button className='Comparevenue_viewvendor' onClick={() => { document.getElementById(scatterportalvendorobj[0]).click() }}>View</button>

                    </div>
                    <div>
                        <div className='Comparevenue_viewvendorenquire' onClick={() => enquire_click(scatterportalvendorobj[0])}>Enquire</div>
                    </div>
                </div>

                {!isselected && acct_type === 'USER'
                    ? <button
                        className={isselected ? 'Comparevenue_selecteditem' : 'Comparevenue_selectednon'}
                        onClick={() => circleclicked(uniquedetaildata[username])}
                    >
                        Select
                    </button>
                    : null}
            </div>
        }


    }
    const SCATTERPLOT_PORTAL = <Portalmodal
        portaltype={'commonportal'}
        open={showscatterplotportal}
        onClose={() => {
            setShowscatterportal(false);
        }}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={
            {
                'WEB': webtabletportalcssscatterplot,
                'TABLET': webtabletportalcssscatterplot,
                'MOBILE': mobileportalcssscatterplot
            }[device]
        }
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        {showscatterplotportal ? genvendorobj(scatterportalvendorobj, uniquedetaildata) : null}
    </Portalmodal>

    const RECOMMENDOTHERDEVICE_PORTAL = <Portalmodal
        portaltype={'commonportal'}
        open={showrecommendotherdevice}
        onClose={() => { setShowrecommendotherdevice(false) }}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={{ ...mobileportalcss, ...{ 'WEB': { width: '50%', height: 130 }, 'TABLET': { width: '40%', height: 130 }, 'MOBILE': { width: '80%', height: 130 } }[device] }}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        <div className='Comparevenue_mobileportal' style={{ padding: "60px 10px 10px", display: 'flex', placeContent: 'center', placeItems: 'center', textAlign: 'center' }} >
            <div style={{ height: 60 }}>The price chart isn't available on this mobile. Please use a desktop to access this feature.</div>
        </div>
    </Portalmodal>

    const MOBILEFILTER_PORTAL = <Portalmodal
        portaltype={'commonportal'}
        open={showmobilefilter}
        onClose={() => { setShowmobilefilter(false) }}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={mobileportalcss}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        <div className='Comparevenue_mobileportal'>

            <h2 style={{ left: 0, margin: 10 }}>Filter</h2>

            <div className='Visualisevenue_subtitle_wt' style={{ borderTop: '1px dotted #707070', flexWrap: 'wrap', width: '100%' }} />

            <div className='Visualisevenue_title' style={{ padding: 10 }}>
                <div className='Visualisevenue_lefttitle'>
                    <div style={{ textAlign: 'start' }}>Wedding Venue</div>
                    <div style={{ textAlign: 'start' }}>Price Comparison Chart</div>
                </div>

                <div className='Visualisevenue_righttitle'>
                    🇸🇬
                </div>
            </div>

            <div className='Visualisevenue_subtitle_wt' style={{ borderTop: '1px dotted #707070', flexWrap: 'wrap', width: '100%' }} />

            <div className='Visualrevenue_menubtnroot' style={{ padding: '10px 0px 0px 0px' }}>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '0px 10px 10px 10px' }}>
                    <button
                        className={`Visualisevenue_menubtn ${tags.includes('Lunch') ? 'Visualisevenue_menubtnselected' : ''}`}
                        onClick={(e) => {
                            let currtags = tags.slice()
                            currtags.includes('Lunch') ? currtags.splice(currtags.indexOf('Lunch'), 1) : currtags.push('Lunch')
                            setTags(currtags)
                        }}
                    >
                        <div>Lunch</div>
                        <div className='Visualisevenue_menucirclered' />
                    </button>
                    <button
                        className={`Visualisevenue_menubtn ${tags.includes('Dinner') ? 'Visualisevenue_menubtnselected' : ''}`}
                        onClick={(e) => {
                            let currtags = tags.slice()
                            currtags.includes('Dinner') ? currtags.splice(currtags.indexOf('Dinner'), 1) : currtags.push('Dinner')
                            setTags(currtags)
                        }}
                    >
                        <div>Dinner</div>
                        <div className='Visualisevenue_menucircleblue' />
                    </button>
                </div>
            </div>

            < div className='Visualisevenue_subtitle_wt' style={{ borderTop: '1px dotted #707070', flexWrap: 'wrap', width: '100%' }} />

            {/* Avg Price */}
            <div className='Comparevenue_datecol' style={{ padding: '0px 10px 0px' }}>
                <div className='Comparevenue_datetitle'>Price Viewing Mode</div>
                <div style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                    <button
                        onClick={updateViewingmode}
                        value='ALL_PRICES'
                        className={`Visualisevenue_menubtn ${priceviewmode === 'ALL_PRICES' ? 'Visualisevenue_menubtnselected' : ''}`}
                    >
                        All Prices
                    </button>
                    {acct_type === 'USER' || (acct_type !== 'USER' && showextrafeature)
                        ? <button
                            onClick={updateViewingmode}
                            value='AVG_PRICE'
                            className={`Visualisevenue_menubtn ${priceviewmode === 'AVG_PRICE' ? 'Visualisevenue_menubtnselected' : ''}`}
                        >
                            Avg Price
                        </button>
                        : null}
                </div>
            </div>

            {/* Meal */}
            <div className='Comparevenue_datecol' style={{ padding: '0px 10px 0px' }}>
                <div className='Comparevenue_datetitle'>Food Service Styles</div>
                <div style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                    {allpossiblemealtypes.map((singlemenu, index) => {
                        return <button
                            key={index + singlemenu}
                            value={singlemenu}
                            className={`Visualisevenue_menubtn ${singlemenu === selectedmealtype ? 'Visualisevenue_menubtnselected' : ''}`}
                            onClick={(e) => {
                                if (selectedmealtype !== e.target.value) {
                                    setAllcapacity(true)
                                    setCustomise('')
                                    setSelectedmealtype(e.target.value) // banquet, buffet

                                    let local_tablecapacity
                                    let local_paxcapacity
                                    if (e.target.value === 'banquet') {
                                        setTablecapacity(TABLE_CAPACITY_ARR)
                                        local_tablecapacity = TABLE_CAPACITY_ARR
                                    }
                                    else {
                                        setPaxcapacity(PAX_CAPACITY_ARR)
                                        local_paxcapacity = PAX_CAPACITY_ARR
                                    }

                                    const { data, cmd } = genscatterdata(priceviewmode, processeddata, e.target.value, selectedculture, tags, strday, strmth, stryear, local_tablecapacity, local_paxcapacity)
                                    updatescatterdata(cmd, data)

                                }
                            }}

                        >
                            {singlemenu}
                        </button>

                    })}
                </div>
            </div>

            <div className='Visualisevenue_subtitle_wt' style={{ borderTop: '1px dotted #707070', flexWrap: 'wrap', width: '100%' }} />

            {/* Cultures */}
            <div className='Comparevenue_datecol' style={{ padding: '0px 10px 0px' }}>
                <div className='Comparevenue_datetitle'>Menu Options</div>
                <div style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                    {allpossiblecultures.map((singlemenutype, index) => {
                        return <button
                            key={index + singlemenutype}
                            value={singlemenutype}
                            className={`Visualisevenue_menubtn ${singlemenutype === selectedculture ? 'Visualisevenue_menubtnselected' : ''}`}
                            onClick={(e) => {
                                if (selectedculture !== e.target.value) {
                                    setSelectedculture(e.target.value)
                                    const { data, cmd } = genscatterdata(priceviewmode, processeddata, selectedmealtype, e.target.value, tags, strday, strmth, stryear, tablecapacity, paxcapacity)
                                    updatescatterdata(cmd, data)
                                }
                            }}
                        >
                            {singlemenutype}
                        </button>

                    })}
                </div>
            </div>

            <div className='Visualisevenue_subtitle_wt' style={{ borderTop: '1px dotted #707070', flexWrap: 'wrap', width: '100%' }} />

            <div className='Visualrevenue_menubtnroot' style={{ flexDirection: 'column' }}>

                {/* day */}
                <div className='Comparevenue_datecol' style={{ padding: '0px 10px 0px' }}>
                    <div className='Comparevenue_datetitle'>Day</div>
                    <div style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                        {['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'].map((day, index) => {
                            return <button
                                key={day + index}
                                value={day.toLocaleLowerCase()}
                                className={`Visualisevenue_menubtn ${day.toLowerCase() === strday ? 'Visualisevenue_menubtnselected' : ''}`}
                                onClick={() => {
                                    if (strday !== day.toLowerCase()) {
                                        let strday_val = day.toLowerCase()
                                        setStrday(strday_val)
                                        setDate('')
                                        const { data, cmd } = genscatterdata(priceviewmode, processeddata, selectedmealtype, selectedculture, tags, strday_val, strmth, stryear, tablecapacity, paxcapacity)
                                        updatescatterdata(cmd, data)
                                    }
                                }}
                            >
                                {day}
                            </button>
                        })}
                    </div>
                </div>

                {/* month */}
                <div className='Comparevenue_datecol' style={{ padding: '0px 10px', margin: 0 }}>
                    <div className='Comparevenue_datetitle'>Month</div>
                    <div style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                        {mtharr.map((mth, index) => {
                            return <button
                                key={mth + index}
                                className={`Visualisevenue_menubtn ${index.toString() === strmth ? 'Visualisevenue_menubtnselected' : ''}`}
                                onClick={() => {
                                    if (strmth !== index.toString()) {
                                        let strmth_val = index.toString()
                                        setStrmth(strmth_val)
                                        setDate('')
                                        const { data, cmd } = genscatterdata(priceviewmode, processeddata, selectedmealtype, selectedculture, tags, strday, strmth_val, stryear, tablecapacity, paxcapacity)
                                        updatescatterdata(cmd, data)
                                    }
                                }}
                            >
                                {mth}
                            </button>
                        })}
                    </div>
                </div>

                {/* year */}
                <div className='Comparevenue_datecol' style={{ padding: '0px 10px' }}>
                    <div className='Comparevenue_datetitle'>Year</div>
                    <div style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                        {allpossibleyears.map((yr, index) => {
                            return <button
                                key={yr + index}
                                className={`Visualisevenue_menubtn ${yr === stryear ? 'Visualisevenue_menubtnselected' : ''}`}
                                onClick={() => {
                                    if (stryear !== yr.toString()) {
                                        let stryear_val = stryear === index.toString() ? '' : yr.toString()
                                        setStryear(stryear_val)
                                        setDate('')
                                        const { data, cmd } = genscatterdata(priceviewmode, processeddata, selectedmealtype, selectedculture, tags, strday, strmth, stryear_val, tablecapacity, paxcapacity)
                                        updatescatterdata(cmd, data)
                                        // recalculatexaxismax(data)// calculate the x-axis of scatterplot max value
                                    }



                                }}
                            >
                                {yr}
                            </button>
                        })}
                    </div>
                </div>

            </div>

            <div className='Visualisevenue_subtitle_wt' style={{ borderTop: '1px dotted #707070', width: '100%' }} />

            {/* VENUETYPE TAGS */}
            <>
                <div className='Comparevenue_datecol' style={{ padding: '0px 10px 0px' }}>
                    <div className='Comparevenue_datetitle'>Venue Tags</div>
                    <div style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                        {VENUETYPE_TAGS.map((item, index) => {
                            return <button
                                key={index + item}
                                value={item}
                                className={`Visualisevenue_menubtn ${tags.includes(item) ? 'Visualisevenue_menubtnselected' : ''}`}
                                onClick={(e) => {

                                    let newtags = tags.slice()
                                    if (newtags.includes(e.target.value)) {
                                        newtags.splice(newtags.indexOf(e.target.value), 1)
                                    }
                                    else {
                                        newtags.push(e.target.value)
                                    }
                                    setTags(newtags)
                                    const { data, cmd } = genscatterdata(priceviewmode, processeddata, selectedmealtype, selectedculture, newtags, strday, strmth, stryear, tablecapacity, paxcapacity)
                                    updatescatterdata(cmd, data)

                                }}
                            >
                                {item}
                            </button>

                        })}
                    </div>
                </div>
                <div className='Visualisevenue_subtitle_wt' style={{ borderTop: '1px dotted #707070', width: '100%' }} />
            </>

            <div className='Visualrevenue_menubtnroot' style={{ minHeight: 80 }}>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '10px 10px 0px 10px', flexWrap: 'wrap' }}>
                    <button
                        className={`Visualisevenue_menubtn ${customise === 'PRICE' ? 'Visualisevenue_menubtnselected' : ''}`}
                        value='PRICE'
                        onClick={(e) => {
                            if (customise === 'PRICE') {
                                setCustomise('')
                            }
                            else {
                                setCustomise(e.target.value)
                            }
                        }}
                    >
                        Price
                    </button>
                    <button
                        className={`Visualisevenue_menubtn ${customise === 'TABLE' ? 'Visualisevenue_menubtnselected' : ''}`}
                        value='TABLE'
                        onClick={(e) => setCustomise(customise === 'TABLE' ? '' : e.target.value)}
                    >
                        Table Capacity
                    </button>
                </div>
            </div>

            {{
                'TABLE': <div style={{ textAlign: 'center', padding: '5px 0px' }}>The capacity is based on the no. of tables, with each table seating <b style={{ color: 'var(--mainpink)' }}>10 pax</b>. <b>"up to 5"</b> means <b>"up to 5 tables"</b>.</div>,
                'PAX': <div style={{ textAlign: 'center', padding: '5px 0px' }}>The capacity is based by the no. of guests attending (pax). <b>"up to 100"</b> means <b>"up to 100 pax"</b>.</div>
            }[customise]}

            {{
                'PRICE': <div className='Visualisevenue_menuroot Visualisevenue_menuroot_wt' style={{ width: '100%', padding: 30 }}>
                    {{
                        'buffet': <Range
                            key={'comparevenuerange0'}
                            dots={false}
                            allowCross={false}
                            step={buffetstep}
                            min={minbuffetprice}
                            max={maxbuffetprice}
                            defaultValue={rangebuffetpriceminmax}
                            value={rangebuffetpriceminmax}
                            onChange={(val) => {
                                setRangebuffetpriceminmax(JSON.parse(JSON.stringify(val)))
                            }}
                        />,
                        'banquet': <Range
                            key={'comparevenuerange3'}
                            dots={false}
                            allowCross={false}
                            step={banquetstep}
                            min={minbanquetprice}
                            max={maxbanquetprice}
                            defaultValue={rangebanquetpriceminmax}
                            value={rangebanquetpriceminmax}
                            onChange={(val) => {
                                setRangebanquetpriceminmax(JSON.parse(JSON.stringify(val)))
                            }}
                        />
                    }[selectedmealtype]}

                    <div className="Pricelist_filtermoneyroot">
                        <div className="Pricelist_filterdollarbox" style={{ height: 80 }} >
                            <div className="Pricelist_filterpricelabel">min price</div>
                            {selectedmealtype === 'banquet'
                                ? <div key={rangebanquetpriceminmax[0]} className="Pricelist_filterdollar">{'$ ' + Processmoney(rangebanquetpriceminmax[0] ? rangebanquetpriceminmax[0] : 0) + ' SGD'}</div>
                                : <div key={rangebuffetpriceminmax[0]} className="Pricelist_filterdollar">{'$ ' + Processmoney(rangebuffetpriceminmax[0] ? rangebuffetpriceminmax[0] : 0) + ' SGD'}</div>}
                        </div>
                        <div className="Pricelist_filterhyphen">-</div>
                        <div className="Pricelist_filterdollarbox" style={{ height: 80 }}>
                            <div className="Pricelist_filterpricelabel">max price</div>
                            {selectedmealtype === 'banquet'
                                ? <div key={rangebanquetpriceminmax[1]} className="Pricelist_filterdollar">{'$ ' + Processmoney(rangebanquetpriceminmax[1] ? rangebanquetpriceminmax[1] : 0) + ' SGD'}</div>
                                : <div key={rangebuffetpriceminmax[1]} className="Pricelist_filterdollar">{'$ ' + Processmoney(rangebuffetpriceminmax[1] ? rangebuffetpriceminmax[1] : 0) + ' SGD'}</div>}
                        </div>
                    </div>

                </div>,
                'TABLE': <div className='Visualisevenue_menuroot Visualisevenue_menuroot_wt' style={{ width: '100%', padding: '10px 30px 30px 30px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        {priceviewmode === 'ALL_PRICES'
                            ? TABLE_CAPACITY_ARR.map((item, i) => {
                                return <button
                                    key={i + item}
                                    className={`Comparevenue_capacitybtn  ${tablecapacity.includes(item) ? 'Comparevenue_capacitybtnselected' : ''}`}
                                    value={item}
                                    onClick={tableitem_click}
                                >
                                    {item}
                                </button>
                            })
                            : <div className='Comparevenue_avgpricecaptext'>Capacity filter not available in "Avg Price Mode"</div>}
                    </div>
                </div>,
                "PAX": <div className='Visualisevenue_menuroot Visualisevenue_menuroot_wt' style={{ width: '100%', padding: '10px 30px 30px 30px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        {priceviewmode === 'ALL_PRICES'
                            ? PAX_CAPACITY_ARR.map((item, i) => {
                                return <button
                                    key={i + item}
                                    className={`Comparevenue_capacitybtn  ${paxcapacity.includes(item) ? 'Comparevenue_capacitybtnselected' : ''}`}
                                    value={item}
                                    onClick={paxitem_click}
                                >
                                    {item}
                                </button>
                            })
                            : <div className='Comparevenue_avgpricecaptext'>Capacity filter not available in "Avg Price Mode"</div>}
                    </div>
                </div>
            }[customise]}

        </div>
    </Portalmodal >

    const MOBILESELECTION_PORTAL = <Portalmodal
        portaltype={'commonportal'}
        open={showmobileselection}
        onClose={() => { setShowmobileselection(false) }}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={mobileportalcss}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'
    >
        <div className='Comparevenue_mobileportal' >
            <h2 style={{ position: 'absolute', left: 0, margin: 10 }}>Selection</h2>
            <div className='Comparevenue_columnitem' style={{ padding: '60px 10px 10px 10px' }}>
                {selecteddata.map((item, index) => {
                    let liked = false
                    for (let i = 0; i < favourite.length; i++) {
                        const vendortypeinterface_id = favourite[i][0]
                        if (vendortypeinterface_id === item.vendortypeinterface_id) {
                            liked = true
                        }
                    }
                    let color_of_heart = liked ? 'rgb(210, 35, 42)' : 'grey'

                    const banquet_prices = vendorusername_data_map[item.username]['prices']['banquet']
                    const buffet_prices = vendorusername_data_map[item.username]['prices']['buffet']

                    const banquetprices_dom = banquet_prices && banquet_prices.length
                        ? <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className='Comparevenue_selecteddatacardtitle'>{'Banquet ( per table )'}</div>
                            <div className='Comparevenue_selecteddatacardrow'>
                                {banquet_prices.map((p, i) => {
                                    return <div className='Comparevenue_selecteddatacardrowitem' key={'banquet_price_' + i}>{'$ ' + p}</div>
                                })}
                            </div>
                        </div>
                        : null

                    const buffetprices_dom = buffet_prices && buffet_prices.length > 0
                        ? <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className='Comparevenue_selecteddatacardtitle'>{'Buffet ( per pax )'}</div>
                            <div className='Comparevenue_selecteddatacardrow'>
                                {buffet_prices.map((p, i) => {
                                    return <div className='Comparevenue_selecteddatacardrowitem' key={'buffet_price_' + i}>{'$ ' + p}</div>
                                })}
                            </div>
                        </div>
                        : null

                    const tags_dom = <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className='Comparevenue_selecteddatacardtitle'>Tags</div>
                        <div className='Comparevenue_tagrow'>
                            {item.tags.map((tag, index) => {
                                return <div className='Comparevenue_tagitem' key={tag + '_' + index}>{tag}</div>
                            })}
                        </div>
                    </div>

                    return <div className={'Comparevenue_selecteddatacard'} key={index + 'selecteddata'}>
                        <button
                            className='Comparevenue_crossbtn'
                            onClick={() => deleteselecteditem(index)}
                        >
                            {iconwrapper(CROSS_ICON, { ...ICON_CSS, width: 15, height: 15 })}
                        </button>
                        <div className='Comparevenue_selectedcarditemcontent' style={{ display: 'flex', flexDirection: 'column', placeContent: 'space-between' }}>
                            <div className='Comparevenue_dndiamondrow'>
                                <div>{item.displayname}</div>
                                <div
                                    className='Comparevenue_diamondroot'
                                    style={{
                                        backgroundImage: `url('https://asset.weavebliss.com/assets/sprites/diamonds.png')`,
                                        backgroundPositionY: TIER_DIAMOND_POSITION[item.tier].y + 'px'
                                    }}
                                />
                            </div>
                            {banquetprices_dom}
                            {buffetprices_dom}
                            {tags_dom}
                            <div className='Comparevenue_colcardrow'>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                    <Link id={item.username} style={{ width: 0, height: 0, visibility: 'hidden' }} to={'/vendorlookup/' + item.username} target="_blank" />
                                    <Link id={item.username + '_review'} style={{ width: 0, height: 0, visibility: 'hidden' }} to={'/vendorlookup/' + item.username + '/review'} target="_blank" />
                                    <Link id={item.username + '_pricelist'} style={{ width: 0, height: 0, visibility: 'hidden' }} to={'/vendorlookup/' + item.username + '/pricelist'} target="_blank" />
                                    {(vendorusername_data_map && vendorusername_data_map[item.username] && vendorusername_data_map[item.username]['link'])
                                        ? <a id={item.username + '_a'} href={vendorusername_data_map[item.username]['link']} style={{ width: 0, height: 0, visibility: 'hidden' }} target="_blank" />
                                        : null}
                                    <button className='Comparevenue_viewvendor' style={{ fontSize: 12, borderRight: '1px solid var(--maindark)' }} onClick={() => { document.getElementById(item.username + '_a').click() }} >Website</button>
                                    <button className='Comparevenue_viewvendor' style={{ fontSize: 12, borderRight: '1px solid var(--maindark)' }} onClick={() => { document.getElementById(item.username + '/review').click() }} >Review</button>
                                    <button className='Comparevenue_viewvendor' style={{ fontSize: 12 }} onClick={() => { document.getElementById(item.username + '_pricelist').click() }} >Pricelist</button>
                                    <button className='Comparevenue_viewvendor' style={{ fontSize: 12 }} onClick={() => { document.getElementById(item.username).click() }}>View</button>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div className='Comparevenue_viewvendorenquire' style={{ fontSize: 12 }} onClick={() => enquire_click(item.username)}>Enquire</div>
                                </div>
                            </div>

                        </div>
                        {acct_type === 'USER'
                            ? <div className='Comparevenue_iconcol'>
                                <button
                                    className='Comparevenue_heartbtn'
                                    onClick={() => {

                                        // check if this vendor is selected
                                        // if yes : unlike
                                        // if no  : like

                                        const { vendortypeinterface_id, vendortype, secondaryvendortype } = item

                                        if (liked) {
                                            let options = {
                                                method: 'POST',
                                                url: USER_UNFAV_A_VENDOR,
                                                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
                                                data: { vendortypeinterface_id, vendortype, secondaryvendortype }
                                            }
                                            axios(options).then((result) => {
                                                const { status } = result
                                                if (status === 200) {
                                                    setFavourite((old_favourite) => {
                                                        let new_favourite = JSON.parse(JSON.stringify(old_favourite))
                                                        for (let i = 0; i < new_favourite.length; i++) {
                                                            if (new_favourite[i][0] === vendortypeinterface_id) {
                                                                new_favourite.splice(i, 1)
                                                            }
                                                        }

                                                        new_favourite = extractVenuefavourite(new_favourite, rawdata)
                                                        return new_favourite

                                                    })
                                                }
                                            })
                                        }
                                        else {
                                            let options = {
                                                method: 'POST',
                                                url: USER_FAV_A_VENDOR,
                                                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
                                                data: { vendortypeinterface_id, vendortype, secondaryvendortype }
                                            }
                                            axios(options).then((result) => {
                                                const { status } = result
                                                if (status === 200) {
                                                    setFavourite((old_favourite) => {
                                                        let new_favourite = JSON.parse(JSON.stringify(old_favourite))
                                                        new_favourite.push([vendortypeinterface_id, vendortype, secondaryvendortype])
                                                        new_favourite = extractVenuefavourite(new_favourite, rawdata)
                                                        return new_favourite
                                                    })
                                                }
                                            })
                                        }

                                    }}
                                >
                                    {iconwrapper(HEARTFILL_ICON, { ...ICON_CSS, color: color_of_heart })}
                                </button>

                            </div>
                            : null}
                    </div>

                })}
            </div>
        </div>
    </Portalmodal>

    const MENU_DOM = <div className='Comparevenue_menu'>
        {device === 'TABLET'
            ? <button className='Comparevenue_tabletclosesidebtn' onClick={() => setShowsidebar(!showsidebar)}>
                {iconwrapper(ARROWDOWNWITHOUTCIRCLE_ICON, { width: 28, height: 28, display: 'flex', placeSelf: 'center' })}
            </button>
            : null}
        {acct_type === 'USER'
            ? <>
                <button
                    className={`Comparevenue_menubtnleft ${rightpanel === 'FILTER' ? 'Visualisevenue_menubtnselected' : ''}`}
                    onClick={() => setRightpanel('FILTER')}
                >
                    Filter
                </button>
                <button
                    className={`Comparevenue_menubtnright ${rightpanel === 'SELECTED' ? 'Visualisevenue_menubtnselected' : ''}`}
                    onClick={() => setRightpanel('SELECTED')}
                >
                    Selection
                </button>
            </>
            : <button
                className={`Comparevenue_menubtnleft ${rightpanel === 'FILTER' ? 'Visualisevenue_menubtnselected' : ''}`}
                style={{ width: '100%', borderRadius: 10 }}
                onClick={() => setRightpanel('FILTER')}
            >
                Filter
            </button>}
        <button className={`Comparevenue_questionmarknonmobile`} onClick={() => { setShowhelp(true) }}>
            <div style={{ alignSelf: 'center', pointerEvents: 'none' }}>?</div>
        </button>
    </div>

    const FILTER_PART = <>
        {/* 
            <div className='Visualisevenue_subtitle Visualisevenue_subtitle_wt'>
                This visualization acts as a tool for you to assess different package and pricelist offers from various venues.
                Based on the visualization, you can explore and compare each venue's package and pricelist according to price and the capacity for hosting guests.
                The venue corresponding to each package or pricelist retains the authority to determine the actual price.
            </div> 
        */}



        {/* Avg Price */}
        <div className='Comparevenue_datetitle' style={{ margin: '10px 0px 0px 0px' }}>Price Viewing Mode</div>
        <div className='Visualrevenue_menubtnroot' >
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '0px 0px 10px 0px', flexWrap: 'wrap' }}>
                <button
                    onClick={updateViewingmode}
                    value='ALL_PRICES'
                    className={`Visualisevenue_menubtn ${priceviewmode === 'ALL_PRICES' ? 'Visualisevenue_menubtnselected' : ''}`}
                >
                    All Prices
                </button>
                {acct_type === 'USER' || (acct_type !== 'USER' && showextrafeature)
                    ? <button
                        onClick={updateViewingmode}
                        value='AVG_PRICE'
                        className={`Visualisevenue_menubtn ${priceviewmode === 'AVG_PRICE' ? 'Visualisevenue_menubtnselected' : ''}`}
                    >
                        Avg Price
                    </button>
                    : null}

            </div>

        </div>

        < div className='Visualisevenue_subtitle_wt' style={{ borderTop: '1px dotted #707070', flexWrap: 'wrap' }} />

        <div className='Comparevenue_datetitle' style={{ margin: '10px 0px 0px 0px' }}>Meals</div>
        <div className='Visualrevenue_menubtnroot' >
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '0px 0px 10px 0px', flexWrap: 'wrap' }}>
                <button
                    className={`Visualisevenue_menubtn ${tags.includes('Lunch') ? 'Visualisevenue_menubtnselected' : ''}`}
                    onClick={(e) => {
                        let currtags = tags.slice()
                        currtags.includes('Lunch') ? currtags.splice(currtags.indexOf('Lunch'), 1) : currtags.push('Lunch')
                        setTags(currtags)

                        const { data, cmd } = genscatterdata(priceviewmode, processeddata, selectedmealtype, selectedculture, currtags, strday, strmth, stryear, tablecapacity, paxcapacity)
                        updatescatterdata(cmd, data)
                    }}
                >
                    <div>Lunch</div>
                    {priceviewmode === 'AVG_PRICE' ? null : <div className='Visualisevenue_menucirclered' />}
                </button>
                <button
                    className={`Visualisevenue_menubtn ${tags.includes('Dinner') ? 'Visualisevenue_menubtnselected' : ''}`}
                    onClick={(e) => {
                        let currtags = tags.slice()
                        currtags.includes('Dinner') ? currtags.splice(currtags.indexOf('Dinner'), 1) : currtags.push('Dinner')
                        setTags(currtags)
                        const { data, cmd } = genscatterdata(priceviewmode, processeddata, selectedmealtype, selectedculture, currtags, strday, strmth, stryear, tablecapacity, paxcapacity)
                        updatescatterdata(cmd, data)
                    }}
                >
                    <div>Dinner</div>
                    {priceviewmode === 'AVG_PRICE' ? null : <div className='Visualisevenue_menucircleblue' />}
                </button>
            </div>
        </div>

        < div className='Visualisevenue_subtitle_wt' style={{ borderTop: '1px dotted #707070', flexWrap: 'wrap' }} />

        {/* Meal */}
        <div className='Comparevenue_datetitle' style={{ margin: '10px 0px 0px 0px' }}>Food Service Styles</div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '0px 0px 10px 0px', flexWrap: 'wrap' }}>
            {allpossiblemealtypes.map((singlemenu, index) => {
                return <button
                    key={index + singlemenu}
                    value={singlemenu}
                    className={`Visualisevenue_menubtn ${singlemenu === selectedmealtype ? 'Visualisevenue_menubtnselected' : ''}`}
                    onClick={(e) => {
                        if (selectedmealtype !== e.target.value) {

                            setAllcapacity(true)
                            setCustomise('')
                            setSelectedmealtype(e.target.value) // banquet, buffet

                            let local_tablecapacity
                            let local_paxcapacity
                            if (e.target.value === 'banquet') {
                                setTablecapacity(TABLE_CAPACITY_ARR)
                                local_tablecapacity = TABLE_CAPACITY_ARR
                            }
                            else {
                                setPaxcapacity(PAX_CAPACITY_ARR)
                                local_paxcapacity = PAX_CAPACITY_ARR
                            }

                            const { data, cmd } = genscatterdata(priceviewmode, processeddata, e.target.value, selectedculture, tags, strday, strmth, stryear, local_tablecapacity, local_paxcapacity)
                            updatescatterdata(cmd, data)


                            // setAllcapacity(true)
                            // setCustomise('')
                            // setSelectedmealtype(e.target.value) // banquet, buffet
                            // let local_tablecapacity
                            // let local_paxcapacity
                            // if (selectedmealtype === 'banquet') {
                            //     setTablecapacity(TABLE_CAPACITY_ARR)
                            // }
                            // else {
                            //     setPaxcapacity(PAX_CAPACITY_ARR)
                            // }

                            // setData(genscatterdata(
                            //     processeddata,
                            //     e.target.value, selectedculture, tags, strday, strmth, stryear,  local_tablecapacity, local_paxcapacity, showsidebar
                            // ))
                        }
                    }}

                >
                    {singlemenu}
                </button>

            })}
        </div>

        <div className='Visualisevenue_subtitle_wt' style={{ borderTop: '1px dotted #707070', flexWrap: 'wrap' }} />

        {/* culture */}
        <div className='Comparevenue_datetitle' style={{ margin: '10px 0px 0px 0px' }}>Menu Options</div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '0px 0px 10px 0px', flexWrap: 'wrap' }}>

            {allpossiblecultures.map((singlemenutype, index) => {
                return <button
                    key={index + singlemenutype}
                    value={singlemenutype}
                    className={`Visualisevenue_menubtn ${singlemenutype === selectedculture ? 'Visualisevenue_menubtnselected' : ''}`}
                    onClick={(e) => {
                        if (selectedculture !== e.target.value) {
                            setSelectedculture(e.target.value)
                            const { data, cmd } = genscatterdata(priceviewmode, processeddata, selectedmealtype, e.target.value, tags, strday, strmth, stryear, tablecapacity, paxcapacity)
                            updatescatterdata(cmd, data)
                        }

                    }}
                >
                    {singlemenutype}
                </button>

            })}
        </div>

        <div className='Visualisevenue_subtitle_wt' style={{ borderTop: '1px dotted #707070', flexWrap: 'wrap' }} />

        <div className='Visualrevenue_menubtnroot' style={{ flexDirection: 'column' }}>

            {/* datepicker */}
            <DatePicker
                dateFormat={'dd/MM/yyyy'}
                withPortal
                fixedHeight
                selected={date}
                onChange={(dateObject) => {

                    var dayOfWeek = dateObject.getDay();

                    // Getting the day name from the array using the day index
                    var month = dateObject.getMonth(); // Returns the month (0-11), so adding 1 to match the real month (1-12)
                    var year = dateObject.getFullYear(); // Returns the year (four digits)

                    let strday_val = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'][parseInt(dayOfWeek)].toLocaleLowerCase()
                    let mth_val = month.toString()
                    let year_val = year.toString()
                    setStrday(strday_val)
                    setStrmth(mth_val)
                    setStryear(year_val)

                    setDate(dateObject)

                    const { data, cmd } = genscatterdata(priceviewmode, processeddata, selectedmealtype, selectedculture, tags, strday_val, mth_val, year_val, tablecapacity, paxcapacity)
                    updatescatterdata(cmd, data)

                }}
                customInput={<CustomDatepicker />}
            />


            {/* day */}
            <div className='Comparevenue_datecol'>
                <div className='Comparevenue_datetitle'>Day</div>
                <div style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                    {['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'].map((day, index) => {
                        return <button
                            key={day + index}
                            value={day.toLocaleLowerCase()}
                            className={`Visualisevenue_menubtn ${day.toLowerCase() === strday ? 'Visualisevenue_menubtnselected' : ''}`}
                            onClick={() => {
                                if (strday !== day.toLowerCase()) {
                                    let strday_val = day.toLowerCase()
                                    setStrday(strday_val)
                                    setDate('')
                                    const { data, cmd } = genscatterdata(priceviewmode, processeddata, selectedmealtype, selectedculture, tags, strday_val, strmth, stryear, tablecapacity, paxcapacity)
                                    updatescatterdata(cmd, data)
                                }
                            }}
                        >
                            {day}
                        </button>
                    })}
                </div>
            </div>

            {/* month */}
            <div className='Comparevenue_datecol' style={{ margin: '0px' }}>
                <div className='Comparevenue_datetitle'>Month</div>
                <div style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                    {mtharr.map((mth, index) => {
                        return <button
                            key={mth + index}
                            className={`Visualisevenue_menubtn ${index.toString() === strmth ? 'Visualisevenue_menubtnselected' : ''}`}
                            onClick={() => {
                                if (strmth !== index.toString()) {
                                    let strmth_val = index.toString()
                                    setStrmth(strmth_val)
                                    setDate('')
                                    const { data, cmd } = genscatterdata(priceviewmode, processeddata, selectedmealtype, selectedculture, tags, strday, strmth_val, stryear, tablecapacity, paxcapacity)
                                    updatescatterdata(cmd, data)
                                }

                            }}
                        >
                            {mth}
                        </button>
                    })}
                </div>
            </div>

            {/* year */}
            <div className='Comparevenue_datecol'>
                <div className='Comparevenue_datetitle'>Year</div>
                <div style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                    {allpossibleyears.map((yr, index) => {
                        return <button
                            key={yr + index}
                            className={`Visualisevenue_menubtn ${yr === stryear ? 'Visualisevenue_menubtnselected' : ''}`}
                            onClick={() => {
                                if (stryear !== yr.toString()) {
                                    let stryear_val = stryear === index.toString() ? '' : yr.toString()
                                    setStryear(stryear_val)
                                    setDate('')
                                    const { data, cmd } = genscatterdata(priceviewmode, processeddata, selectedmealtype, selectedculture, tags, strday, strmth, stryear_val, tablecapacity, paxcapacity, showsidebar)
                                    updatescatterdata(cmd, data)
                                    // recalculatexaxismax(data) // calculate the x-axis of scatterplot max value
                                }
                            }}
                        >
                            {yr}
                        </button>
                    })}
                </div>
            </div>

        </div>

        {(acct_type === 'USER' || (acct_type !== 'USER' && showextrafeature))
            ? <>
                <div className='Visualisevenue_subtitle_wt' style={{ borderTop: '1px dotted #707070', flexWrap: 'wrap' }} />
                {/* venue type tags */}
                <div className='Comparevenue_datetitle' style={{ margin: '10px 0px 0px 0px' }}>Venue Tags</div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '0px 0px 10px 0px', flexWrap: 'wrap' }}>

                    {VENUETYPE_TAGS.map((item, index) => {
                        return <button
                            key={index + item}
                            value={item}
                            className={`Visualisevenue_menubtn ${tags.includes(item) ? 'Visualisevenue_menubtnselected' : ''}`}
                            onClick={(e) => {
                                let newtags = tags.slice()
                                if (newtags.includes(e.target.value)) {
                                    newtags.splice(newtags.indexOf(e.target.value), 1)
                                }
                                else {
                                    newtags.push(e.target.value)
                                }
                                setTags(newtags)
                                const { data, cmd } = genscatterdata(priceviewmode, processeddata, selectedmealtype, selectedculture, newtags, strday, strmth, stryear, tablecapacity, paxcapacity)
                                updatescatterdata(cmd, data)
                            }}
                        >
                            {item}
                        </button>

                    })}
                </div>
            </>
            : null}




        <div className='Visualisevenue_subtitle_wt' style={{ borderTop: '1px dotted #707070' }} />

        <div className='Visualrevenue_menubtnroot'>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: '10px 0px', flexWrap: 'wrap' }}>

                <button
                    className={`Visualisevenue_menubtn ${customise === 'PRICE' ? 'Visualisevenue_menubtnselected' : ''}`}
                    value='PRICE'
                    onClick={(e) => setCustomise(customise === 'PRICE' ? '' : e.target.value)}
                >
                    Price
                </button>

                {{
                    "banquet": <button
                        className={`Visualisevenue_menubtn ${customise === 'TABLE' ? 'Visualisevenue_menubtnselected' : ''}`}
                        value='TABLE'
                        onClick={(e) => setCustomise(customise === 'TABLE' ? '' : e.target.value)}
                    >
                        Table Capacity
                    </button>,
                    "buffet": <button
                        className={`Visualisevenue_menubtn ${customise === 'PAX' ? 'Visualisevenue_menubtnselected' : ''}`}
                        value='PAX'
                        onClick={(e) => setCustomise(customise === 'PAX' ? '' : e.target.value)}
                    >
                        Pax
                    </button>
                }[selectedmealtype]}

            </div>
        </div>

        {{
            'TABLE': <div style={{ textAlign: 'center', padding: '5px 10px' }}>The capacity is based on the no. of tables, with each table seating <b style={{ color: 'var(--mainpink)' }}>10 pax</b>. <b>"up to 5"</b> means <b>"up to 5 tables"</b>.</div>,
            'PAX': <div style={{ textAlign: 'center', padding: '5px 10px' }}>The capacity is based by the no. of guests attending (pax). <b>"up to 100"</b> means <b>"up to 100 pax"</b>.</div>
        }[customise]}

        {{
            'PRICE': <div className='Visualisevenue_menuroot Visualisevenue_menuroot_wt' style={{ width: 260, margin: '10px 0px', padding: '0px 10px' }}>
                {{
                    'buffet': <Range
                        key={'comparevenuerange0'}
                        dots={false}
                        allowCross={false}
                        step={buffetstep}
                        min={minbuffetprice}
                        max={maxbuffetprice}
                        defaultValue={rangebuffetpriceminmax}
                        value={rangebuffetpriceminmax}
                        onChange={(val) => {
                            setRangebuffetpriceminmax(JSON.parse(JSON.stringify(val)))
                        }}
                    />,
                    'banquet': <Range
                        key={'comparevenuerange3'}
                        dots={false}
                        allowCross={false}
                        step={banquetstep}
                        min={minbanquetprice}
                        max={maxbanquetprice}
                        defaultValue={rangebanquetpriceminmax}
                        value={rangebanquetpriceminmax}
                        onChange={(val) => {
                            setRangebanquetpriceminmax(JSON.parse(JSON.stringify(val)))
                        }}
                    />
                }[selectedmealtype]}

                <div className="Pricelist_filtermoneyroot">

                    <div style={{ display: 'flex', flexDirection: 'column', width: 96 }}>
                        <button
                            className="Pricelist_filterdollarbox"
                            style={{ height: 80, pointerEvents: 'auto', width: '100%' }}
                        >
                            <div className="Pricelist_filterpricelabel">min price</div>
                            {selectedmealtype === 'banquet'
                                ? <div key={rangebanquetpriceminmax[0]} className="Pricelist_filterdollar">{'$ ' + Processmoney(rangebanquetpriceminmax[0] ? rangebanquetpriceminmax[0] : 0) + ' SGD'}</div>
                                : <div key={rangebuffetpriceminmax[0]} className="Pricelist_filterdollar">{'$ ' + Processmoney(rangebuffetpriceminmax[0] ? rangebuffetpriceminmax[0] : 0) + ' SGD'}</div>}
                        </button>
                        <div className='Comparevenue_plusminusroot'>
                            <button
                                className='Comparevenue_plusminussign'
                                onClick={() => {
                                    const min = parseInt(rangebanquetpriceminmax[0])
                                    const max = parseInt(rangebanquetpriceminmax[1])
                                    setRangebanquetpriceminmax([min - 100, max])
                                }}
                            >
                                -
                            </button>
                            <button
                                className='Comparevenue_plusminussign'
                                onClick={() => {
                                    const min = parseInt(rangebanquetpriceminmax[0])
                                    const max = parseInt(rangebanquetpriceminmax[1])
                                    setRangebanquetpriceminmax([min + 100, max])
                                }}
                            >
                                +
                            </button>
                        </div>
                    </div>

                    <div className="Pricelist_filterhyphen" style={{ height: 80 }}>-</div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: 96 }}>
                        <button className="Pricelist_filterdollarbox"
                            style={{ height: 80, pointerEvents: 'auto', width: '100%' }}
                            onClick={() => {
                                const min = parseInt(rangebanquetpriceminmax[0])
                                const max = parseInt(rangebanquetpriceminmax[1])
                                setRangebanquetpriceminmax([min, max + 100])
                            }}
                        >
                            <div className="Pricelist_filterpricelabel">max price</div>
                            {selectedmealtype === 'banquet'
                                ? <div key={rangebanquetpriceminmax[1]} className="Pricelist_filterdollar">{'$ ' + Processmoney(rangebanquetpriceminmax[1] ? rangebanquetpriceminmax[1] : 0) + ' SGD'}</div>
                                : <div key={rangebuffetpriceminmax[1]} className="Pricelist_filterdollar">{'$ ' + Processmoney(rangebuffetpriceminmax[1] ? rangebuffetpriceminmax[1] : 0) + ' SGD'}</div>}
                        </button>
                        <div className='Comparevenue_plusminusroot'>
                            <button
                                className='Comparevenue_plusminussign'
                                onClick={() => {
                                    const min = parseInt(rangebanquetpriceminmax[0])
                                    const max = parseInt(rangebanquetpriceminmax[1])
                                    setRangebanquetpriceminmax([min, max - 100])
                                }}
                            >
                                -
                            </button>
                            <button
                                className='Comparevenue_plusminussign'
                                onClick={() => {
                                    const min = parseInt(rangebanquetpriceminmax[0])
                                    const max = parseInt(rangebanquetpriceminmax[1])
                                    setRangebanquetpriceminmax([min, max + 100])
                                }}
                            >
                                +
                            </button>
                        </div>
                    </div>

                </div>

            </div>,
            'TABLE': <div className='Visualisevenue_menuroot Visualisevenue_menuroot_wt' style={{ width: 260, margin: '10px 0px', padding: '0px 10px' }}>
                <div></div>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    {priceviewmode === 'ALL_PRICES'
                        ? TABLE_CAPACITY_ARR.map((item, i) => {
                            return <button
                                key={i + item}
                                className={`Comparevenue_capacitybtn ${tablecapacity.includes(item) ? 'Comparevenue_capacitybtnselected' : ''}`}
                                value={item}
                                onClick={tableitem_click}
                            >
                                {item}
                            </button>
                        })
                        : <div className='Comparevenue_avgpricecaptext'>Capacity filter not available in "Avg Price Mode"</div>}

                </div>
            </div>,
            "PAX": <div className='Visualisevenue_menuroot Visualisevenue_menuroot_wt' style={{ width: 260, margin: '10px 0px', padding: '0px 10px' }}>

                {/*
                 <Range
                    key={'comparevenuerange1'}
                    dots={false}
                    allowCross={false}
                    marks={marks}
                    step={paxminmaxstep} min={minpaxrange} max={maxpaxrange}
                    defaultValue={rangepaxminmax}
                    onChange={(val) => setRangepaxminmax(JSON.parse(JSON.stringify(val)))}
                /> 
                */}
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    {priceviewmode === 'ALL_PRICES'
                        ? PAX_CAPACITY_ARR.map((item, i) => {
                            return <button
                                key={i + item}
                                className={`Comparevenue_capacitybtn  ${paxcapacity.includes(item) ? 'Comparevenue_capacitybtnselected' : ''}`}
                                value={item}
                                onClick={paxitem_click}
                            >
                                {item}
                            </button>
                        })
                        : <div className='Comparevenue_avgpricecaptext'>Capacity filter not available in "Avg Price Mode"</div>}
                </div>

                {/* 
                <div className="Pricelist_filtermoneyroot">
                    <div className="Pricelist_filterdollarbox" style={{ height: 80 }}>
                        <div className="Pricelist_filterpricelabel">min pax</div>
                        <div className="Pricelist_filterdollar">{rangepaxminmax[0]}</div>
                    </div>
                    <div className="Pricelist_filterhyphen">-</div>
                    <div className="Pricelist_filterdollarbox" style={{ height: 80 }}>
                        <div className="Pricelist_filterpricelabel">max pax</div>
                        <div className="Pricelist_filterdollar">{rangepaxminmax[1]}</div>
                    </div>
                </div> */}
            </div>
        }[customise]}

    </>

    const FILTER_DOM = <div style={{ width: panelwt, height: mainht, display: 'flex', flexDirection: 'column', right: 0, position: 'relative', overflow: 'hidden scroll' }}>
        {MENU_DOM}
        <div className='Visualisevenue_title'>
            <div className='Visualisevenue_lefttitle'>
                <div style={{ textAlign: 'start' }}>Wedding Venue</div>
                <div style={{ textAlign: 'start' }}>Price Comparison Chart</div>
            </div>
            <div className='Visualisevenue_righttitle'>🇸🇬</div>
        </div>
        {FILTER_PART}
    </div>

    const SELECTED_DOM = <div style={{ width: panelwt, height: mainht, display: 'flex', flexDirection: 'column', right: 0, position: 'relative', overflow: 'hidden scroll' }}>
        {MENU_DOM}
        <div className='Comparevenue_columnitem'>
            {selecteddata.length > 1
                ? <div style={{ display: 'flex', flexDirection: 'row', width: '100%', placeContent: 'space-between' }}>
                    <button className='Comparevenue_hideexpand' style={{ width: '100%', marginRight: 2.5, fontSize: 12 }} onClick={enquireall_click}>Enquire All Venues</button>
                    <button className='Comparevenue_hideexpand' style={{ width: '100%', marginLeft: 2.5, fontSize: 12 }} onClick={() => setExpandcard(!expandcard)}>{!expandcard ? 'Expand Detail' : 'Hide Detail'}</button>
                </div>
                : selecteddata.length === 1 ?
                    <button className='Comparevenue_hideexpand' style={{ width: '100%' }} onClick={() => setExpandcard(!expandcard)}>{!expandcard ? 'Expand Detail' : 'Hide Detail'}</button>
                    : null
            }

            {selecteddata.map((item, index) => {
                let liked = false
                for (let i = 0; i < favourite.length; i++) {
                    const vendortypeinterface_id = favourite[i][0]
                    if (vendortypeinterface_id === item.vendortypeinterface_id) {
                        liked = true
                    }
                }
                let color_of_heart = liked ? 'rgb(210, 35, 42)' : 'grey'

                const banquet_prices = vendorusername_data_map[item.username]['prices']['banquet']
                const buffet_prices = vendorusername_data_map[item.username]['prices']['buffet']

                const banquetprices_dom = banquet_prices && banquet_prices.length
                    ? <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className='Comparevenue_selecteddatacardtitle'>{'Banquet ( per table )'}</div>
                        <div className='Comparevenue_selecteddatacardrow'>
                            {banquet_prices.map((p, i) => {
                                return <div className='Comparevenue_selecteddatacardrowitem' key={'banquet_price_' + i}>{'$ ' + p}</div>
                            })}
                        </div>
                    </div>
                    : null

                const buffetprices_dom = buffet_prices && buffet_prices.length > 0
                    ? <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className='Comparevenue_selecteddatacardtitle'>{'Buffet ( per pax )'}</div>
                        <div className='Comparevenue_selecteddatacardrow'>
                            {buffet_prices.map((p, i) => {
                                return <div className='Comparevenue_selecteddatacardrowitem' key={'buffet_price_' + i}>{'$ ' + p}</div>
                            })}
                        </div>
                    </div>
                    : null

                const tags_dom = <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className='Comparevenue_selecteddatacardtitle'>Tags</div>
                    <div className='Comparevenue_tagrow'>
                        {item.tags.map((tag, index) => {
                            return <div className='Comparevenue_tagitem' key={tag + '_' + index}>{tag}</div>
                        })}
                    </div>
                </div>

                return <div className={'Comparevenue_selecteddatacard'} key={index + 'selecteddata'}>
                    <button
                        className='Comparevenue_crossbtn'
                        onClick={() => deleteselecteditem(index)}
                    >
                        {iconwrapper(CROSS_ICON, { ...ICON_CSS, width: 15, height: 15 })}
                    </button>
                    <div className='Comparevenue_selectedcarditemcontent' style={{ display: 'flex', flexDirection: 'column', placeContent: 'space-between' }}>
                        {/* <img src={getS3fullpath_withbucketname('VENDOR_PROFILE_IMG', item.profileimg_url)} style={{ width: 30, height: 30, border: '1px solid var(--subgrey)', borderRadius: 50 }} /> */}
                        <div style={{ width: 'max-content', height: '100%', border: '1px solid var(--subgrey)', borderRadius: 60 }}>
                            <div
                                style={{
                                    width: 40, height: 40, borderRadius: 40,
                                    backgroundImage: `url('https://asset.weavebliss.com/assets/sprites/${production ? 'p' : 'up'}/venue.png')`,
                                    backgroundPositionY:
                                        ((parseInt(profileimgpositionarr.indexOf(item.username)) * -60)
                                            + (parseInt(profileimgpositionarr.indexOf(item.username)) * -10)) * (4 / 6)
                                        + 'px',
                                    backgroundPositionX: '0.39px',
                                    backgroundSize: '40px',
                                    backgroundRepeat: 'no-repeat'
                                }}
                            />
                        </div>
                        <div className='Comparevenue_dndiamondrow'>
                            <div>{item.displayname}</div>
                            {item.tier === 0
                                ? null
                                : <div
                                    className='Comparevenue_diamondroot'
                                    style={{
                                        backgroundImage: `url('https://asset.weavebliss.com/assets/sprites/diamonds.png')`,
                                        backgroundPositionY: TIER_DIAMOND_POSITION[item.tier].y + 'px'
                                    }}
                                />}
                        </div>
                        {expandcard ? banquetprices_dom : null}
                        {expandcard ? buffetprices_dom : null}
                        {expandcard ? tags_dom : null}

                        <div className='Comparevenue_colcardrow'>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                <Link id={item.username} style={{ width: 0, height: 0, visibility: 'hidden' }} to={'/vendorlookup/' + item.username} target="_blank" />
                                <Link id={item.username + '_review'} style={{ width: 0, height: 0, visibility: 'hidden' }} to={'/vendorlookup/' + item.username + '/review'} target="_blank" />
                                <Link id={item.username + '_pricelist'} style={{ width: 0, height: 0, visibility: 'hidden' }} to={'/vendorlookup/' + item.username + '/pricelist'} target="_blank" />
                                {(vendorusername_data_map && vendorusername_data_map[item.username] && vendorusername_data_map[item.username]['link'])
                                    ? <a id={item.username + '_a'} href={vendorusername_data_map[item.username]['link']} style={{ width: 0, height: 0, visibility: 'hidden' }} target="_blank" ></a>
                                    : null}
                                <button className='Comparevenue_viewvendor' style={{ fontSize: 12, height: 29 }} onClick={() => { document.getElementById(item.username + '_a').click() }} >Website</button>
                                <div className='Comparevenue_border' />
                                <button className='Comparevenue_viewvendor' style={{ fontSize: 12, height: 29 }} onClick={() => { document.getElementById(item.username + '_review').click() }} >Review</button>
                                <div className='Comparevenue_border' />
                                <button className='Comparevenue_viewvendor' style={{ fontSize: 12, height: 29 }} onClick={() => { document.getElementById(item.username + '_pricelist').click() }}>Pricelist</button>
                                <div className='Comparevenue_border' />
                                <button className='Comparevenue_viewvendor' style={{ fontSize: 12, height: 29 }} onClick={() => { document.getElementById(item.username).click() }}>View</button>
                            </div>
                        </div>
                        <div style={{ display: 'flex', placeContent: 'flex-end' }}>
                            <div className='Comparevenue_viewvendorenquire' style={{ fontSize: 12 }} onClick={() => enquire_click(item.username)} >Enquire</div>
                        </div>

                    </div>
                    {acct_type === 'USER'
                        ? <div className='Comparevenue_iconcol'>
                            <button
                                className='Comparevenue_heartbtn'
                                onClick={() => {

                                    // check if this vendor is selected
                                    // if yes : unlike
                                    // if no  : like

                                    const { vendortypeinterface_id, vendortype, secondaryvendortype } = item

                                    if (liked) {
                                        let options = {
                                            method: 'POST',
                                            url: USER_UNFAV_A_VENDOR,
                                            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
                                            data: { vendortypeinterface_id, vendortype, secondaryvendortype }
                                        }
                                        axios(options).then((result) => {
                                            const { status } = result
                                            if (status === 200) {
                                                setFavourite((old_favourite) => {
                                                    let new_favourite = JSON.parse(JSON.stringify(old_favourite))
                                                    for (let i = 0; i < new_favourite.length; i++) {
                                                        if (new_favourite[i][0] === vendortypeinterface_id) {
                                                            new_favourite.splice(i, 1)
                                                        }
                                                    }
                                                    new_favourite = extractVenuefavourite(new_favourite, rawdata)
                                                    return new_favourite
                                                })
                                            }
                                        })
                                    }
                                    else {
                                        let options = {
                                            method: 'POST',
                                            url: USER_FAV_A_VENDOR,
                                            headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
                                            data: { vendortypeinterface_id, vendortype, secondaryvendortype }
                                        }
                                        axios(options).then((result) => {
                                            const { status } = result
                                            if (status === 200) {
                                                setFavourite((old_favourite) => {
                                                    let new_favourite = JSON.parse(JSON.stringify(old_favourite))
                                                    new_favourite.push([vendortypeinterface_id, vendortype, secondaryvendortype])
                                                    new_favourite = extractVenuefavourite(new_favourite, rawdata)
                                                    return new_favourite
                                                })
                                            }
                                        })
                                    }

                                }}
                            >
                                {iconwrapper(HEARTFILL_ICON, { ...ICON_CSS, color: color_of_heart })}
                            </button>

                        </div>
                        : null}
                </div>

            })}
        </div>

    </div>

    const DISPLAYSTATE_DOM = <div className='Comparevenue_nodatafound' style={{ width: (device === 'TABLET') ? '100%' : mainwt, height: mainht, display: 'flex', placeContent: 'center' }} >
        {displaystate === 'NODATA'
            ? <div style={{ fontSize: 22 }}>No Data</div>
            : displaystate === 'LOADING'
                ? <div style={{ fontSize: 22, width: '100%', display: 'flex', placeContent: 'center', placeSelf: 'center', textAlign: 'center' }}>Loading...</div>
                : null}
    </div>

    const CHART_LIST_DOM = <div className='Comparevenue_iconrow' style={{ position: device === 'MOBILE' ? 'fixed' : '', top: device === 'MOBILE' ? 35 : '', left: device === 'MOBILE' ? 0 : '' }}>
        {device === 'MOBILE' ? <div style={{ height: 20, width: 20, margin: 5 }} /> : <div style={{ height: 32, width: 96, margin: 5 }} />}
        <div className='Comparevenue_btnsrow'>
            <button
                className='Comparevenue_allorselectedroot'
                onClick={() => setAllorselected(allorselected === 'All Venues' && acct_type === 'USER' ? 'Selected' : 'All Venues')}
            >
                {allorselected}
            </button>
            <div className='Comparevenue_lineroot'><div className='Comparevenue_linebridge' /></div>
            <button className={`Comparevenue_topicon ${leftmain === 'Chart' ? 'Comparevenue_topiconselected' : ''}`} style={{ borderRadius: '10px 0px 0px 10px', borderRight: 'none' }} onClick={(e) => {
                if (device === 'MOBILE') {
                    setShowrecommendotherdevice(true)
                }
                else {
                    setLeftmain(e.target.value)
                }
            }}
                value='Chart'
            >
                Chart
            </button>
            <button
                className={`Comparevenue_topicon ${leftmain === 'List' ? 'Comparevenue_topiconselected' : ''}`}
                style={{ borderRadius: '0px 10px 10px 0px' }}
                onClick={(e) => setLeftmain(e.target.value)}
                value='List'
            >
                List
            </button>
        </div>

        {/* or sort */}
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <button
                className={`Comparevenue_sort ${sort === 3 ? 'Comparevenue_sorted' : ''}`}
                onClick={() => setSort(sort === REVIEWNUMSORT ? NOSORT : REVIEWNUMSORT)}>
                {iconwrapper(TICKSORTREVIEWNUM_ICON, { width: 24, height: 24, color: 'currentColor', borderRadius: 10 })}
            </button>
            {EMPTYSPACE}
            <button
                className={`Comparevenue_sort ${sort === 1 ? 'Comparevenue_sorted' : ''}`}
                onClick={() => setSort(sort === PRICESORT ? NOSORT : PRICESORT)}
            >
                {iconwrapper(THICKSORTPRICE_ICON, { width: 24, height: 24, color: 'currentColor', borderRadius: 10 })}
            </button>
            {EMPTYSPACE}
            <button
                className={`Comparevenue_sort ${sort === 2 ? 'Comparevenue_sorted' : ''}`}
                onClick={() => setSort(sort === ALPHABETSORT ? NOSORT : ALPHABETSORT)}
            >
                {iconwrapper(THICKSORT_ICON, { width: 24, height: 24, color: 'currentColor', borderRadius: 10 })}
            </button>
            {device === 'TABLET'
                ? <button className='Comparevenue_tabletsidebtn' onClick={() => { setShowsidebar(true) }}>
                    {iconwrapper(ARROWDOWNWITHOUTCIRCLE_ICON, { width: 28, height: 28, display: 'flex', placeSelf: 'center' })}
                </button>
                : null}
            <div style={{ width: 5, height: '100%' }} />
        </div>


        {/* and sort */}
        {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
            <button
                className={`Comparevenue_sort ${reviewnumsort === 1 ? 'Comparevenue_sorted' : ''}`}
                onClick={() => {
                    if (reviewnumsort === 0) setReviewnumsort(1)
                    else if (reviewnumsort === 1) setReviewnumsort(2)
                    else setReviewnumsort(0)
                }}
            >
                {iconwrapper(STAR_ICON, { width: 24, height: 24, color: 'currentColor', borderRadius: 10 })}
            </button>
            {EMPTYSPACE}
            <button
                className={`Comparevenue_sort ${pricesort === 1 ? 'Comparevenue_sorted' : ''}`}
                onClick={() => {
                    if (pricesort === 0) setPricesort(1)
                    else if (pricesort === 1) setPricesort(2)
                    else setPricesort(0)
                }}
            >
                {iconwrapper(THICKSORTPRICE_ICON, { width: 24, height: 24, color: 'currentColor', borderRadius: 10 })}
            </button>
            {EMPTYSPACE}
            <button
                className={`Comparevenue_sort ${alphasort === 1 ? 'Comparevenue_sorted' : ''}`}
                onClick={() => {
                    if (alphasort === 0) setAlphasort(1)
                    else if (alphasort === 1) setAlphasort(2)
                    else setAlphasort(0)
                }}
            >
                {iconwrapper(THICKSORT_ICON, { width: 24, height: 24, color: 'currentColor', borderRadius: 10 })}
            </button>
            {device === 'TABLET'
                ? <button className='Comparevenue_tabletsidebtn' onClick={() => { setShowsidebar(true) }}>
                    {iconwrapper(ARROWDOWNWITHOUTCIRCLE_ICON, { width: 28, height: 28, display: 'flex', placeSelf: 'center' })}
                </button>
                : null}
            <div style={{ width: 5, height: '100%' }} />
        </div> */}

        {/* <button className='Comparevenue_setting'>{iconwrapper(SETTING10_ICON, { width: 24, height: 24, color: 'var(--maindark)', borderRadius: 50, visibility: 'hidden' })}</button> */}
    </div>

    const LIST_DOM = <div className={`Comparevenue_coldata${device}`}>
        {Array.from(uniquedetailmap).reverse().map((item, index) => {
            const username = item[0]
            const {
                displayname, tier, link, profileimg_url, secondaryvendortype, tags,
                vendortype, vendortype_id, vendortypeinterface_id, venue
                // color, culture, datalevel, id, mealofday, profileimg_url, secondaryvendortype, tags, username, vendortype, vendortype_id, vendortypeinterface_id 
            } = uniquedetaildata[username]
            let dinnerprice_arr = []
            let lunchprice_arr = []

            for (let i = 0; i < item[1].length; i++) {
                const { x, mealofday } = item[1][i]
                if (mealofday === 'Dinner') dinnerprice_arr.push(x)
                if (mealofday === 'Lunch') lunchprice_arr.push(x)
            }
            // dinnerprice_arr = dinnerprice_arr.sort(function (a, b) { return parseInt(a) - parseInt(b) }).map(item => '$ ' + item)
            // lunchprice_arr = lunchprice_arr.sort(function (a, b) { return parseInt(a) - parseInt(b) }).map(item => '$ ' + item)

            dinnerprice_arr = dinnerprice_arr.sort(function (a, b) { return parseInt(a) - parseInt(b) }).map(item => item)
            lunchprice_arr = lunchprice_arr.sort(function (a, b) { return parseInt(a) - parseInt(b) }).map(item => item)

            let isselected = false
            for (let x = 0; x < selecteddata.length; x++) {
                if (selecteddata[x].username === username) {
                    isselected = true
                    break
                }
            }

            const mealcard_dom = (lunchordinner, mealarr) => {
                let capacitymap = getCapacity(
                    username,
                    lunchordinner,
                    selectedmealtype, selectedculture,
                    strday, strmth, stryear
                )
                return mealarr.length > 0
                    ? <>
                        <div className='Comparevenue_selecteddatacardtitle' style={{ margin: '10px 0px 0px' }}>{`${lunchordinner} ( ${daymap[strday] + ' / ' + mtharr[strmth] + ' / ' + stryear} )`}</div>
                        <div className='Comparevenue_btmcardrow' style={{ display: 'flex', flexDirection: 'row', overflowX: mealarr.length > 3 ? 'scroll' : 'auto' }}>
                            {mealarr.map((price, index) => {
                                return <div key={index + 'pricepoint'} style={{ minWidth: 100, width: 100, display: 'flex', flexDirection: 'column', border: '1px solid var(--maindark)', marginRight: 5 }}>
                                    <div style={{ width: '100%', borderBottom: '1px solid var(--maindark)', padding: 5, display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ fontSize: 10 }}>Price</div>
                                        <div>{'$ ' + price}</div>
                                    </div>
                                    <div style={{ width: '100%', padding: 5, display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ fontSize: 10 }}>Capacity</div>
                                        <div>
                                            {(capacitymap && capacitymap[price])
                                                ? capacitymap[price].map((item, y) => {
                                                    return Array.from(new Set(item.split(','))).map((eachcap, z) => {
                                                        try {
                                                            return <div key={index + '_' + item + '_' + y + '_' + z}>{eachcap}</div>
                                                        }
                                                        catch (e) { return null }
                                                    })


                                                })
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </>
                    : null
            }

            const mealoption_dom = () => {
                return <>
                    <div className='Comparevenue_selecteddatacardtitle' style={{ margin: '10px 0px 0px' }}>Menu</div>
                    <div className='Comparevenue_btmcardrow' style={{ textTransform: 'capitalize' }}>{selectedculture + ' ' + selectedmealtype}</div>
                </>
            }


            const avgprice_dom = () => {
                const { displayname } = uniquedetaildata[username]
                let tempdata = {}
                for (let i = 0; i < data.length; i++) {
                    if (data[i].y === displayname) {
                        tempdata = data[i]
                        break
                    }
                }
                const { x, mealofday } = tempdata
                return <div className='Comparevenue_avgpriceportalenquire'>
                    <div className='Scatterplot_tooltiptop' style={{ marginTop: 5, display: 'flex', flexDirection: 'column' }}>
                        <div>{mealofday}</div>
                    </div>
                    <div>{'$ ' + Math.round(x) + '++ ( Avg Price per table )'}</div>
                </div>
            }


            return <div className={`Comparevenue_colcard Comparevenue_colcard${device}`} key={displayname} style={isselected ? { border: '2px solid var(--mainpink)', backgroundColor: 'var(--subpink)' } : { border: '1px solid var(--maindark)' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {/* <img src={getS3fullpath_withbucketname('VENDOR_PROFILE_IMG', profileimg_url)} style={{ width: 50, height: 50, border: '1px solid var(--subgrey)', borderRadius: 50 }} /> */}
                    <div style={{ width: 'max-content', height: '100%', border: '1px solid var(--subgrey)', borderRadius: 60 }}>
                        <div
                            style={{
                                width: 40, height: 40, borderRadius: 40,
                                backgroundImage: `url('https://asset.weavebliss.com/assets/sprites/${production ? 'p' : 'up'}/venue.png')`,
                                backgroundPositionY:
                                    ((parseInt(profileimgpositionarr.indexOf(username)) * -60)
                                        + (parseInt(profileimgpositionarr.indexOf(username)) * -10)) * (4 / 6)
                                    + 'px',
                                backgroundPositionX: 0,
                                backgroundSize: '40px',
                                backgroundRepeat: 'no-repeat',
                                backgroundClip: 'padding-box'
                            }}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5 }}>
                        <div className='Comparevenue_selecteddatacardtitle' style={{ marginTop: 0 }}>Venue</div>
                        <div style={{ whiteSpace: 'break-spaces', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <div>{displayname}</div>
                            {tier === 0
                                ? null
                                : <div
                                    className='Comparevenue_diamondroot'
                                    style={{
                                        backgroundImage: `url('https://asset.weavebliss.com/assets/sprites/diamonds.png')`,
                                        backgroundPositionY: TIER_DIAMOND_POSITION[tier].y + 'px'
                                    }}
                                />}
                        </div>
                    </div>
                </div>

                {priceviewmode === 'ALL_PRICES'
                    ? <div className='Comparevenue_colcardbtm'>
                        {mealoption_dom()}
                        {mealcard_dom('Lunch', lunchprice_arr)}
                        {mealcard_dom('Dinner', dinnerprice_arr)}
                    </div>
                    : <div className='Comparevenue_colcardbtm'>
                        {mealoption_dom()}
                        {avgprice_dom()}
                    </div>}

                <div className='Comparevenue_colcardrow'>
                    <div style={{ display: 'flex', flexDirection: 'row', flexwWrap: 'wrap' }}>
                        <Link id={item[0]} style={{ width: 0, height: 0, visibility: 'hidden' }} to={'/vendorlookup/' + item[0]} target="_blank" />
                        <Link id={item[0] + '_review'} style={{ width: 0, height: 0, visibility: 'hidden' }} to={'/vendorlookup/' + item[0] + '/review'} target="_blank" />
                        <Link id={item[0] + '_pricelist'} style={{ width: 0, height: 0, visibility: 'hidden' }} to={'/vendorlookup/' + item[0] + '/pricelist'} target="_blank" />
                        {(vendorusername_data_map && vendorusername_data_map[username] && vendorusername_data_map[username]['link'])
                            ? <a id={item[0] + '_a'} href={vendorusername_data_map[username]['link']} style={{ width: 0, height: 0, visibility: 'hidden' }} target="_blank" ></a>
                            : null}
                        <button className='Comparevenue_viewvendor' onClick={() => { document.getElementById(item[0] + '_a').click() }} >Website</button>
                        <div className='Comparevenue_border' />
                        <button className='Comparevenue_viewvendor' onClick={() => { document.getElementById(item[0] + '_review').click() }} >Review</button>
                        <div className='Comparevenue_border' />
                        <button className='Comparevenue_viewvendor' onClick={() => { document.getElementById(item[0] + '_pricelist').click() }} >Pricelist</button>
                        <div className='Comparevenue_border' />
                        <button className='Comparevenue_viewvendor' onClick={() => { document.getElementById(item[0]).click() }}  >View</button>

                    </div>
                    <div style={{ display: 'flex', placeContent: 'flex-end' }}>
                        <div className='Comparevenue_viewvendorenquire' onClick={() => enquire_click(item[0])}>Enquire</div>
                    </div>
                </div>

                {!isselected && acct_type === 'USER'
                    ? <button
                        className={isselected ? 'Comparevenue_selecteditem' : 'Comparevenue_selectednon'}
                        onClick={() => circleclicked(uniquedetaildata[username])}
                    >
                        Select
                    </button>
                    : null}

            </div>

        })}
        <div style={{ height: 120 }} />
    </div>

    const updateScatterportaldata = (dn, selecteddata) => {

        let scatterportalvendorobj = {}

        {
            Array.from(uniquedetailmap).reverse().map((item, index) => {
                const username = item[0]
                if (username === displayname_username_map[dn]) {
                    scatterportalvendorobj = JSON.parse(JSON.stringify(item))
                }
            })
        }

        setShowscatterportal(true)
        setScatterportalvendorobj(() => {
            return scatterportalvendorobj
        })

    }



    const WEB_DOM = <div className={`Visualisevenue_root Visualisevenue_root${device === 'TABLET' ? 'WEB' : device}`}>
        <div style={{ width: mainwt, height: mainht, zIndex: 1, overflow: 'hidden', overflowY: data.length > 3 ? 'scroll' : 'hidden' }}>
            {touchscreen}
            {CHART_LIST_DOM}
            {(leftmain === 'Chart' && data.length > 0) || (leftmain === 'Chart' && avgdata.length > 0)
                ? <Scatterplot
                    priceviewmode={priceviewmode}
                    data={data}
                    scatterkey={scatterkey}
                    selecteddata={selecteddata}
                    scatterwt={wt - panelwt - 10 - emptyspace}
                    scatterht={scatterplot_css_map['WEB']['height'](data, scatterht, priceviewmode)}
                    radius={scatterplot_css_map['WEB']['radius']}
                    circleclick={circleclicked}
                    touchscreen={touchscreen}
                    device={device}
                    leftmain={leftmain}

                    min={0}
                    max={selectedmealtype === 'banquet' ? maxbanquetprice : maxbuffetprice}
                    updatemin={(v) => { }}// brush, dont delete
                    updatemax={(v) => { }}// brush, dont delete

                    windowmin={selectedmealtype === 'banquet' ? minbanquetwindowprice : minbuffetwindowprice}// brush, dont delete
                    windowmax={selectedmealtype === 'banquet' ? maxbanquetwindowprice : maxbuffetwindowprice}// brush, dont delete

                    favourite={favourite}
                    allorselected={allorselected}
                    updateScatterportaldata={updateScatterportaldata}
                />
                : null}

            {leftmain === 'List' && data.length > 0
                ? LIST_DOM
                : null}

            {data.length === 0
                ? DISPLAYSTATE_DOM
                : null}


        </div>
        <div style={{ width: emptyspace }} />

        {{
            'FILTER': FILTER_DOM,
            'SELECTED': SELECTED_DOM
        }[rightpanel]}

    </div>

    const TABLET_DOM = <div className={`Visualisevenue_root Visualisevenue_root${device}`}>
        <div style={{ width: wt, height: mainht, zIndex: 1, overflow: 'hidden', overflowY: data.length > 3 ? 'scroll' : 'hidden' }}>
            {CHART_LIST_DOM}
            {leftmain === 'Chart' && data.length > 0
                ? <Scatterplot
                    priceviewmode={priceviewmode}
                    data={data}
                    scatterkey={scatterkey}
                    selecteddata={selecteddata}
                    scatterwt={wt - 50}
                    scatterht={scatterplot_css_map['WEB']['height'](data, scatterht, priceviewmode)}
                    radius={scatterplot_css_map['WEB']['radius']}
                    circleclick={circleclicked}
                    touchscreen={true}
                    device={device}
                    favourite={favourite}
                    leftmain={leftmain}
                    allorselected={allorselected}
                    updateScatterportaldata={updateScatterportaldata}

                    min={0}
                    max={selectedmealtype === 'banquet' ? maxbanquetprice : maxbuffetprice}

                />
                : null}

            {leftmain === 'List' && data.length > 0
                ? LIST_DOM
                : null}

            {data.length === 0
                ? DISPLAYSTATE_DOM
                : null}

        </div>

        {showsidebar
            ? <div style={{ position: 'absolute', right: 0, top: 0, zIndex: 9999, backgroundColor: 'white', paddingLeft: 5, borderLeft: '1px solid var(--lightdark)' }}>
                {{
                    'FILTER': FILTER_DOM,
                    'SELECTED': SELECTED_DOM
                }[rightpanel]}
            </div>
            : null}

    </div>

    const MOBILE_DOM = <div className={`Visualisevenue_root Visualisevenue_root${device}`}  >
        <div style={{ width: mainwt, height: '100%', zIndex: 1, minWidth: '100%' }}>
            {CHART_LIST_DOM}
            {data.length > 0
                ? {
                    'Chart': <Scatterplot
                        priceviewmode={priceviewmode}
                        data={data}
                        scatterkey={scatterkey}
                        selecteddata={selecteddata}
                        scatterwt={wt * 2} // scatterwt={1000}
                        scatterht={scatterplot_css_map['MOBILE']['height'](data, scatterht, priceviewmode)}
                        radius={scatterplot_css_map['MOBILE']['radius'](data.length)}
                        circleclick={circleclicked}
                        touchscreen={touchscreen}
                        device={device}
                        favourite={favourite}
                        leftmain={leftmain}
                        allorselected={allorselected}
                        updateScatterportaldata={updateScatterportaldata}

                        min={0}
                        max={selectedmealtype === 'banquet' ? maxbanquetprice : maxbuffetprice}
                    />,
                    'List': <div>
                        <div style={{ height: 70 }} />
                        {LIST_DOM}
                    </div>
                }[leftmain]
                : DISPLAYSTATE_DOM}
        </div>

        {acct_type === 'USER'
            ? <div className='Comparevenue_mobilebtm' style={{ position: 'fixed', height: 60, backgroundColor: '#ffffff', zIndex: 1 }}>
                <button className='Comparevenue_mobilebtmbtnleft' onClick={() => setShowmobilefilter(true)}>Filter</button>
                <button className='Comparevenue_mobilebtmbtnright' style={{ display: 'flex', flexDirection: 'row', placeContent: 'center', alignItems: 'center' }} onClick={() => setShowmobileselection(true)}>
                    <div style={{ pointerEvents: 'none', marginRight: 5 }}>{`Selection`}</div>
                    <div style={{ fontSize: 12, pointerEvents: 'none' }}>{selecteddata.length === 0 ? '' : '( ' + selecteddata.length + ' )'}</div>
                </button>
                <button className={`Comparevenue_questionmarknonmobile`} onClick={() => { setShowhelp(true) }}>
                    <div style={{ alignSelf: 'center', pointerEvents: 'none', color: 'var(--subdark)' }} >?</div>
                </button>
            </div>
            : <div className='Comparevenue_mobilebtm' style={{ position: 'fixed', height: 60, backgroundColor: '#ffffff', zIndex: 1 }}>
                <button className='Comparevenue_mobilebtmbtnleft' style={{ width: '100%', borderRadius: 10, border: '1px solid var(--maindark)', backgroundColor: '#ffffff' }} onClick={() => setShowmobilefilter(true)}>Filter</button>
                <button className={`Comparevenue_questionmarknonmobile`} onClick={() => { setShowhelp(true) }}>
                    <div style={{ alignSelf: 'center', pointerEvents: 'none' }}>?</div>
                </button>
            </div>}

        {MOBILEFILTER_PORTAL}
        {MOBILESELECTION_PORTAL}

    </div>

    return <>
        {{ 'WEB': WEB_DOM, 'TABLET': TABLET_DOM, 'MOBILE': MOBILE_DOM }[device]}
        {HELP_PORTAL}
        {RECOMMENDOTHERDEVICE_PORTAL}
        {ENQUIRY_PORTAL}
        {ENQUIRYALL_PORTAL}
        {SCATTERPLOT_PORTAL}
    </>

}

export default Comparevenue

function getTablecheck(tablecapacity, table_arr) {

    let indextablecapacity = tablecapacity.map((stritem) => TABLE_CAPACITY_ARR.indexOf(stritem)).sort(function (a, b) { return parseInt(a) - parseInt(b) })

    let tablecapacitygrpsaddress = []
    let grpindex = 0
    let grpname_vals_map = {}

    // group them
    for (let i = 0; i < indextablecapacity.length; i++) {
        // look forward, check if next elem is 1 value away?

        if (i === 0) {
            tablecapacitygrpsaddress.push(grpindex)
            grpname_vals_map[grpindex] = []
        }
        else {
            let currvalue = parseInt(indextablecapacity[i])
            let prevvalue = parseInt(indextablecapacity[i - 1])
            if ((prevvalue + 1) === currvalue) {
                tablecapacitygrpsaddress.push(grpindex)
            }
            else {
                grpindex = grpindex + 1
                tablecapacitygrpsaddress.push(grpindex)
                grpname_vals_map[grpindex] = []
            }
        }
        grpname_vals_map[grpindex].push(TABLE_CAPACITY_VALUE_ARR[TABLE_CAPACITY_ARR[indextablecapacity[i]]])
    }

    for (let i = 0; i < Object.keys(grpname_vals_map).length; i++) {
        let key = Object.keys(grpname_vals_map)[i]
        grpname_vals_map[key] = grpname_vals_map[key].flat()// reduce dimension of grpname_vals_map's arrays to single dimension
        grpname_vals_map[key] = [grpname_vals_map[key][0], grpname_vals_map[key][grpname_vals_map[key].length - 1]] // obtain the first and end value for comparison
    }


    // check from groups
    for (let x = 0; x < table_arr.length; x++) {

        const itemmin = parseInt(table_arr[x][0]) // venue vendor available min table 
        const itemmax = parseInt(table_arr[x][1]) // above, but max table

        for (let y = 0; y < Object.keys(grpname_vals_map).length; y++) {
            let key = Object.keys(grpname_vals_map)[y]
            let pairarr = grpname_vals_map[key]
            const filtermin = pairarr[0]
            const filtermax = pairarr[1]

            if (filtermin < itemmin && itemmax <= filtermax) {
                return { capacitycheck: true, capacity: table_arr[x] }
            }

        }
    }

    // check from individual tablecapacity
    for (let i = 0; i < table_arr.length; i++) {
        const itemmin = parseInt(table_arr[i][0]) // venue vendor available min table 
        const itemmax = parseInt(table_arr[i][1]) // above, but max table

        let min_is_check = 0
        let max_is_check = 0

        for (let j = 0; j < tablecapacity.length; j++) {
            const userselected_min_max_pair = TABLE_CAPACITY_VALUE_ARR[tablecapacity[j]]
            let filtermin = userselected_min_max_pair[0] // user selected min filter
            let filtermax = userselected_min_max_pair[1]// user selected max filter

            if (tablecapacity[j] === 'up to 5') {
                if (itemmin <= 5) {
                    return { capacitycheck: true, capacity: table_arr[i] }
                }
            }
            else if (tablecapacity[j] === '100 and above') {
                if (itemmax >= 100) {
                    return { capacitycheck: true, capacity: table_arr[i] }
                }
            }
            else if (itemmax === filtermax || itemmin === filtermin || filtermin === itemmax || filtermax === itemmin) {
                return { capacitycheck: true, capacity: table_arr[i] }
            }
            else if (filtermin > itemmin && itemmax > filtermax) {
                return { capacitycheck: true, capacity: table_arr[i] }
            }

            if (itemmin > filtermin && itemmin < filtermax) {
                min_is_check = 1
            }

            if (itemmax < filtermax) {
                max_is_check = 1
            }

        }

        if (min_is_check + max_is_check === 2) {
            return { capacitycheck: true, capacity: table_arr[i] }
        }
    }

    return { capacitycheck: false, capacity: null }

}

function getPaxcheck(paxcapacity, pax_arr) {

    for (let i = 0; i < pax_arr.length; i++) {
        const itemmin = parseInt(pax_arr[i][0]) // venue vendor available min table 
        const itemmax = parseInt(pax_arr[i][1]) // above, but max table
        for (let j = 0; j < paxcapacity.length; j++) {

            const userselected_min_max_pair = PAX_CAPACITY_VALUE_ARR[paxcapacity[j]]
            let filtermin = userselected_min_max_pair[0] // user selected min filter
            let filtermax = userselected_min_max_pair[1]// user selected max filter
            if (paxcapacity[j] === 'up to 100') {
                if (itemmin <= 100) {
                    return { capacitycheck: true, capacity: pax_arr[i] }
                }
            }
            else if (paxcapacity[j] === '1000 and above') {
                if (itemmax >= 100) {
                    return { capacitycheck: true, capacity: pax_arr[i] }
                }
            }
            else if (itemmax === filtermax || itemmin === filtermin || filtermin === itemmax || filtermax === itemmin) {
                return { capacitycheck: true, capacity: pax_arr[i] }
            }
            else if (filtermin > itemmin && itemmax > filtermax) {
                return { capacitycheck: true, capacity: pax_arr[i] }
            }

        }

    }

    return { capacitycheck: false, capacity: null }




    // for (let i = 0; i < pax_arr.length; i++) {

    //     if (!(pax_arr[i].toString() in allpossiblepax)) {
    //         allpossiblepax[pax_arr[i].toString()] = 1
    //     }
    //     else {
    //         allpossiblepax[pax_arr[i].toString()] = allpossiblepax[pax_arr[i].toString()] + 1
    //     }

    //     const itemmin = parseInt(pax_arr[i][0])
    //     const itemmax = parseInt(pax_arr[i][1])
    //     if (itemmin < usermin && usermax < itemmax) {
    //         checkresult = true
    //         break
    //     }
    // }
}

