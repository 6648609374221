import React, { useEffect, useState } from 'react'
import { iconwrapper, Retrieve_personal_info } from '../../../../config/commonfunctions'
import {
    INVITATION_READEDITINIT, INVITATION_SAVE, INVITATION_SAVENAV, INVITATION_SCHEDULERMV,
    INVITATION_SCHEDULESAVE, INVITATION_SCHEDULESWAP, INVITATION_SCHEDULEEDIT, INVITATION_SAVEIMGSWAP,
    INVITATION_RMVIMG, INVITATION_SAVEMAINIMGPROP, INVITATION_SAVETEMPLATE, INVITATION_SAVETEMPLATEFIRSTTIME, INVITATION_SAVEBACKGROUND,
    INVITATION_SAVEBORDER, INVITATION_SAVEBANNER, INVITATION_SAVEBGCOLOR,

    // Details
    INVITATION_DNAME, INVITATION_DFONTS, INVITATION_DJOURNEY, INVITATION_DLOCATION, INVITATION_DDATETIME,
    INVITATION_DQNA, INVITATION_DCONTACTS, INVITATION_DPROMOTE, INVITATION_DNOTIFICATION, INVITATION_DSHARE,
    INVITATION_DSCHEDULES, INVITATION_DOPENINGS, INVITATION_DREGISTRY, INVITATION_DSEQUENCE, INVITATION_RETRIEVEITINERARIES

} from '../../../../config/api'
import { EMPTY, ARROWLEFT_ICON, EMPTYSPACE, FS_LH_MAP, WEBSITE_URL, QR_NORM, LOOKUPUNSTYLE_ICON, d_fs, e_fs, MAINLOADING_TIME, PLOBJ, STARTUP_NAME } from '../../../../config/constants'
import { ScreenHeight, Checkdevice, Loading, ScreenWidth, Noti, Modcfm, Portalmodal } from '../../../../components'
import { Templates, Images, Details, Background, QRcodediv, Simulator, Navigation, } from './components' // Structures, Guestqna Alticlinks,Schedules,Border, Openings,
import { Link } from "react-router-dom";
import { Togglebutton } from '../../../../basic_components'
import { banners } from '../../../../assets/invitationcard/index1'
import Invitationguestlist from './Invitationguestlist'
import axios from 'axios'
import { PLANNING_QR_ICON } from '../../../../config/svgicons'
import './Invitationedit.css'

const templateedit = false // tool to help create template
const partialtitles = ['QR Code', 'Details', 'Background', 'Templates', 'Gallery', 'Navigation']//'Alt Links', 'Border', 'Openings'
const secretfeature = ['Details', 'Templates', 'Schedules', 'Details', 'Templates', 'Schedules', 'Details'].toString()
const secretfeature1 = ['Details', 'Templates', 'Details', 'Templates', 'Details', 'Details'].toString()

const DEVICE_MODALWT_MAP = {
    'WEB': 500,
    'TABLET': 500,
    'MOBILE': 'auto',
}

// WEB AND TABLET
const rootStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    fontFamily: 'Lato',
    // position: 'fixed',
    minHeight: '100%',
    maxHeight: '100%',
    top: 0,
    left: 0
}

const leftStyle = {
    width: '45%',
    display: 'flex',
    flexDirection: 'column',
    borderRight: '1px solid rgb(221, 221, 221)',
    overflowY: 'auto',
    position: 'relative'
}

const rightStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '55%',
    alignItems: 'center',
}

function Invitationedit(props) {

    const device = Checkdevice()
    const ht = ScreenHeight()
    const wt = ScreenWidth()
    const { token } = Retrieve_personal_info()
    const [firsttime, setFirsttime] = useState(true)

    const [firsttimeenter, setFirsttimeenter] = useState(true) // if user is first time enter, if true let user select a template

    // btn selected
    const [selectedpage, setSelectedpage] = useState(EMPTY)
    const [selectedsubpage, setSelectedsubpage] = useState('') // pages that is now showing

    // editor or guestlist ( true is editor, false is guestlist)
    const [editororguest, setEditororguest] = useState(-1)

    // all guests
    const [guestids, setGuestids] = useState([])
    const [guestid_guestinfo_map, setGuestid_guestinfo_map] = useState({})

    // card data
    const [itype, setItype] = useState(EMPTY)
    const [displayseq, setDisplayseq] = useState([]) // display sequence of
    const [displaytxtmap, setDisplaytxtmap] = useState({}) // change the title for the default ones
    const [displayonoffmap, setDisplayonoffmap] = useState({}) // user can select to on off certain components
    const [urlhandler, setUrlhandler] = useState(EMPTY)
    const [css_map, setCss_map] = useState({}) // contain css of maintext, subtext
    const [titletext, setTitletext] = useState(EMPTY)
    const [name, setName] = useState(EMPTY)
    const [ntt, setNtt] = useState(EMPTY) // nametype for tablet
    const [ntw, setNtw] = useState(EMPTY) // nametype for web
    const [ntm, setNtm] = useState(EMPTY) // nametype for mobile
    const [naw, setNaw] = useState(EMPTY) // nameanimation for web
    const [nat, setNat] = useState(EMPTY) // nameanimation for tablet
    const [nam, setNam] = useState(EMPTY) // nameanimation for mobile
    const [nameanimationdatatxt, setNameanimationdatatxt] = useState('')
    const [nameanimationdatatimer, setNameanimationdatatimer] = useState(0)
    const [spousename, setSpousename] = useState(EMPTY)
    const [babyname, setBabyname] = useState(EMPTY)
    const [location, setLocation] = useState(EMPTY)
    const [mapmode, setMapmode] = useState(0)
    const [maintext, setMaintext] = useState(EMPTY)
    const [wproposal, setProposal] = useState(EMPTY) // wedding proposal
    const [whowwemet, setHowwemet] = useState(EMPTY) // wedding how we met
    const [subtext, setSubtext] = useState(EMPTY)
    const [date_e, setDate_e] = useState(EMPTY)
    const [date_w, setDate_w] = useState(EMPTY)
    const [dt_type, setDt_type] = useState(EMPTY) // different ui representation of date time
    const [lat, setLat] = useState('0')
    const [long, setLong] = useState('0')
    const [locurl, setLocurl] = useState('') // url of the location, ideally is google map link
    const [locdescript, setLocdescript] = useState('') // location description
    const [time, setTime] = useState(EMPTY)
    const [qnas, setQnas] = useState([])

    const [specificcompff_map, setSpecificcompff_map] = useState({})
    const [ffobj, setFfobj] = useState({})
    const [priff, setPriff] = useState('')
    const [secff, setSecff] = useState('')

    const [locationff, setLocationff] = useState(-1) // derieved state from pri or secff
    const [datetimeff, setDatetimeff] = useState(-1) // derieved state from pri or secff
    const [notiff, setNotiff] = useState(-1) // derieved state from pri or secff
    const [btmbarff, setBtmbarff] = useState(-1) // derieved state from pri or secff

    const [fc, setFc] = useState(EMPTY) // FONT COLOR
    const [schedules, setSchedules] = useState([])
    const [socialmedias, setSocialmedias] = useState([]) // facebook link, instagram link, whatsapp link
    const [contacts, setContacts] = useState([])
    const [alticlinks, setAlticlinks] = useState([]) // alternate invitation card link, link to another Invitation card. Can be use as a bilingual invitation card.
    const [promote, setPromote] = useState([]) // promotes vendors that user engaged
    const [passcode, setPasscode] = useState(EMPTY)
    const [level, setLevel] = useState(0) // determine whether to unlock special features
    const [gallery_quota, setGallery_quota] = useState(0)

    const [_id, set_id] = useState(EMPTY)
    const [u_id, setU_id] = useState(EMPTY)
    const [menutitles, setMenutitles] = useState(partialtitles)
    const [images, setImages] = useState([])
    const [imgcss_map, setImagecss_map] = useState({})
    // 'mainimg': {
    //     'borderRadius': true, // got borderradius
    //     'boxShadow': true,  // got boxshadow
    // },
    const [svgoverlay_map, setSvgoverlay_map] = useState({})
    // 'mainimg': {
    //     'svgitem': 0, // 0 - 'we do', 1 - heart, ...
    //     'mode': 0, // 0 - direct appear, 1 - animate once, 2 - forever animate
    //     'color': '#ffffff', // color of the animated text
    //     'replay': false,
    //     'speed' : 0, // 0 = slow, 1 = mid, 2 = fast | speed of the animated text
    //     'svgposition': 2, // 0 = flex-start, 1 = center, 2 = flex-end | svg position within image.
    //     'sw': 45 // stroke width
    // }

    const [popupmsg, setPopupmsg] = useState(EMPTY)

    // template type & color & textures
    const [templatetype, setTemplatetype] = useState(-1)
    const [templatecolor, setTemplatecolor] = useState(EMPTY)
    const [templatebgcolor, setTemplatebgcolor] = useState(EMPTY)
    const [textures, setTextures] = useState([])

    // style type
    const [styletype, setStyletype] = useState(-1)
    const [styleformation, setStyleformation] = useState(0)
    const [styleformationmobile, setStyleformationmobile] = useState(0)
    const [styleorientation, setStyleorientation] = useState(0)
    const [styleorientationmobile, setStyleorientationmobile] = useState(0)
    const [sep, setSep] = useState(EMPTY) // separator

    // border
    const [styleborder, setStyleborder] = useState(0)
    const [styleborderwt, setStyleborderwt] = useState(null) // true or false
    const [styleborderbr, setStyleborderbr] = useState(null) // true or false
    const [stylebordercolor, setStylebordercolor] = useState('') // string

    // modal for send
    const [showsend, setShowsend] = useState(false)

    // openings start
    const [openingnames, setOpeningnames] = useState([])
    const [openingtype, setOpeningtype] = useState(EMPTY) // Convo or Story
    const [openingobjs, setOpeningobjs] = useState([]) // for Convo only
    const [openingclosing, setOpeningclosing] = useState(0)
    const [openingstoryobjs, setOpeningstoryobjs] = useState(EMPTY) // for Story only
    const [animationtype, setAnimationtype] = useState(EMPTY)
    const [animationobjs, setAnimationobjs] = useState([])
    const [toggleopening, setToggleopening] = useState(false) // eslint-disable-line
    const [history, setHistory] = useState([]) // this will trigger to open the open tab
    // openings end

    // navigation 
    const [menutype, setMenutype] = useState('') // none, btmnavbar, stickytopnav, menu, btmnavbarwithouticon,
    const [menuicongate, setMenuicongate] = useState(true) // show icon or not show icon
    const [menustyle, setMenustyle] = useState({}) // { 'backgroundColor': "#ffffff", 'color': "#333333", 'borderColor': "#ffffff" }
    const [menustyledited, setMenustyleedited] = useState(-1) // counter to check if menustyle edited
    const [menuitems, setMenuitems] = useState({})
    const [menuitemsedited, setMenuitemsedited] = useState(false)
    const [menuseq, setMenuseq] = useState([])
    const [menuseqedited, setMenuseqedited] = useState(false)
    const [notificationbool, setNotificationbool] = useState(false)
    const [notificationtitle, setNotificationtitle] = useState(false)
    const [notificationtxt, setNotificationtxt] = useState('')
    const [menumore, setMenumore] = useState([])
    const [registry, setRegistry] = useState({}) // itype registry

    const [editedsignal, setEditedsignal] = useState(false) // prevent user from clicking preview if nt save

    const [help, setHelp] = useState(false)
    const [returnhome, setReturnhome] = useState(false)

    const [rsvpactive, setRsvpactive] = useState(false)

    const [backupdata, setBackupdata] = useState({})
    const [saveprompt, setSaveprompt] = useState(false) // prompt user whether to discard or save before redirect to another page 
    const [proceedingpage, setProceedingpage] = useState('') // proceed to this page after saveprompt modcfm is confirm aka true, this includes : "Guest List", "Navigation"
    const [subpageexit, setSubpageexit] = useState(false)

    const init = (id, page) => {
        try {

            // setEditororguest(0)
            // setSelectedpage('Details')
            // setSelectedsubpage('')
            // props.history.push('/invitationbuilder/' + urlhandler + '/edit')
            if (page !== 'gl') {
                let options = {
                    method: 'POST',
                    url: INVITATION_READEDITINIT,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                    data: { id }
                }
                axios(options).then((item) => {
                    const { card, level = 0, gallery_quota = 50 } = item.data
                    const { itype, guestids, guestid_guestinfo_map, urlhandler, css_map, specificcompff_map, ffobj, name, ntw, ntt, ntm, naw, nat, nam, titletext, spousename, babyname, location, mapmode, maintext, whowwemet, wproposal, subtext, date_e, date_w, dt_type, lat, long, locdescript, locurl, _id, u_id, time, images, qnas, templatetype, templatecolor, templatebgcolor, textures, styleborder, styleborderwt, styleborderbr, stylebordercolor, styleformation, styleformationmobile, styleorientation, styleorientationmobile, styletype, stylesep, schedules, passcode, openingnames, openingtype, openingclosing, openingobjs, openingstoryobjs, animationtype, animationobjs, alticlinks, promote, socialmedialinks, contacts, fc, rsvpactive, menutype, menuicongate, menustyle, menuitems, menumore, menuseq, notificationbool, notificationtitle, notificationtxt, nameanimationdata, imgcss_map, svgoverlay_map, registry, firsttimeenter, displayseq, displaytxtmap, displayonoffmap } = card

                    setFirsttimeenter(firsttimeenter)
                    setSelectedpage('Details')
                    setSelectedsubpage('')

                    // guests
                    setGuestids(guestids)
                    setGuestid_guestinfo_map(guestid_guestinfo_map)

                    // card data
                    setItype(itype)
                    setUrlhandler(urlhandler)
                    setCss_map(css_map)
                    setDisplayseq(displayseq)
                    setDisplaytxtmap(displaytxtmap)
                    setDisplayonoffmap(displayonoffmap)

                    setName(name)
                    setNtw(ntw)
                    setNtt(ntt)
                    setNtm(ntm)
                    setNaw(naw)
                    setNat(nat)
                    setNam(nam)
                    setNameanimationdatatxt(nameanimationdata.txt)
                    setNameanimationdatatimer(nameanimationdata.duration)
                    setTitletext(titletext)
                    setSpousename(spousename)
                    setBabyname(babyname)
                    setLocation(location)
                    setMapmode(mapmode)
                    setProposal(wproposal)
                    setHowwemet(whowwemet)
                    setMaintext(maintext)
                    setSubtext(subtext)

                    date_e === 'I don\'t have a date.' ? setDate_e('') : setDate_e((typeof (date_e) === 'string' && date_e.length > 0) ? new Date(date_e) : '')
                    date_w === 'I don\'t have a date.' ? setDate_w('') : setDate_w((typeof (date_w) === 'string' && date_w.length > 0) ? new Date(date_w) : '')
                    setDt_type(dt_type)
                    setLat(lat)
                    setLong(long)
                    setLocurl(locurl)
                    setLocdescript(locdescript)
                    set_id(_id)
                    setU_id(u_id)
                    setTime(time)

                    setFfobj(ffobj)

                    const { priff, secff } = ffobj
                    setPriff(priff)
                    setSecff(secff)

                    setLocationff(ffobj[specificcompff_map['location']])
                    setBtmbarff(ffobj[specificcompff_map['btmbar']])
                    setDatetimeff(ffobj[specificcompff_map['datetime']])
                    setNotiff(ffobj[specificcompff_map['noti']])
                    setSpecificcompff_map(specificcompff_map)

                    setFc(fc)
                    setImages(images.slice())
                    setImagecss_map(imgcss_map)
                    setSvgoverlay_map(svgoverlay_map)
                    setQnas(qnas ? qnas : [])
                    setTemplatetype(templatetype)
                    setTemplatecolor(templatecolor)
                    setTemplatebgcolor(templatebgcolor)
                    setTextures(textures)
                    setStyletype(styletype)
                    setStyleformation(styleformation)
                    setStyleformationmobile(styleformationmobile)
                    setStyleorientation(styleorientation)
                    setStyleorientationmobile(styleorientationmobile)
                    setStyleborder(styleborder)
                    setStyleborderwt(styleborderwt)
                    setStyleborderbr(styleborderbr)
                    setStylebordercolor(stylebordercolor)
                    setSep(stylesep)
                    setSchedules(schedules)
                    setSocialmedias(socialmedialinks)
                    setContacts(contacts)
                    setAlticlinks(alticlinks ? alticlinks : [])
                    setPromote(promote ? promote : [])
                    setPasscode(passcode.length > 0 ? passcode : EMPTY)
                    setLevel(level)
                    setGallery_quota(gallery_quota)
                    setNotificationbool(notificationbool)
                    setNotificationtitle(notificationtitle)
                    setNotificationtxt(notificationtxt)
                    setRegistry(registry)

                    setOpeningnames(openingnames)
                    setOpeningtype(openingtype)
                    setToggleopening(openingtype !== 'Empty' ? true : false) // if openingtype not Empty, den its activated
                    setOpeningobjs(openingobjs)
                    setOpeningclosing(openingclosing)
                    setOpeningstoryobjs(openingstoryobjs)
                    setAnimationtype(animationtype)
                    setAnimationobjs(animationobjs)

                    setMenustyle(menustyle)
                    setMenutype(menutype)
                    setMenuicongate(menuicongate)
                    setMenuitems(menuitems)
                    setMenuseq(menuseq)
                    setMenumore(menumore)

                    setRsvpactive(rsvpactive)

                    setEditororguest(page === 'gl' ? 1 : 0)

                    setFirsttime(false)

                }).catch((e) => {
                    props.history.push('/')
                })
            }
            else if (page === 'gl') {
                setEditororguest(page === 'gl' ? 1 : 0)
                setFirsttime(false)
            }
        }
        catch (e) { props.history.push('/') }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        if (firsttime) {
            props.hidetopbar(true)
            const { id, page } = props.match.params
            init(id, page)
        }
        return () => props.hidetopbar(false)

    }, [])

    useEffect(() => {
        if (!firsttime && history.length < 10) {
            var newHistory = history.slice()
            newHistory.push(selectedpage)
            setHistory(newHistory)
            if (newHistory.toString() === secretfeature) {
                setToggleopening(true)
            }
            else if (newHistory.toString() === secretfeature1) {
                setToggleopening(true)
            }
        }
    }, [selectedpage])

    useEffect(() => {
        let local_menutitles = menutitles.slice()
        // if (itype !== 'Postponement' && itype !== EMPTY && local_menutitles.indexOf('Schedules') < 0) {
        //     local_menutitles.push('Schedules')
        // }
        // if ((toggleopening || level > 0) && local_menutitles.indexOf('Openings') < 0) {
        //     local_menutitles.push('Openings')
        // }
        setMenutitles(local_menutitles)
    }, [itype, level])

    const timersequence = (run, msg) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                setPopupmsg(msg);
                setTimeout(() => {
                    run();
                    setPopupmsg('');
                    resolve(
                        {
                            'Saved': 'SUCCESS',
                            'Failed': 'FAILURE',
                            'Failed to update.': 'FAILURE'
                        }[msg]
                    ); // Resolve the promise when the timers are done
                }, 1250);
            }, 1250);
        });
    };

    const SaveFunc = () => {
        setPopupmsg('Saving...')

        // update ffobj
        let new_ffobj = Object.assign(ffobj, {})
        new_ffobj['priff'] = priff
        new_ffobj['secff'] = secff

        var sv = 20

        try {
            var totalstring = (name + spousename + babyname + location + maintext.length + lat + long + time + _id + ntm + ntt + ntw + naw + nat + nam + JSON.stringify(schedules) + JSON.stringify({ txt: nameanimationdatatxt, duration: nameanimationdatatimer })).toString()
            sv = Math.round(totalstring.length * 369)
        }
        catch (e) { }

        let options = {
            method: 'POST',
            url: INVITATION_SAVE,
            headers: { 'Authorization': 'Bearer ' + token },
            data: { css_map, ntw, ntt, ntm, naw, nat, nam, name, spousename, babyname, location, mapmode, locurl, locdescript, maintext, subtext, dt_type, date_e, date_w, lat, long, time, _id, qnas, sv, templatetype, templatecolor, templatebgcolor, textures, styletype, styleformation, styleformationmobile, styleorientation, styleorientationmobile, styleborder, styleborderwt, styleborderbr, stylebordercolor, 'stylesep': sep, fc, titletext, schedules, openingnames, openingclosing, openingtype, openingobjs, animationtype, animationobjs, openingstoryobjs, 'socialmedialinks': socialmedias, contacts, alticlinks, promote, urlhandler, passcode, whowwemet, wproposal, menutype, menustyle, menuitems, menuseq, menumore, notificationbool, notificationtitle, notificationtxt, nameanimationdata: { txt: nameanimationdatatxt, duration: nameanimationdatatimer }, ffobj: new_ffobj, specificcompff_map },
        }

        setTimeout(() => {
            try {
                axios(options).then((response) => {
                    const { message } = response.data
                    if (message === 'SUCCESS') {
                        setPopupmsg('Saved')
                        setEditedsignal(false)
                        setTimeout(() => {
                            setPopupmsg('')
                        }, 1500)
                    }
                })
            }
            catch (e) {
                console.log(e)
            }
        }, 2000)
    }

    const leftbtn = <button className="planning_backicon" onClick={() => { setReturnhome(true) }}>{ARROWLEFT_ICON}</button>

    var previewbtn = () => {
        const tourl = passcode.length > 0 ? '/invitation/' + urlhandler + '/passcode=' + passcode : '/invitation/' + urlhandler
        return <button className="iepreview_btn" onClick={() => { document.getElementById('iepreview_btninvisible').click() }}>{LOOKUPUNSTYLE_ICON} Preview
            <Link id="iepreview_btninvisible" to={tourl} target="_blank" />
        </button>
    }

    const [tooglestate, setTogglestate] = useState(false)

    var guestoreditorbtn = <div className='ietypeselecterroot'>
        <Togglebutton
            singlebtnstyle={{ width: device === 'MOBILE' ? 65 : 75 }}
            funcs={[
                () => {
                    if (editororguest === 1) {
                        const { id } = props.match.params
                        props.history.push('/tools/invitationbuilder/' + id + '/edit')
                        try { // init
                            let options = {
                                method: 'POST',
                                url: INVITATION_READEDITINIT,
                                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                                data: { id }
                            }
                            axios(options).then((item) => {
                                setTogglestate(false)
                                const { card, level = 0, gallery_quota = 50 } = item.data
                                const { itype, guestids, guestid_guestinfo_map, urlhandler, css_map, specificcompff_map, ffobj, name, ntw, ntt, ntm, naw, nat, nam, titletext, spousename, babyname, location, mapmode, maintext, whowwemet, wproposal, subtext, date_e, date_w, dt_type, lat, long, locdescript, locurl, _id, u_id, time, images, qnas, templatetype, templatecolor, templatebgcolor, textures, styleborder, styleborderwt, styleborderbr, stylebordercolor, styleformation, styleformationmobile, styleorientation, styleorientationmobile, styletype, stylesep, schedules, passcode, openingnames, openingtype, openingclosing, openingobjs, openingstoryobjs, animationtype, animationobjs, alticlinks, promote, socialmedialinks, contacts, fc, rsvpactive, menutype, menuicongate, menustyle, menuitems, menumore, menuseq, notificationbool, notificationtitle, notificationtxt, nameanimationdata, imgcss_map, svgoverlay_map, registry, firsttimeenter, displayseq, displaytxtmap, displayonoffmap } = card

                                setFirsttimeenter(firsttimeenter)

                                // guests
                                setGuestids(guestids)
                                setGuestid_guestinfo_map(guestid_guestinfo_map)
                                setEditororguest(0)
                                setSelectedpage('Details')
                                setSelectedsubpage('')

                                // card data
                                setItype(itype)
                                setUrlhandler(urlhandler)
                                setCss_map(css_map)
                                setDisplayseq(displayseq)
                                setDisplaytxtmap(displaytxtmap)
                                setDisplayonoffmap(displayonoffmap)

                                setName(name)
                                setNtw(ntw)
                                setNtt(ntt)
                                setNtm(ntm)
                                setNaw(naw)
                                setNat(nat)
                                setNam(nam)
                                setNameanimationdatatxt(nameanimationdata.txt)
                                setNameanimationdatatimer(nameanimationdata.duration)
                                setTitletext(titletext)
                                setSpousename(spousename)
                                setBabyname(babyname)
                                setLocation(location)
                                setMapmode(mapmode)
                                setProposal(wproposal)
                                setHowwemet(whowwemet)
                                setMaintext(maintext)
                                setSubtext(subtext)

                                date_e === 'I don\'t have a date.' ? setDate_e('') : setDate_e((typeof (date_e) === 'string' && date_e.length > 0) ? new Date(date_e) : '')
                                date_w === 'I don\'t have a date.' ? setDate_w('') : setDate_w((typeof (date_w) === 'string' && date_w.length > 0) ? new Date(date_w) : '')
                                setDt_type(dt_type)
                                setLat(lat)
                                setLong(long)
                                setLocurl(locurl)
                                setLocdescript(locdescript)
                                set_id(_id)
                                setU_id(u_id)
                                setTime(time)

                                setFfobj(ffobj)

                                const { priff, secff } = ffobj
                                setPriff(priff)
                                setSecff(secff)

                                setLocationff(ffobj[specificcompff_map['location']])
                                setBtmbarff(ffobj[specificcompff_map['btmbar']])
                                setDatetimeff(ffobj[specificcompff_map['datetime']])
                                setNotiff(ffobj[specificcompff_map['noti']])
                                setSpecificcompff_map(specificcompff_map)

                                setFc(fc)
                                setImages(images.slice())
                                setImagecss_map(imgcss_map)
                                setSvgoverlay_map(svgoverlay_map)
                                setQnas(qnas ? qnas : [])
                                setTemplatetype(templatetype)
                                setTemplatecolor(templatecolor)
                                setTemplatebgcolor(templatebgcolor)
                                setTextures(textures)
                                setStyletype(styletype)
                                setStyleformation(styleformation)
                                setStyleformationmobile(styleformationmobile)
                                setStyleorientation(styleorientation)
                                setStyleorientationmobile(styleorientationmobile)
                                setStyleborder(styleborder)
                                setStyleborderwt(styleborderwt)
                                setStyleborderbr(styleborderbr)
                                setStylebordercolor(stylebordercolor)
                                setSep(stylesep)
                                setSchedules(schedules)
                                setSocialmedias(socialmedialinks)
                                setContacts(contacts)
                                setAlticlinks(alticlinks ? alticlinks : [])
                                setPromote(promote ? promote : [])
                                setPasscode(passcode.length > 0 ? passcode : EMPTY)
                                setLevel(level)
                                setGallery_quota(gallery_quota)
                                setNotificationbool(notificationbool)
                                setNotificationtitle(notificationtitle)
                                setNotificationtxt(notificationtxt)
                                setRegistry(registry)

                                setOpeningnames(openingnames)
                                setOpeningtype(openingtype)
                                setToggleopening(openingtype !== 'Empty' ? true : false) // if openingtype not Empty, den its activated
                                setOpeningobjs(openingobjs)
                                setOpeningclosing(openingclosing)
                                setOpeningstoryobjs(openingstoryobjs)
                                setAnimationtype(animationtype)
                                setAnimationobjs(animationobjs)

                                setMenustyle(menustyle)
                                setMenutype(menutype)
                                setMenuicongate(menuicongate)
                                setMenuitems(menuitems)
                                setMenuseq(menuseq)
                                setMenumore(menumore)

                                setRsvpactive(rsvpactive)

                                setEditororguest(0)
                                setFirsttime(false)

                            }).catch((e) => {
                                props.history.push('/')
                            })
                        }
                        catch (e) { props.history.push('/') }
                    }

                },
                () => {
                    if (selectedpage === 'Navigation' || selectedpage === 'Gallery' || selectedpage === 'Templates' || selectedpage === 'Background' || selectedpage === 'Details') {
                        if (Object.keys(backupdata).length > 0) {
                            setSaveprompt(true)
                            setProceedingpage('Guest List')
                        }
                        else {
                            setEditororguest(1)
                            props.history.push('/tools/invitationbuilder/' + _id + '/edit/gl')
                        }
                    }
                    else {
                        setEditororguest(1)
                        props.history.push('/tools/invitationbuilder/' + _id + '/edit/gl')
                    }
                }
            ]}
            txts={['Editor', 'Guest List']}
            btnselected={editororguest}
        />
        <div className='ietypequestionmark' onClick={() => { setHelp(true) }}>?</div>
    </div>

    const sendbtn = <button className='iecommon_btn' style={{ marginRight: device === 'MOBILE' ? 5 : 0 }} onClick={() => setShowsend(!showsend)}>Send</button>

    const savebtn = null
    // const savebtn = editedsignal
    //     ? <button
    //         className={`iecommon_btn iesave_btn${device}`}
    //         style={{ boxShadow: '0 0 50px #f77f7f', transition: 'box-shadow 1500ms cubic-bezier(0.23, 1, 0.32, 1) 0s', height: 40, marginRight: device === 'MOBILE' ? 10 : 0 }}
    //         onClick={SaveFunc}>
    //         Save
    //     </button>
    //     : <button
    //         className={`iecommon_btn iesave_btn${device}`}
    //         style={{ marginRight: device === 'MOBILE' ? 10 : 0, }}
    //         onClick={SaveFunc}
    //     >Save</button>

    const mobilesavebtn = null
    // const mobilesavebtn = <button
    //     style={{
    //         boxShadow: editedsignal ? '0 0 50px #f77f7f' : '',
    //         transition: editedsignal ? 'box-shadow 1500ms cubic-bezier(0.23, 1, 0.32, 1) 0s' : '',
    //         width: 'calc( 100% - 285px )',
    //         height: 60,
    //         fontSize: 15,

    //         backgroundColor: 'white',
    //         border: '1px solid #dddddd',
    //         borderBottom: 'none',
    //         borderTop: 'none',
    //         borderRight: 'none'

    //     }}
    //     onClick={SaveFunc}
    // >Save</button>

    const invisiblesavebtn = <button className={`invisiblesavebtn`}>Save</button>



    const clearBackup = () => {
        setBackupdata({})
    }

    const restoreNavbackup = () => {
        if (Object.keys(backupdata).length > 0) {
            const { menutype, menuicongate, menustyle, menustyledited, menuitems, menuitemsedited, menuseq, menuseqedited, menumore, specificcompff_map, btmbarff } = backupdata
            setMenutype(menutype)
            setMenuicongate(menuicongate)

            setMenustyle(JSON.parse(JSON.stringify(menustyle)))
            setMenustyleedited(menustyledited)
            setMenuitems(JSON.parse(JSON.stringify(menuitems)))
            setMenuitemsedited(menuitemsedited)
            setMenuseq(menuseq)
            setMenuseqedited(menuseqedited)
            setMenumore(menumore)
            setSpecificcompff_map(JSON.parse(JSON.stringify(specificcompff_map)))
            setBtmbarff(btmbarff)
            clearBackup()
        }
    }

    const restoreGallerybackup = () => {
        if (Object.keys(backupdata).length > 0) {
            const { imgcss_map, svgoverlay_map } = backupdata
            setImagecss_map(JSON.parse(JSON.stringify(imgcss_map)))
            setSvgoverlay_map(JSON.parse(JSON.stringify(svgoverlay_map)))
            clearBackup()
        }
    }

    const restoreTemplatebackup = () => {
        if (Object.keys(backupdata).length > 0) {
            const {
                templatetype,
                ntm, ntt, ntw, ffobj, fc, styleborder, stylebordercolor, styleborderbr, styleborderwt, styletype, styleorientationmobile, styleorientation, styleformationmobile, styleformation, sep, menutype, menuicongate, menustyle, templatecolor
            } = backupdata

            setTemplatetype(templatetype)

            setNtm(ntm)
            setNtt(ntt)
            setNtw(ntw)
            setFfobj(ffobj)
            const { priff, secff } = ffobj
            setPriff(priff)
            setSecff(secff)
            setFc(fc)
            setStyleborder(styleborder)
            setStylebordercolor(stylebordercolor)
            setStyleborderbr(styleborderbr)
            setStyleborderwt(styleborderwt)
            setStyletype(styletype)
            setStyleorientationmobile(styleorientationmobile)
            setStyleorientation(styleorientation)
            setStyleformationmobile(styleformationmobile)
            setStyleformation(styleformation)
            setSep(sep)
            setMenutype(menutype)
            setMenuicongate(menuicongate)
            setTemplatecolor(templatecolor)
            setMenustyle(menustyle)

            clearBackup()
        }
    }

    const restoreBackgroundbackup = () => {
        if (Object.keys(backupdata).length > 0) {
            const {
                styleformation, styleformationmobile, styleorientation, styleorientationmobile, styletype,
                sep,
                styleborder, styleborderwt, styleborderbr, stylebordercolor,
                templatecolor, templatebgcolor
            } = backupdata

            if (selectedsubpage === 'Design') {
                setStyleformation(styleformation)
                setStyleformationmobile(styleformationmobile)
                setStyleorientation(styleorientation)
                setStyleorientationmobile(styleorientationmobile)
                setStyletype(styletype)
                setSep(sep)
            }
            else if (selectedsubpage === 'Frame') {
                setStyleborder(styleborder)
                setStyleborderwt(styleborderwt)
                setStyleborderbr(styleborderbr)
                setStylebordercolor(stylebordercolor)
            }
            else if (selectedsubpage === 'Banners') {
                setSep(sep)
            }
            else if (selectedsubpage === 'Colors') {
                setTemplatecolor(templatecolor)
                setTemplatebgcolor(templatebgcolor)
            }
            clearBackup()
        }
    }

    const restoreDetailsbackup = () => {
        if (Object.keys(backupdata).length > 0) {
            if (selectedsubpage === 'Names') {
                const { name, spousename, nameanimationdatatxt, nameanimationdatatimer, ntw, ntt, ntm, naw, nat, nam } = backupdata
                setName(name)
                setSpousename(spousename)
                setNameanimationdatatxt(nameanimationdatatxt)
                setNameanimationdatatimer(nameanimationdatatimer)
                setNtw(ntw)
                setNtt(ntt)
                setNtm(ntm)
                setNaw(naw)
                setNat(nat)
                setNam(nam)
            }

            else if (selectedsubpage === 'Fonts') {
                const { fc, priff, secff, ffobj } = backupdata
                setFc(fc)
                setPriff(priff)
                setSecff(secff)
                setFfobj(Object.assign({}, ffobj))
            }

            else if (selectedsubpage === 'Our Journey') {
                const { css_map, maintext, whowwemet, wproposal } = backupdata
                setCss_map(Object.assign({}, css_map))
                setMaintext(maintext)
                setHowwemet(whowwemet)
                setProposal(wproposal)

            }

            else if (selectedsubpage === 'Location') {
                const { location, lat, long, locurl, locdescript, specificcompff_map, mapmode, locationff } = backupdata
                setLocation(location)
                setLat(lat)
                setLong(long)
                setLocurl(locurl)
                setLocdescript(locdescript)
                setSpecificcompff_map(specificcompff_map)
                setMapmode(mapmode)
                setLocationff(locationff)
            }

            else if (selectedsubpage === 'Date Time') {
                const { dt_type, date_w, date_e, time, specificcompff_map, datetimeff } = backupdata
                setDt_type(dt_type)
                setDate_w(date_w)
                setDate_e(date_e)
                setTime(time)
                setSpecificcompff_map(specificcompff_map)
                setDatetimeff(datetimeff)
            }

            else if (selectedsubpage === 'Q&A') {
                const { qnas } = backupdata
                setQnas(qnas)
            }

            else if (selectedsubpage === 'Contacts') {
                const { contacts } = backupdata
                setContacts(contacts)
            }

            else if (selectedsubpage === 'Promote') {
                const { promote } = backupdata
                setPromote(promote)
            }

            else if (selectedsubpage === 'Notification') {
                const { notificationbool, notificationtitle, notificationtxt, specificcompff_map } = backupdata
                setNotificationbool(notificationbool)
                setNotificationtitle(notificationtitle)
                setNotificationtxt(notificationtxt)
                setSpecificcompff_map(specificcompff_map)
            }

            else if (selectedsubpage === 'Share') {
                const { socialmedias } = backupdata
                setSocialmedias(socialmedias)
            }

            else if (selectedsubpage === 'Schedules') {
                const { schedules } = backupdata
                setSchedules(schedules)
            }

            else if (selectedsubpage === 'Openings') {
                const { openingnames, openingobjs, openingstoryobjs, openingtype, animationobjs, animationtype, openingclosing } = backupdata
                setOpeningnames(openingnames)
                setOpeningobjs(openingobjs)
                setOpeningstoryobjs(openingstoryobjs)
                setOpeningtype(openingtype)
                setAnimationobjs(animationobjs)
                setAnimationtype(animationtype)
                setOpeningclosing(openingclosing)
            }

            else if (selectedsubpage === 'Registry') {
                const { registry } = backupdata
                setRegistry(JSON.parse(JSON.stringify(registry)))
            }

            else if (selectedsubpage === 'Sequence') { //golden
                const { displayseq, displayonoffmap, displaytxtmap } = backupdata
                setDisplayseq(displayseq)
                setDisplayonoffmap(JSON.parse(JSON.stringify(displayonoffmap)))
                setDisplaytxtmap(JSON.parse(JSON.stringify(displaytxtmap)))
            }

            clearBackup()

        }
    }

    const updateNavbackup = () => {
        setBackupdata({
            menutype,
            menustyle,
            menuitems,
            menuseq,
            menumore,
            specificcompff_map,
            menustyledited,
            menuitemsedited,
            menuseqedited,
            btmbarff
        })
    }

    const updateGallerybackup = () => {
        setBackupdata({
            imgcss_map: JSON.parse(JSON.stringify(imgcss_map)),
            svgoverlay_map: JSON.parse(JSON.stringify(svgoverlay_map))
        })
    }

    const updateTemplatebackup = () => {
        setBackupdata({
            templatetype: templatetype,

            ntm: ntm,
            ntt: ntt,
            ntw: ntw,
            ffobj: ffobj,
            styleborder: styleborder,
            stylebordercolor: stylebordercolor,
            styleborderbr: styleborderbr,
            styleborderwt: styleborderwt,
            styletype: styletype,
            styleorientationmobile: styleorientationmobile,
            styleorientation: styleorientation,
            styleformationmobile: styleformationmobile,
            styleformation: styleformation,
            sep: sep,
            menutype: menutype,
            menustyle: menustyle,
            templatecolor: templatecolor,
            fc: fc
        })
    }

    // useEffect(() => {
    //     console.log('backupdata useeffect', backupdata)
    // }, [backupdata])

    const updateBackgroundbackup = () => {
        if (selectedsubpage === 'Design') {

            console.log('updateBackup')
            console.log({
                styleformation: styleformation,
                styleformationmobile: styleformationmobile,
                styleorientation: styleorientation,
                styleorientationmobile: styleorientationmobile,
                styletype: styletype,
                sep: sep,
            })
            setBackupdata({
                styleformation: styleformation,
                styleformationmobile: styleformationmobile,
                styleorientation: styleorientation,
                styleorientationmobile: styleorientationmobile,
                styletype: styletype,
                sep: sep,
            })
        }
        else if (selectedsubpage === 'Frame') {
            setBackupdata({
                styleborder: styleborder,
                styleborderwt: styleborderwt,
                styleborderbr: styleborderbr,
                stylebordercolor: stylebordercolor
            })
        }
        else if (selectedsubpage === 'Banners') {
            setBackupdata({
                sep: sep
            })
        }
        else if (selectedsubpage === 'Colors') {
            setBackupdata({
                templatebgcolor: templatebgcolor,
                templatecolor: templatecolor
            })
        }
    }

    const updateDetailsbackup = () => {

        if (selectedsubpage === 'Names') {
            setBackupdata({ name: name, spousename: spousename, nameanimationdatatxt: nameanimationdatatxt, nameanimationdatatimer: nameanimationdatatimer, ntw: ntw, ntt: ntt, ntm: ntm, naw: naw, nat: nat, nam: nam })
        }
        else if (selectedsubpage === 'Fonts') {
            setBackupdata({ fc: fc, priff: priff, secff: secff, ffobj: ffobj })
        }
        else if (selectedsubpage === 'Our Journey') {
            setBackupdata({ css_map: css_map, maintext: maintext, whowwemet: whowwemet, wproposal: wproposal })
        }
        else if (selectedsubpage === 'Location') {
            setBackupdata({ location: location, lat: lat, long: long, locurl: locurl, locdescript: locdescript, specificcompff_map: specificcompff_map, mapmode: mapmode, locationff: locationff })
        }
        else if (selectedsubpage === 'Date Time') {
            setBackupdata({ dt_type: dt_type, date_w: date_w, date_e: date_e, time: time, specificcompff_map: specificcompff_map, datetimeff: datetimeff })
        }
        else if (selectedsubpage === 'Q&A') {
            setBackupdata({ qnas: qnas.slice() })
        }
        else if (selectedsubpage === 'Contacts') {
            setBackupdata({ contacts: contacts.slice() })
        }
        else if (selectedsubpage === 'Promote') {
            setBackupdata({ promote: promote.slice() })
        }
        else if (selectedsubpage === 'Notification') {
            setBackupdata({ notificationbool: notificationbool, notificationtitle: notificationtitle, notificationtxt: notificationtxt, specificcompff_map: specificcompff_map })
        }
        else if (selectedsubpage === 'Share') {
            setBackupdata({ socialmedias: socialmedias.slice() })
        }
        else if (selectedsubpage === 'Schedules') {
            setBackupdata({ schedules: JSON.parse(JSON.stringify(schedules)) })
        }
        else if (selectedsubpage === 'Openings') {
            setBackupdata({ openingnames: openingnames, openingobjs: openingobjs, openingstoryobjs: openingstoryobjs, openingtype: openingtype, animationobjs: animationobjs, animationtype: animationtype, openingclosing: openingclosing })
        }
        else if (selectedsubpage === 'Registry') {
            setBackupdata({ registry: JSON.parse(JSON.stringify(registry)) })
        }
        else if (selectedsubpage === 'Sequence') {
            setBackupdata({
                displayseq: JSON.parse(JSON.stringify(displayseq)),
                displayonoffmap: JSON.parse(JSON.stringify(displayonoffmap)),
                displaytxtmap: JSON.parse(JSON.stringify(displaytxtmap))
            })
        }
    }

    const template_dom = <Templates
        firsttimeenter={firsttimeenter}
        clearBackup={clearBackup}
        updateBackup={updateTemplatebackup}
        restoreBackup={restoreTemplatebackup}
        itype={itype}

        updateTemplate={(val) => {
            if (templatetype !== parseInt(val)) { // new template type added
                setTemplatetype(parseInt(val))
            }
        }}

        updatePrebuilttemplate={(data) => {
            const {
                ntm, ntt, ntw, ffobj, fc,
                styleborder, stylebordercolor, styleborderbr, styleborderwt, styletype, styleorientationmobile, styleorientation, styleformationmobile, styleformation,
                sep, menutype, menuicongate, menustyle, templatecolor, svgoverlay_map
            } = data

            setNtm(ntm)
            setNtt(ntt)
            setNtw(ntw)
            setFfobj(ffobj)
            setFc(fc)

            const { priff, secff } = ffobj
            setPriff(priff)
            setSecff(secff)

            setStyleborder(styleborder)
            setStylebordercolor(stylebordercolor)
            setStyleborderbr(styleborderbr)
            setStyleborderwt(styleborderwt)
            setStyletype(styletype)
            setStyleorientationmobile(styleorientationmobile)
            setStyleorientation(styleorientation)
            setStyleformationmobile(styleformationmobile)
            setStyleformation(styleformation)
            setSep(sep)
            setMenutype(menutype)
            setMenuicongate(menuicongate)
            setTemplatecolor(templatecolor)
            setMenustyle(menustyle)
            setSvgoverlay_map(svgoverlay_map)

            if (firsttimeenter) {
                const { images, } = data
                setImages(images)
            }

        }}

        selectedTemplate={templatetype} // template
        device={device}
        wt={wt} // tablet and web screen proportion 0.55 | 0.45
        saveTemplates={() => {
            setPopupmsg('Saving...')
            const data = firsttimeenter
                ? {
                    urlhandler, _id, u_id, passcode, templatetype,
                    ntm, ntt, ntw, ffobj,
                    styleborder, stylebordercolor, styleborderbr, styleborderwt, styletype,
                    styleorientationmobile, styleorientation, styleformationmobile, styleformation,
                    sep, menutype, templatecolor, menustyle, fc, svgoverlay_map, images,
                }
                : {
                    urlhandler, _id, u_id, passcode, templatetype,
                    ntm, ntt, ntw, ffobj,
                    styleborder, stylebordercolor, styleborderbr, styleborderwt, styletype,
                    styleorientationmobile, styleorientation, styleformationmobile, styleformation,
                    sep, menutype, templatecolor, menustyle, fc, svgoverlay_map,
                }
            return axios({
                method: 'POST',
                url: firsttimeenter ? INVITATION_SAVETEMPLATEFIRSTTIME : INVITATION_SAVETEMPLATE,
                headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                data: data
            }).then((item) => {
                const { message } = item.data

                if (message === 'SUCCESS') {
                    timersequence(
                        () => {
                            if (firsttimeenter) {
                                setFirsttimeenter(false)
                                const { menuitems, menumore, menuseq, menustyle, menutype, menuicongate } = item.data.btmbardata
                                setMenuitems(menuitems)
                                setMenumore(menumore)
                                setMenuseq(menuseq)
                                setMenustyle(menustyle)
                                setMenutype(menutype)
                                setMenuicongate(menuicongate)
                            }

                        },
                        'Saved')
                }
                else {
                    timersequence(() => { }, 'Failed to update.')
                }
            }).catch((e) => {
                timersequence(() => { }, 'Failed to update.')
            })

        }}
    />

    var MENU_MAP_DOM = {
        'Details': <Details
            wt={wt}
            partialwt={wt * 0.45} // tablet and web screen proportion 0.55 | 0.45
            token={token}
            itype={itype} displayseq={displayseq} displayonoffmap={displayonoffmap} displaytxtmap={displaytxtmap} urlhandler={urlhandler} name={name} naw={naw} nat={nat} nam={nam}
            ntw={ntw} ntt={ntt} ntm={ntm} nameanimationdatatxt={nameanimationdatatxt}
            nameanimationdatatimer={nameanimationdatatimer} titletext={titletext} spousename={spousename}
            babyname={babyname} date_e={date_e} date_w={date_w} dt_type={dt_type}
            location={location} mapmode={mapmode} maintext={maintext} subtext={subtext}
            lat={lat} long={long} locurl={locurl} locdescript={locdescript}
            _id={_id} u_id={u_id} time={time} qnas={qnas}
            css_map={css_map} socialmedias={socialmedias} contacts={contacts}
            device={device} proposal={wproposal} howwemet={whowwemet}
            notificationbool={notificationbool} notificationtitle={notificationtitle}
            notificationtxt={notificationtxt}
            schedules={schedules} alticlinks={alticlinks}
            selectedsubpage={selectedsubpage} templatecolor={templatecolor}
            priff={priff} secff={secff}
            specificcompff_map={specificcompff_map}
            guestids={guestids}
            guestid_guestinfo_map={guestid_guestinfo_map}
            subpageexit={subpageexit}
            save={() => {
                const url = {
                    "Names": INVITATION_DNAME,
                    'Fonts': INVITATION_DFONTS,
                    'Our Journey': INVITATION_DJOURNEY,
                    'Location': INVITATION_DLOCATION,
                    'Date Time': INVITATION_DDATETIME,
                    'Q&A': INVITATION_DQNA,
                    'Contacts': INVITATION_DCONTACTS,
                    'Promote': INVITATION_DPROMOTE,
                    'Notification': INVITATION_DNOTIFICATION,
                    'Share': INVITATION_DSHARE,
                    'Schedules': INVITATION_DSCHEDULES,
                    'Openings': INVITATION_DOPENINGS,
                    'Registry': INVITATION_DREGISTRY,
                    'Sequence': INVITATION_DSEQUENCE
                }[selectedsubpage]

                let data = { urlhandler, _id, u_id, passcode }
                if (selectedsubpage === 'Names') {
                    data = { ...data, name, spousename, nameanimationdatatxt, nameanimationdatatimer, ntw, ntt, ntm, naw, nat, nam }
                }
                else if (selectedsubpage === 'Fonts') {
                    data = { ...data, fc, priff, secff, ffobj }
                }
                else if (selectedsubpage === 'Our Journey') {
                    data = { ...data, css_map, maintext, whowwemet, wproposal }
                }
                else if (selectedsubpage === 'Location') {
                    data = { ...data, location, lat, long, locurl, locdescript, specificcompff_map, mapmode }
                }
                else if (selectedsubpage === 'Date Time') {
                    data = { ...data, dt_type, date_w, date_e, time, specificcompff_map, datetimeff }
                }
                else if (selectedsubpage === 'Q&A') {
                    data = { ...data, qnas }
                }
                else if (selectedsubpage === 'Contacts') {
                    data = { ...data, contacts }
                }
                else if (selectedsubpage === 'Promote') {
                    data = { ...data, promote }
                }
                else if (selectedsubpage === 'Notification') {
                    data = { ...data, notificationbool, notificationtitle, notificationtxt, specificcompff_map }
                }
                else if (selectedsubpage === 'Share') {
                    data = { ...data, socialmedias }
                }
                else if (selectedsubpage === 'Schedules') {
                    data = { ...data, schedules }
                }
                else if (selectedsubpage === 'Openings') {
                    data = { ...data, openingnames, openingobjs, openingstoryobjs, openingtype, animationobjs, animationtype, openingclosing }
                }
                else if (selectedsubpage === 'Registry') {
                    data = { ...data, registry: backupdata['registry'], newregistry: registry }
                }
                // else if (selectedsubpage === 'Sequence') {
                //     data = {
                //         ...data,
                //         displayseq: backupdata['displayseq'], newdisplayseq: displayseq,
                //         displayonoffmap: backupdata['displayonoffmap'], newdisplayonoffmap: displayonoffmap,
                //         displaytxtmap: backupdata['displaytxtmap'], newdisplaytxtmap: displaytxtmap,
                //     }
                // }
                // console.log('data', data)
                setPopupmsg('Saving...')
                return axios({
                    method: 'POST',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                    data: data
                }).then((item) => {
                    const { message } = item.data
                    return message === 'SUCCESS'
                        ? timersequence(() => { }, 'Saved')
                        : timersequence(() => { }, 'Failed to update.')
                }).catch((e) => {
                    timersequence(() => { }, 'Failed to update.')
                })
            }}
            clearBackup={clearBackup}
            updateBackup={updateDetailsbackup}
            restoreBackup={restoreDetailsbackup}
            updateSubpageback={() => {
                Object.keys(backupdata).length > 0 ? setSaveprompt(true) : setSelectedsubpage('')
            }}
            updateSubpageexit={() => setSubpageexit(false)}

            updateCss_map={(d) => { setCss_map(d); setEditedsignal(true) }}
            updateTitletext={(t) => { setTitletext(t); setEditedsignal(true) }}
            updateName={(i) => { setName(i); setEditedsignal(true) }}
            updateNtt={(ntt) => { setNtt(ntt); setEditedsignal(true) }}
            updateNtw={(ntw) => { setNtw(ntw); setEditedsignal(true) }}
            updateNtm={(ntm) => { setNtm(ntm); setEditedsignal(true) }}
            updateNaw={(naw) => { setNaw(naw); setEditedsignal(true) }}
            updateNat={(nat) => { setNat(nat); setEditedsignal(true) }}
            updateNam={(nam) => { setNam(nam); setEditedsignal(true) }}
            updateSpousename={(i) => { setSpousename(i); setEditedsignal(true) }}
            updateBabyname={(i) => { setBabyname(i); setEditedsignal(true) }}
            updateLocation={(l) => { setLocation(l); setEditedsignal(true) }}
            updateMapmode={(m) => { setMapmode(parseInt(m.target.value)); setEditedsignal(true) }}
            updateMaintext={(t) => { setMaintext(t); setEditedsignal(true) }}
            updateSubtext={(t) => { setSubtext(t); setEditedsignal(true) }}
            updateDatew={(d) => { setDate_w(d); setEditedsignal(true) }}
            updateDatee={(d) => { setDate_e(d); setEditedsignal(true) }}
            updateDatetype={(d) => { setDt_type(d); setEditedsignal(true) }}
            updateLong={(l) => { setLong(l.toString()); setEditedsignal(true) }}
            updateLocurl={(l) => { setLocurl(l); setEditedsignal(true) }}
            updateLocdescript={(l) => { setLocdescript(l); setEditedsignal(true) }}
            updateLat={(l) => { setLat(l.toString()); setEditedsignal(true) }}
            updateTime={(t) => { setTime(t); setEditedsignal(true) }}
            updateSm={(sm) => { setSocialmedias(sm); setEditedsignal(true) }}
            updateContacts={(c) => { setContacts(c); setEditedsignal(true) }}
            updateDisplayseq={(c) => { setDisplayseq(c); setEditedsignal(true) }}
            updateFc={(fc) => { setFc(fc); setEditedsignal(true) }}
            updateProposal={(p) => { setProposal(p); setEditedsignal(true) }}
            updateHowwemet={(h) => { setHowwemet(h); setEditedsignal(true) }}
            updateNotificationbool={() => { setNotificationbool(!notificationbool); setEditedsignal(true) }}
            updateNotificationtitle={(n) => { setNotificationtitle(n); setEditedsignal(true) }}
            updateNotificationtxt={(n) => { setNotificationtxt(n); setEditedsignal(true) }}
            updateAlticlinks={(al) => { setAlticlinks(al); setEditedsignal(true) }}
            updateSelectedsubpage={(subpage) => setSelectedsubpage(subpage)}
            updateNowshowing={(showing) => {
                if (showing !== 'Schedules') { // Schedules is "local-save" capable
                    setEditedsignal(true)
                }
                setSelectedsubpage(showing);
            }}
            updateNowunshowing={(unshowing) => {
                if (unshowing === 'Schedules') { // Schedules is "local-save" capable
                }
                setSelectedsubpage('');
            }}
            updateNameanimationtext={(val) => { setNameanimationdatatxt(val); setEditedsignal(true) }}
            updateNameanimationtimer={(val) => { setNameanimationdatatimer(val); setEditedsignal(true) }}

            // schedule, do not require "save" indication. Schedule capable of local "save"
            updateSchedules={(s) => { setSchedules(s) }}
            addSchedule={(newschedule) => { // add a single schedule

                setPopupmsg('Saving...')
                return axios({
                    method: 'POST',
                    url: INVITATION_SCHEDULESAVE,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, // need token from the web
                    data: { urlhandler: urlhandler, u_id: u_id, invitationid: _id, newschedule: newschedule, schedules: backupdata['schedules'] }
                })
                    .then((item) => {
                        const { message } = item.data
                        return message === 'SUCCESS'
                            ? timersequence(() => { setSchedules([...schedules, newschedule]); clearBackup(); }, 'Saved')
                            : timersequence(() => { }, 'Failed to update.')
                    })
                    .catch(() => {
                        return timersequence(() => { }, 'Failed to update.')
                    })

            }}

            retrieveitineraries={() => {
                return axios({
                    method: 'POST',
                    url: INVITATION_RETRIEVEITINERARIES,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, // need token from the web
                    data: { urlhandler: urlhandler, u_id: u_id, }
                }).then((item) => {
                    const { message, tls } = item.data
                    if (message === 'SUCCESS') {
                        return new Promise((resolve) => {
                            resolve(tls)
                        });
                    }
                }).catch((e) => {
                    return timersequence(() => { }, 'Failed to update.')
                })
            }}

            // dragSaveschedule={(newschedules, oldschedules) => {
            dragSaveschedule={(newschedules) => {
                setPopupmsg('Saving...')
                return axios({
                    method: 'POST',
                    url: INVITATION_SCHEDULESWAP,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, // need token from the web
                    data: { urlhandler: urlhandler, u_id: u_id, invitationid: _id, newschedules: newschedules, schedules: backupdata['schedules'], }
                }).then((item) => {
                    const { message } = item.data
                    return message === 'SUCCESS'
                        ? timersequence(() => { setSchedules(newschedules); clearBackup(); }, 'Saved')
                        : timersequence(() => { }, 'Failed')
                }).catch((e) => {
                    return timersequence(() => { }, 'Failed to update.')
                })
            }}
            rmvSchedule={(deleteindex) => { //  remove a single schedule,  do not have backup schedule
                let newschedules = schedules.slice()
                newschedules.splice(deleteindex, 1)
                setPopupmsg('Saving...')
                return axios({
                    method: 'POST',
                    url: INVITATION_SCHEDULERMV,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, // need token from the web
                    data: { urlhandler: urlhandler, u_id: u_id, invitationid: _id, newschedules: newschedules, schedules: schedules }
                }).then((item) => {
                    const { message } = item.data
                    return message === 'SUCCESS'
                        ? timersequence(() => setSchedules(newschedules), 'Saved')
                        : timersequence(() => { }, 'Failed')
                }).catch((e) => {
                    return timersequence(() => { }, 'Failed to update.')
                })
            }}
            editSchedule={(newschedules) => {
                setPopupmsg('Saving...')
                return axios({
                    method: 'POST',
                    url: INVITATION_SCHEDULEEDIT,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, // need token from the web
                    data: { urlhandler: urlhandler, u_id: u_id, invitationid: _id, newschedules: newschedules, schedules: backupdata['schedules'], }
                }).then((item) => {
                    const { message } = item.data
                    return message === 'SUCCESS'
                        ? timersequence(() => { setSchedules(newschedules.slice()); clearBackup(); }, 'Saved')
                        : timersequence(() => { }, 'Failed')
                }).catch((e) => {
                    return timersequence(() => { }, 'Failed to update.')
                })
            }}

            // datetime
            datetimeff={datetimeff}
            updateDatetimefftier={() => { // tier = priff || secff
                setSpecificcompff_map((smap) => {
                    const tier = smap['datetime'] === 'priff' ? 'secff' : 'priff'
                    smap['datetime'] = tier
                    setDatetimeff(tier === 'priff' ? priff : secff)
                    return smap
                })
                setEditedsignal(true)
            }}

            // location
            locationff={locationff}
            updateLocationfftier={() => {
                setSpecificcompff_map((smap) => {
                    console.log('smap', smap)
                    const tier = smap['location'] === 'priff' ? 'secff' : 'priff'
                    smap['location'] = tier
                    setLocationff(tier === 'priff' ? priff : secff)
                    return smap
                })
                setEditedsignal(true)
            }}

            // notification
            notiff={notiff}
            updateNotifftier={() => {
                setSpecificcompff_map((smap) => {
                    const tier = smap['noti'] === 'priff' ? 'secff' : 'priff'
                    smap['noti'] = tier
                    setNotiff(tier === 'priff' ? priff : secff)
                    return smap
                })
                setEditedsignal(true)
            }}

            // fonts
            fc={fc}
            testingword={
                (itype === 'House-Warming' && titletext && titletext.length > 0)
                    ? titletext
                    : itype !== 'House-Warming' && name && name.length > 0
                        ? name
                        : STARTUP_NAME
            }
            updatePriff={(ff) => {
                setPriff(ff);
                var new_ffobj = JSON.parse(JSON.stringify(ffobj))
                new_ffobj['priff'] = ff
                setLocationff(new_ffobj[specificcompff_map['location']])
                setBtmbarff(new_ffobj[specificcompff_map['btmbar']])
                setDatetimeff(new_ffobj[specificcompff_map['datetime']])
                setFfobj(new_ffobj)
                setEditedsignal(true)
            }}
            updateSecff={(ff) => {
                setSecff(ff);
                var new_ffobj = JSON.parse(JSON.stringify(ffobj))
                new_ffobj['secff'] = ff
                setLocationff(new_ffobj[specificcompff_map['location']])
                setBtmbarff(new_ffobj[specificcompff_map['btmbar']])
                setDatetimeff(new_ffobj[specificcompff_map['datetime']])
                setFfobj(new_ffobj)
                setEditedsignal(true)
            }}

            // questions 
            updateQnas={(q) => { setQnas(q); setEditedsignal(true) }}
            saveQnas={async (newqnas, action) => {
                var orgdata = (action === 'add' || action === 'remove') ? qnas : backupdata['qnas']
                setPopupmsg('Saving...')
                return axios({
                    method: 'POST',
                    url: INVITATION_DQNA,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                    data: { urlhandler, _id, u_id, passcode, newqnas, qnas: orgdata }
                }).then((item) => {
                    const { message } = item.data
                    return message === 'SUCCESS'
                        ? timersequence(() => { setQnas(newqnas); clearBackup(); }, 'Saved')
                        : timersequence(() => { }, 'Failed to update.')
                }).catch((e) => {
                    timersequence(() => { }, 'Failed to update.')
                })
            }}

            // sequence
            saveDisplayseq={() => {
                setPopupmsg('Saving...')
                return axios({
                    method: 'POST',
                    url: INVITATION_DSEQUENCE,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                    data: {
                        urlhandler, _id, u_id, passcode,
                        displayseq: backupdata['displayseq'], newdisplayseq: displayseq,
                        displayonoffmap: backupdata['displayonoffmap'], newdisplayonoffmap: displayonoffmap,
                        displaytxtmap: backupdata['displaytxtmap'], newdisplaytxtmap: displaytxtmap,
                    }
                }).then((item) => {
                    const { message } = item.data
                    return message === 'SUCCESS'
                        ? timersequence(() => { setDisplayseq(displayseq); setDisplayonoffmap(displayonoffmap); setDisplaytxtmap(displaytxtmap); clearBackup(); }, 'Saved')
                        : timersequence(() => { }, 'Failed to update.')
                }).catch((e) => {
                    timersequence(() => { }, 'Failed to update.')
                })
            }}
            updateDisplaytxtmap={(key, val) => {
                let newdisplaytxtmap = JSON.parse(JSON.stringify(displaytxtmap))
                newdisplaytxtmap[key] = val
                setDisplaytxtmap(newdisplaytxtmap)
            }}
            updateDisplayonoffmap={(key) => { // BRAVE
                let newdisplayonoffmap = JSON.parse(JSON.stringify(displayonoffmap))
                newdisplayonoffmap[key] = !newdisplayonoffmap[key]
                setDisplayonoffmap(newdisplayonoffmap)
            }}


            // contacts
            saveContacts={(newcontacts, action) => {
                var orgdata = (action === 'add' || action === 'remove') ? contacts : backupdata['contacts']
                setPopupmsg('Saving...')
                return axios({
                    method: 'POST',
                    url: INVITATION_DCONTACTS,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                    data: { urlhandler, _id, u_id, passcode, newcontacts, contacts: orgdata }
                }).then((item) => {
                    const { message } = item.data
                    return message === 'SUCCESS'
                        ? timersequence(() => { setContacts(newcontacts); clearBackup(); }, 'Saved')
                        : timersequence(() => { }, 'Failed to update.')
                }).catch((e) => {
                    timersequence(() => { }, 'Failed to update.')
                })
            }}

            // promote
            promote={promote}
            updatePromote={(e) => { setPromote(e); setEditedsignal(true) }}
            savePromote={(newpromote, action) => {
                var orgdata = (action === 'add' || action === 'remove') ? promote : backupdata['promote']
                setPopupmsg('Saving...')
                return axios({
                    method: 'POST',
                    url: INVITATION_DPROMOTE,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                    data: { urlhandler, _id, u_id, passcode, newpromote, promote: orgdata }
                }).then((item) => {
                    const { message } = item.data
                    return message === 'SUCCESS'
                        ? timersequence(() => { setPromote(newpromote); clearBackup(); }, 'Saved')
                        : timersequence(() => { }, 'Failed to update.')
                }).catch((e) => {
                    timersequence(() => { }, 'Failed to update.')
                })
            }}

            // registry
            registry={registry}
            updateRegistry={(e) => { setRegistry(e); setEditedsignal(true) }}
            saveRegistry={async (newregistry, action) => {
                var orgdata = (action === 'add' || action === 'remove') ? registry : backupdata['registry']
                setPopupmsg('Saving...')
                return axios({
                    method: 'POST',
                    url: INVITATION_DREGISTRY,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                    data: { urlhandler, _id, u_id, passcode, newregistry, registry: orgdata }
                }).then((item) => {
                    const { message } = item.data
                    return message === 'SUCCESS'
                        ? timersequence(() => { setRegistry(newregistry); clearBackup(); }, 'Saved')
                        : timersequence(() => { }, 'Failed to update.')
                }).catch((e) => {
                    timersequence(() => { }, 'Failed to update.')
                })
            }}

            // openings
            openingnames={openingnames} openingobjs={openingobjs} openingclosing={openingclosing} openingstoryobjs={openingstoryobjs} openingtype={openingtype} animationobjs={animationobjs} animationtype={animationtype}
            updateOpeningnames={(n) => { setOpeningnames(n); setEditedsignal(true) }} // store names for Convo
            updateOpeningobjs={(o) => { setOpeningobjs(o); setEditedsignal(true) }} // update for Convo obj only
            updateOpeningstoryobjs={(o) => { setOpeningstoryobjs(o); setEditedsignal(true) }} // update for Story obj only
            updateOpeningtype={(t) => { setOpeningtype(t); setEditedsignal(true) }} // Convo or Story
            updateAnimationobjs={(a) => { setAnimationobjs(a); setEditedsignal(true) }}
            updateAnimationtype={(t) => { setAnimationtype(t); setEditedsignal(true) }}
            updateOpeningclosing={(o) => { setOpeningclosing(o); setEditedsignal(true) }} // closing of opening
        />,
        'Templates': template_dom,
        'Gallery': <Images
            clearBackup={() => clearBackup()}
            restoreBackup={restoreGallerybackup}
            updateBackup={updateGallerybackup}
            updateSelectedsubpage={(subpage) => setSelectedsubpage(subpage)}
            updateSubpageback={() => {
                Object.keys(backupdata).length > 0 ? setSaveprompt(true) : setSelectedsubpage('')
            }}
            updateSubpageexit={() => setSubpageexit(false)}
            subpageexit={subpageexit}
            selectedsubpage={selectedsubpage}

            images={images}
            urlhandler={urlhandler}
            gallery_quota={gallery_quota}
            invitation_id={_id}
            imgcss_map={imgcss_map}
            svgoverlay_map={svgoverlay_map}

            updateImages={(imgs) => { setImages(imgs) }}
            updateImagecssmap={(newimagecss_map) => { setImagecss_map(JSON.parse(JSON.stringify(newimagecss_map))) }}
            updateSvgoverlaymap={(newsvgoverlay_map) => { setSvgoverlay_map(JSON.parse(JSON.stringify(newsvgoverlay_map))) }}
            saveSwapimages={(images) => {
                setPopupmsg('Saving...')
                return axios({
                    method: 'POST',
                    url: INVITATION_SAVEIMGSWAP,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                    data: { urlhandler, _id, u_id, images, passcode }
                }).then((item) => {
                    const { message } = item.data
                    return message === 'SUCCESS'
                        ? timersequence(() => { setImages(images); clearBackup(); }, 'Saved')
                        : timersequence(() => { }, 'Failed')
                }).catch(() => {
                    return timersequence(() => { }, 'Failed to update.')
                })
            }}
            delSingleimage={(thumbnailimg, fullsizeimg) => {
                setPopupmsg('Saving...')
                var new_images = images.slice()
                const fullsizeimgindex = images.indexOf(fullsizeimg)
                new_images.splice(fullsizeimgindex, 1)
                new_images.splice(new_images.indexOf(thumbnailimg), 1)
                let isremovingfirstimg = fullsizeimgindex === 0
                return axios({
                    method: 'POST',
                    url: INVITATION_RMVIMG,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, // need token from the web
                    data: { urlhandler: urlhandler, u_id: u_id, invitationid: _id, 'imgurl': fullsizeimg, 'thumbnail': thumbnailimg, 'urlhandler': urlhandler, 'images_seq': images, 'new_images_seq': new_images, firstimg: isremovingfirstimg }
                }).then((item) => {
                    const { message, replacedimg } = item.data
                    return message === 'SUCCESS'
                        ? timersequence(() => { new_images = [...replacedimg, ...new_images]; setImages(new_images); }, 'Saved')
                        : timersequence(() => { }, 'Failed to update.')
                }).catch(() => {
                    timersequence(() => { }, 'Failed to update.')
                })
            }}
            saveMainimgsprop={() => {
                setPopupmsg('Saving...')
                return axios({
                    method: 'POST',
                    url: INVITATION_SAVEMAINIMGPROP,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                    data: { urlhandler, _id, u_id, passcode, imgcss_map, svgoverlay_map }
                }).then((item) => {
                    const { message } = item.data
                    return message === 'SUCCESS'
                        ? timersequence(() => { setImagecss_map(JSON.parse(JSON.stringify(imgcss_map))); setSvgoverlay_map(JSON.parse(JSON.stringify(svgoverlay_map))); }, 'Saved')
                        : timersequence(() => { }, 'Failed to update.')
                }).catch((e) => {
                    timersequence(() => { }, 'Failed to update.')
                })
            }}
        />,
        'Background': <Background
            clearBackup={clearBackup}
            updateBackup={updateBackgroundbackup}
            restoreBackup={restoreBackgroundbackup}
            updateSubpageback={() => {
                Object.keys(backupdata).length > 0 ? setSaveprompt(true) : setSelectedsubpage('')
            }}
            updateSelectedsubpage={(subpage) => setSelectedsubpage(subpage)}
            updateSubpageexit={() => setSubpageexit(false)}
            save={() => {

                const url = {
                    'Design': INVITATION_SAVEBACKGROUND,
                    'Frame': INVITATION_SAVEBORDER,
                    'Banners': INVITATION_SAVEBANNER,
                    'Colors': INVITATION_SAVEBGCOLOR
                }[selectedsubpage]

                let data = { urlhandler, _id, u_id, passcode, }
                if (selectedsubpage === 'Design') {
                    data = { ...data, sep, styleformation, styleformationmobile, styleorientation, styleorientationmobile, styletype }
                }
                else if (selectedsubpage === 'Frame') {
                    data = { ...data, styleborderwt, styleborderbr, stylebordercolor, styleborder }
                }
                else if (selectedsubpage === 'Banners') {
                    data = { ...data, sep }
                }
                else if (selectedsubpage === 'Colors') {
                    data = { ...data, templatecolor, templatebgcolor, textures }
                }

                setPopupmsg('Saving...')
                return axios({
                    method: 'POST',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                    data: data
                }).then((item) => {
                    const { message } = item.data
                    return message === 'SUCCESS'
                        ? timersequence(() => { }, 'Saved')
                        : timersequence(() => { }, 'Failed to update.')
                }).catch((e) => {
                    timersequence(() => { }, 'Failed to update.')
                })
            }}
            subpageexit={subpageexit}
            selectedsubpage={selectedsubpage}

            // selectedStyletype={styletype}
            selectedStyletype={styletype}
            itype={itype}
            templatetype={templatetype}
            selectedStyleformation={styleformation}
            selectedStyleformationmobile={styleformationmobile}
            selectedStyleorientation={styleorientation}
            selectedStyleorientationmobile={styleorientationmobile}
            selectedStyleborder={styleborder}

            wt={wt * 0.45} // tablet and web screen proportion 0.55 | 0.45

            updateStyleAndBanner={(styletype, banner) => {
                setStyletype(styletype);
                setSep(banner)
                setEditedsignal(true)

                // update to initial state
                setStyleformation(0);
                setStyleorientation(0);
                setStyleformationmobile(0);
                setStyleorientationmobile(0);
            }}
            updateStyleformation={(val) => { setStyleformation(val); setEditedsignal(true) }}
            updateStyleformationmobile={(val) => { setStyleformationmobile(val); setEditedsignal(true) }}
            updateStyleorientation={(val) => { setStyleorientation(val); setEditedsignal(true) }}
            updateStyleorientationmobile={(val) => { setStyleorientationmobile(val); setEditedsignal(true) }}
            updateStyleborder={(val) => { setStyleborder(val); setEditedsignal(true) }}

            // banner
            sep={sep}
            updateSep={(sep) => { setSep(sep); setEditedsignal(true) }}

            // frame aka border
            styleborderwt={styleborderwt} updateStyleborderwt={(val) => { setStyleborderwt(val); setEditedsignal(true) }}
            styleborderbr={styleborderbr} updateStyleborderbr={(val) => { setStyleborderbr(val); setEditedsignal(true) }}
            stylebordercolor={stylebordercolor} updateStylebordercolor={(val) => { setStylebordercolor(val); setEditedsignal(true) }}

            // color and template
            updateTemplatecolor={(color) => { setTemplatecolor(color); setEditedsignal(true) }}
            updateTemplatebgcolor={(color) => { setTemplatebgcolor(color); setEditedsignal(true) }}
            updateTextures={(ts, position) => {
                setTextures((textures) => {
                    var newts = textures.slice()
                    newts[position] = ts
                    return newts
                });
                setEditedsignal(true)
            }}
            selectedTemplatecolor={templatecolor}
            selectedTemplatebgcolor={templatebgcolor}
            selectedTemplate={templatetype}
            textures={textures}
        />,
        'Navigation': <Navigation
            clearBackup={clearBackup}
            restoreBackup={restoreNavbackup}
            updateBackup={updateNavbackup}

            fc={fc}
            wt={wt}
            templatecolor={templatecolor}
            menutype={menutype}
            updateMenutype={(mt) => { setMenutype(mt); setEditedsignal(true); }}
            menuicongate={menuicongate}
            updateMenuicongate={(mg) => { setMenuicongate(mg); setEditedsignal(true); }}
            menustyle={JSON.parse(JSON.stringify(menustyle))}
            menustyledited={menustyledited}
            updateMenustyle={(ms) => { setMenustyle(JSON.parse(JSON.stringify(ms))); setMenustyleedited(menustyledited + 1); setEditedsignal(true); }}
            menuitems={JSON.parse(JSON.stringify(menuitems))}
            token={token}
            updateMenuitems={(mi) => { setMenuitems(JSON.parse(JSON.stringify(mi))); setMenuitemsedited(!menuitemsedited); setEditedsignal(true); }}
            menuitemsedited={menuitemsedited}
            menuseq={menuseq}
            updateMenuseq={(newmenuseq) => { setMenuseq(newmenuseq); setEditedsignal(true); setMenuseqedited(!menuseqedited); }}
            menuseqedited={menuseqedited}
            updateMenumore={(newmenumore) => { setMenumore(newmenumore); setEditedsignal(true); }}
            menumore={menumore}
            priff={priff}
            secff={secff}
            btmbarff={btmbarff}
            specificcompff_map={specificcompff_map}
            updateSpecificcompff={() => {
                setSpecificcompff_map((old_smap) => {
                    let smap = JSON.parse(JSON.stringify(old_smap))
                    const tier = smap['btmbar'] === 'priff' ? 'secff' : 'priff'
                    smap['btmbar'] = tier
                    setBtmbarff(tier === 'priff' ? priff : secff)
                    return smap
                })
                setEditedsignal(true)
            }}
            onSave={() => {
                setPopupmsg('Saving...')
                return axios({
                    method: 'POST',
                    url: INVITATION_SAVENAV,
                    headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' }, //need token from the web
                    data: { urlhandler, _id, u_id, passcode, menutype, menustyle, menuitems, menuseq, menumore, menuicongate}
                }).then((item) => {
                    const { message } = item.data
                    if (message === 'SUCCESS') {
                        timersequence(() => { clearBackup() }, 'Saved')
                    }
                    else { timersequence(() => { }, 'Failed') }
                    return message
                }).catch((e) => { timersequence(() => { }, 'Failed to update.') })

            }}
        />,
        'QR Code': <QRcodediv
            url={urlhandler}
            name={name}
            titletext={titletext}
            spousename={spousename}
            babyname={babyname}
            date_e={date_e}
            date_w={date_w}
            dt_type={dt_type}
            itype={itype}
            passcode={passcode}
            bannerdesign={banners.indexOf(sep)}
            device={device}
        />

        // 'Alt Links': <Alticlinks updateAlticlinks={(al) => { setAlticlinks(al); setEditedsignal(true) }} alticlinks={alticlinks} />,

        // 'Border': <Border
        //     wt={wt * 0.45}
        //     styleborder={styleborder} updateStyleborder={(val) => { setStyleborder(val); setEditedsignal(true) }}
        //     styleborderwt={styleborderwt} updateStyleborderwt={(val) => { setStyleborderwt(val); setEditedsignal(true) }}
        //     styleborderbr={styleborderbr} updateStyleborderbr={(val) => { setStyleborderbr(val); setEditedsignal(true) }}
        //     stylebordercolor={stylebordercolor} updateStylebordercolor={(val) => { setStylebordercolor(val); setEditedsignal(true) }}
        // />,

        // 'Schedules': <Schedules updateSchedules={(s) => { setSchedules(s); setEditedsignal(true) }} schedules={schedules} />,
        // 'Openings': <Openings
        //     openingnames={openingnames} openingobjs={openingobjs} openingclosing={openingclosing} openingstoryobjs={openingstoryobjs} openingtype={openingtype} animationobjs={animationobjs} animationtype={animationtype}
        //     updateOpeningnames={(n) => { setOpeningnames(n); setEditedsignal(true) }} // store names for Convo
        //     updateOpeningobjs={(o) => { setOpeningobjs(o); setEditedsignal(true) }} // update for Convo obj only
        //     updateOpeningstoryobjs={(o) => { setOpeningstoryobjs(o); setEditedsignal(true) }} // update for Story obj only
        //     updateOpeningtype={(t) => { setOpeningtype(t); setEditedsignal(true) }} // Convo or Story
        //     updateAnimationobjs={(a) => { setAnimationobjs(a); setEditedsignal(true) }}
        //     updateAnimationtype={(t) => { setAnimationtype(t); setEditedsignal(true) }}
        //     updateOpeningclosing={(o) => { setOpeningclosing(o); setEditedsignal(true) }} // closing of opening
        // />,
    }

    const simulator_dom = (
        <Simulator
            devicewt={wt}
            firsttimeenter={firsttimeenter}
            displayseq={displayseq}
            displayonoffmap={displayonoffmap}
            displaytxtmap={displaytxtmap}
            templatetype={templatetype} // template type
            templatecolor={templatecolor}
            templatebgcolor={templatebgcolor}
            textures={textures}
            styletype={styletype} // floral etc
            styleformation={styleformation}
            styleformationmobile={styleformationmobile}
            styleorientation={styleorientation}
            styleorientationmobile={styleorientationmobile}
            styleborder={styleborder}
            styleborderwt={styleborderwt}
            styleborderbr={styleborderbr}
            stylebordercolor={stylebordercolor}
            stylesep={sep}
            itype={itype} // invitation type
            css_map={css_map} // css of maintext and subtext
            priff={priff}
            secff={secff}
            locationff={locationff}
            datetimeff={datetimeff}
            titletext={titletext} // use for itype= Birthday party, Housewarming and baby shwoer
            ntw={ntw}
            ntt={ntt}
            ntm={ntm}
            naw={naw}
            nat={nat}
            nam={nam}
            name={name} spousename={spousename} babyname={babyname}  // all the names    
            maintext={maintext}
            subtext={subtext}
            date_w={date_w}
            date_e={date_e}
            dt_type={dt_type}
            location={location}
            mapmode={mapmode}
            time={time}
            fc={fc}
            lat={lat} long={long}
            images={images}
            qnas={qnas}
            schedules={schedules}
            urlhandler={urlhandler}
            locurl={locurl}
            locdescript={locdescript}
            whowwemet={whowwemet}
            wproposal={wproposal}
            rsvpactive={rsvpactive}
            socialmedias={socialmedias}
            contacts={contacts}
            promote={promote}
            menutype={menutype} // for navigation btm bar
            menuseq={menuseq} // for navigation btm bar
            btmbarff={btmbarff} // for navigation btm bar
            menustyle={menustyle} // for navigation btm bar style
            menustyledited={menustyledited} // for navigation btm bar style
            imgcss_map={imgcss_map}
            svgoverlay_map={svgoverlay_map}
            menuicongate={menuicongate}
        />
    )

    const portalmidcss = { width: { 'WEB': 500, 'TABLET': 450, 'MOBILE': '100%' }[device], height: { 'WEB': 500, 'TABLET': 400, 'MOBILE': '100%' }[device], backgroundColor: 'white', top: '50%', left: '50%', right: '', transform: 'translate(-50%,-50%)' }

    const sendmodal = <Portalmodal
        portaltype={'commonportal'}
        open={showsend}
        onClose={() => { setShowsend(false) }}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={portalmidcss}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'>
        <div className={device === 'MOBILE' ? 'SearchmodalM' : 'Searchmodal'} >
            <div className="inviteedit_helproot">
                <h5 className="inviteedit_helptitle" >Send</h5>
                <p className="ie_modaltext" style={{ marginBottom: 15 }}>Before you send your invitation, please ensure that your guest list is filled up with guests.</p>
                <p className="ie_modaltext">
                    <div className='ie_num'>1</div>
                    <div>Start by adding the guest to the guestlist.</div>
                </p>
                <p className="ie_modaltext">
                    <div className='ie_num'>2</div>
                    <div>Proceed to the guest list. Each guest will have a "Contact/Send" column. Click on the preferred methods you would like to use to send your invitation.</div>
                </p>
                <p className="ie_modaltext">
                    <div className='ie_num'>3</div>
                    <div>After successfully sending your invite,  a popup will appear and you will need to assign a status to the guest's "Contact/Send" cell.</div>
                </p>
            </div>
        </div>
    </Portalmodal>

    const helpmodal = <Portalmodal
        portaltype={'commonportal'}
        open={help}
        onClose={() => { setHelp(false) }}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={portalmidcss}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'>
        <div className={device === 'MOBILE' ? 'SearchmodalM' : 'Searchmodal'} >
            <div className="inviteedit_helproot">
                <div className="inviteedit_helptitle" >Invitation Editor</div>
                <div className="ie_modaltext">
                    <div className='ie_num'>1</div>
                    <div>Start by selecting a template based on your preference.</div>
                </div>
                <div className="ie_modaltext">
                    <div className='ie_num'>2</div>
                    <div>Upload image.</div>
                </div>
                <div className="ie_modaltext">
                    <div className='ie_num'>3</div>
                    <div>Fill up all the necessary details, such as Names, Maintext etc.</div>
                </div>
                <div className="ie_modaltext">
                    <div className='ie_num'>4</div>
                    <div>Create a time schedule for your event.</div>
                </div>
                <div className="ie_modaltext">
                    <div className='ie_num'>5</div>
                    <div>Click on Preview to view your Wedding/Event website.</div>
                </div>
                {EMPTYSPACE}
                <div className="inviteedit_helptitle">Guest List</div>
                <div className="ie_modaltext">
                    <div className='ie_num'>1</div>
                    <div>You can upload .csv file or add the guests manually to the guest list.</div>
                </div>
                <div className="ie_modaltext">
                    <div className='ie_num'>2</div>
                    <div>Click "Publish" to make RSVP option visible on your invitation card.</div>
                </div>
                <div className="ie_modaltext">
                    <div className='ie_num'>3</div>
                    <div>Click on Preview to view your Wedding/Event website.</div>
                </div>
            </div>
        </div>
    </Portalmodal>

    const SELECTION_BAR_WEBANDTABLET_DIV = <div style={{ width: '100%', borderBottom: '1px solid #dddddd', padding: 10, flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', zIndex: 1040 }}>
        <div style={{ display: 'flex', flexDirection: 'row', fontSize: e_fs, alignItems: 'center', pointerEvents: 'none', userSelect: 'none', opacity: 0 }}>
            {!editororguest === 0 ? invisiblesavebtn : null}
            {sendbtn}
            {editororguest === 0 ? savebtn : null}
        </div>
        <button className="planning_backicon" style={{ position: 'absolute', left: 10, }} onClick={() => { setReturnhome(true) }}>{ARROWLEFT_ICON}</button>
        {guestoreditorbtn}
        <div style={{ display: 'flex', flexDirection: 'row', fontSize: e_fs, alignItems: 'center' }}>
            {!editororguest === 0 ? invisiblesavebtn : null}
            {sendbtn}
            {editororguest === 0 ? savebtn : null}
        </div>
        <div className="ie_previewbtn_root" >
            {editororguest === 0 && !firsttimeenter ? previewbtn() : null}
        </div>
        {returnhome
            ? <Modcfm
                onHide={() => { setReturnhome(false) }}
                onOk={() => { props.history.push(PLOBJ['invitation']['toolurl']) }}
                onNo={() => { setReturnhome(false) }}
                txt={'You are returning to planning tools menu.'}
            /> : null}
    </div>

    const MENU = (
        <div className={"Iedittopbarleftmenubtn_root" + device} style={{ width: (device === 'WEB' || device === 'TABLET') ? wt * 0.45 : '100%' }} >
            {menutitles.map((page, index) => {
                return <button
                    key={index + 'menutitles'}
                    className={`${selectedpage === page ? 'Iedittopbarbtnselected' : 'Iedittopbarbtn'}`}
                    style={{
                        borderLeft: index === 0 ? '1px solid #dddddd' : 'none',
                        width: page === 'QR Code' ? 'max-content' : 90,
                        maxWidth: page === 'QR Code' ? 'max-content' : 90,
                        minWidth: page === 'QR Code' ? 'max-content' : 90,
                    }}
                    onClick={() => {

                        if (page === selectedpage) return

                        if (page !== 'Navigation' && selectedpage === 'Navigation' && Object.keys(backupdata).length > 0) {
                            // user trying to leave and have made changes to data, so required to restore in saveprompt - saveprompt_dom
                            setSaveprompt(true)
                            setProceedingpage(page)
                        }
                        else if (page !== 'Gallery' && selectedpage === 'Gallery' && Object.keys(backupdata).length > 0) {
                            setSaveprompt(true)
                            setProceedingpage(page)
                        }
                        else if (page !== 'Templates' && selectedpage === 'Templates' && Object.keys(backupdata).length > 0) {
                            setSaveprompt(true)
                            setProceedingpage(page)
                        }
                        else if (page !== 'Background' && selectedpage === 'Background' && Object.keys(backupdata).length > 0) {
                            setSaveprompt(true)
                            setProceedingpage(page)
                        }
                        else if (page !== 'Details' && selectedpage === 'Details' && Object.keys(backupdata).length > 0) {
                            setSaveprompt(true)
                            setProceedingpage(page)
                        }
                        else if (page !== 'Gallery' && selectedpage === 'Gallery' && Object.keys(backupdata).length > 0) {
                            setSaveprompt(true)
                            setProceedingpage(page)
                        }
                        else {
                            clearBackup()
                            setSelectedpage(page)
                            setSelectedsubpage('')
                        }
                    }}>
                    {page === 'QR Code' ? iconwrapper(PLANNING_QR_ICON, { width: 24, height: 24 }) : page}
                </button>
            })}
        </div>
    )

    const saveprompt_dom = saveprompt // this will activted if user leave page that require save
        ? <Modcfm
            onHide={() => setSaveprompt(false)}
            onOk={() => {// ok with discarding data

                // 3 type of saveprompt
                // 1 from 'Cancel' or 'Discard' 
                // 2 clicking to exit from a subpage, from Highlighttitle 
                // 3 clicking to 'Guest List'

                const proceeding_to_guestlist = proceedingpage === 'Guest List'

                // restoration backup
                if (selectedpage === 'Navigation') {
                    restoreNavbackup()
                }
                else if (selectedpage === 'Gallery') {
                    restoreGallerybackup()
                }
                else if (selectedpage === 'Templates') {
                    restoreTemplatebackup()
                }
                else if (selectedpage === 'Background') {
                    restoreBackgroundbackup()
                }
                else if (selectedpage === 'Details') {
                    restoreDetailsbackup()
                }

                if (proceeding_to_guestlist) { // proceeding to guestlist
                    setEditororguest(1)
                    props.history.push('/tools/invitationbuilder/' + _id + '/edit/gl')
                }
                else { // proceeding to other page
                    if (proceedingpage !== '') {
                        setSelectedpage(proceedingpage)
                    }
                    else {  // is a subpage switching
                        if (selectedpage === 'Background' && (selectedsubpage === 'Design' || selectedsubpage === 'Frame' || selectedsubpage === 'Banners' || selectedsubpage === 'Colors')) {
                            setSubpageexit(true)
                        }
                        else if (selectedpage === 'Details' && (selectedsubpage === 'Names' || selectedsubpage === 'Fonts' || selectedsubpage === 'Our Journey' || selectedsubpage === 'Location' || selectedsubpage === 'Date Time' || selectedsubpage === 'Q&A' || selectedsubpage === 'Contacts' || selectedsubpage === 'Promote' || selectedsubpage === 'Notification' || selectedsubpage === 'Share' || selectedsubpage === 'Schedules' || selectedsubpage === 'Openings' || selectedsubpage === 'Registry')) {
                            setSubpageexit(true)
                        }
                        else if (selectedpage === 'Gallery' && (selectedpage === '' || selectedpage === 1 || selectedpage === 2)) {
                            setSubpageexit(true)
                        }
                    }
                }

                setProceedingpage('')
                setSelectedsubpage('')
                setSaveprompt(false)

            }}
            onNo={() => {
                setSaveprompt(false)
                setProceedingpage('')
            }}
            txt={'By leaving this page, you will lose any changes you have made.'}
        />
        : null


    const editbtn_dom = <div className='editbtn' style={{ position: 'absolute', top: '90%', left: '50%' }}>
        <button
            onClick={() => {
                const data = {
                    ntm,
                    ntt,
                    ntw,

                    ffobj,

                    styleborder,
                    stylebordercolor,
                    styleborderbr,
                    styleborderwt,

                    sep,
                    styletype,
                    styleorientationmobile,
                    styleformationmobile,
                    styleorientation,
                    styleformation,

                    menutype
                }
                navigator.clipboard.writeText(JSON.stringify(data)).then(() => { }).catch(err => { });
            }}
        >
            data
        </button>
        <button
            onClick={() => {

                const templatecolors_data_map = {}
                templatecolors_data_map[templatecolor] = {
                    menustyle,
                    templatecolor,
                    fc
                }

                navigator.clipboard.writeText(JSON.stringify(templatecolors_data_map)).then(() => { }).catch(err => { });

            }}
        >
            templatecolors_data_map
        </button>
    </div>

    const DEVICE_DOM_MAP = {
        'WEB': (
            <div className="Iedit_root" style={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '100vw', height: ht, position: 'fixed', top: 0, zIndex: 1, fontFamily: 'Lato' }}>
                {SELECTION_BAR_WEBANDTABLET_DIV}
                {editororguest === 0
                    ? firsttimeenter
                        ? <div className="Iedittopbar_root">
                            <div className="Iedittopbarleft_root" style={{ ...leftStyle, height: ht, }}> {/* wt : 45% */}
                                {template_dom}
                            </div>
                            <div className="Iedittopbarright_root" style={{ display: 'flex', flexDirection: 'column', width: '55%', alignItems: 'center', height: 'calc( 100% - 60px )' }}> {/* wt : 55% */}
                                <div style={{ fontSize: 16, placeContent: 'center', height: '100%', alignItems: 'center', display: 'flex', textAlign: 'center', padding: 20, flexDirection: 'column' }}>
                                    <div style={{ paddingBottom: 5 }}>Start by selecting a pre-built template, then confirm your choice by clicking save.</div>
                                    <div>Rest assured that you can always modify your template selection at a later time if needed.</div>
                                </div>
                            </div>
                        </div>
                        : <div className="Iedittopbar_root">
                            <div className="Iedittopbarleft_root" style={{ ...leftStyle, height: ht, }}> {/* wt : 45% */}
                                <div className="Iedittopbarleftmenu_root" style={{ borderBottom: (selectedsubpage && selectedsubpage.length > 0) ? '' : '1px solid #dddddd' }}>
                                    {MENU}
                                </div>
                                {MENU_MAP_DOM[selectedpage]}
                            </div>
                            <div className="Iedittopbarright_root" style={{ display: 'flex', flexDirection: 'column', width: '55%', alignItems: 'center', height: 'calc( 100% - 60px )' }}> {/* wt : 55% */}
                                {simulator_dom}
                            </div>
                        </div>
                    : <Invitationguestlist {...props} _id={_id} updateRsvpactive={(val) => setRsvpactive(val)} />}
                <Noti noti={popupmsg} />
                {helpmodal}
                {sendmodal}
                {saveprompt_dom}
                {templateedit ? editbtn_dom : null}
            </div>
        ),
        'TABLET': (
            <div style={{ ...rootStyle, height: ht }}>
                {SELECTION_BAR_WEBANDTABLET_DIV}
                {editororguest === 0
                    ? firsttimeenter
                        ? <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ ...leftStyle, height: ht, }}> {/* wt : 45% */}
                                {template_dom}
                            </div>
                            <div style={{ ...rightStyle, height: ht - 60, placeContent: 'center' }}> {/* wt : 55% */}
                                <div style={{ fontSize: 15, placeContent: 'center', alignItems: 'center', display: 'flex', textAlign: 'center', padding: '0pc 20px', flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', textAlign: 'start', paddingBottom: 5 }}>Start by selecting a pre-built template, then confirm your choice by clicking save.</div>
                                    <div style={{ display: 'flex', textAlign: 'start' }}>Rest assured that you can always modify your template selection at a later time if needed.</div>
                                </div>
                            </div>
                        </div>
                        : <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ ...leftStyle, height: ht, }}> {/* wt : 45% */}
                                <div style={{ display: 'flex', flexDirection: 'row', overflow: 'hidden', height: 'max-content', minHeight: 70, alignItems: 'center', justifyContent: 'space-between', fontSize: e_fs, borderBottom: 'thin solid rgb(221, 221, 221)' }}>
                                    {MENU}
                                </div>
                                {MENU_MAP_DOM[selectedpage]}
                            </div>
                            <div style={{ ...rightStyle, height: ht - 60 }}> {/* wt : 55% */}
                                {simulator_dom}
                            </div>
                        </div>
                    : <Invitationguestlist {...props} _id={_id} updateRsvpactive={(val) => { setRsvpactive(val) }} />}
                <Noti noti={popupmsg} />
                {helpmodal}
                {sendmodal}
                {saveprompt_dom}
            </div >
        ),
        'MOBILE': (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', fontFamily: 'Lato', overflow: 'hidden', touchAction: 'manipulation', backgroundColor: 'white' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', overflow: 'hidden' }}>
                    <div style={{ width: '100%', padding: '0px 10px', height: 60, borderBottom: '1px solid rgb(221, 221, 221)', flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', minHeight: 'max-content', userSelect: 'none' }}>
                        {leftbtn}
                        <div style={{ display: 'flex', flexDirection: 'row', fontSize: e_fs, alignItems: 'center' }}>
                            {guestoreditorbtn}
                            <div style={{ borderLeft: 'thin solid rgb(193, 193, 193)', margin: '0px 5px' }} />
                            {sendbtn}
                            {previewbtn()}
                        </div>
                    </div>

                    {/* THIS IS USE WHEN "SAVE ALL" BUTTON EXIST */}
                    {/* {editororguest === 0 
                        ? <div className="ie_mse_mobile">
                            {wt > 350 && wt < 450
                                ? <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(85px, max-content))', width: 285 }} >
                                    {menutitles.map((page, index) => {
                                        return <button
                                            key={index + 'menutitles'}
                                            style={{
                                                height: 30,
                                                width: 285 / 3,
                                                fontSize: 14,
                                                textAlign: 'center',
                                                placeContent: 'center',
                                                padding: 0,
                                                margin: 0,

                                                backgroundColor: selectedpage === page ? '#eb5d7b' : 'white',
                                                color: selectedpage === page ? 'white' : 'inherit',

                                                border: '1px solid #dddddd',
                                                borderTop: 'none',
                                                borderBottom: (index === 3 || index === 4 || index === 5) ? 'none' : '1px solid #dddddd',
                                                borderLeft: 'none',
                                                borderRight: (index === 2 || index === 5) ? 'none' : '1px solid #dddddd',
                                            }}
                                            onClick={() => {
                                                setSelectedpage(page)
                                                setSelectedsubpage('')
                                            }}>
                                            {page === 'QR Code' ? QR_NORM : page}
                                        </button>
                                    })}
                                </div>
                                : MENU} */}

                    {editororguest === 0
                        ? !firsttimeenter
                            ? <div className="ie_mse_mobile">
                                {wt > 350 && wt < 450
                                    ? <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(33.33%, max-content))', width: '100%' }} >
                                        {menutitles.map((page, index) => {
                                            return <button
                                                key={index + 'menutitles'}
                                                style={{
                                                    height: 30,
                                                    // width:'calc(  100% / 3 )',
                                                    fontSize: 14,
                                                    textAlign: 'center',
                                                    placeContent: 'center',
                                                    padding: 0,
                                                    margin: 0,
                                                    display: 'flex',
                                                    placeContent: 'center',
                                                    alignItems: 'center',

                                                    backgroundColor: selectedpage === page ? '#eb5d7b' : 'white',
                                                    color: selectedpage === page ? 'white' : 'inherit',

                                                    border: '1px solid #dddddd',
                                                    borderTop: 'none',
                                                    borderBottom: (index === 3 || index === 4 || index === 5) ? 'none' : '1px solid #dddddd',
                                                    borderLeft: 'none',
                                                    borderRight: (index === 2 || index === 5) ? 'none' : '1px solid #dddddd',
                                                }}
                                                onClick={() => {
                                                    setSelectedpage(page)
                                                    setSelectedsubpage('')
                                                }}>
                                                {page === 'QR Code' ? iconwrapper(PLANNING_QR_ICON, { width: 24, height: 24 }) : page}
                                            </button>
                                        })}
                                    </div>
                                    : MENU}

                                {wt > 350 && wt < 450 ?
                                    mobilesavebtn
                                    : savebtn}

                            </div>
                            : null
                        : null}

                    {!firsttimeenter
                        ? <div style={{ overflowY: 'auto' }}>
                            {editororguest === 0 ? MENU_MAP_DOM[selectedpage] : null}
                        </div>
                        : editororguest === 0 ? template_dom : null}

                    {editororguest === 1
                        ? <Invitationguestlist {...props} _id={_id} updateRsvpactive={(val) => { setRsvpactive(val) }} />
                        : null}

                </div>
                <Noti noti={popupmsg} />
                {helpmodal}
                {sendmodal}
                {returnhome
                    ? <Modcfm
                        onHide={() => { setReturnhome(false) }}
                        onOk={() => { props.history.push(PLOBJ['invitation']['toolurl']) }}
                        onNo={() => { setReturnhome(false) }}
                        txt={'You are returning to planning tools menu.'}
                    /> : null}

                {saveprompt_dom}
            </div>
        )
    }

    return firsttime
        ? <Loading ht={'100%'} />
        : DEVICE_DOM_MAP[device]
}

export default Invitationedit