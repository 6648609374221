import React, { useState } from 'react'
import { Checkdevice, Portalmodal, refresh } from '../../../../../../components'
import { EMPTY, COLLECTION_VENDORTYPE, searchgate } from '../../../../../../config/constants'
import { getS3fullpath_withbucketname, iconwrapper, Retrieve_personal_info, str_similarity } from '../../../../../../config/commonfunctions'
import { SEARCH_V, SEARCH_PL, SEARCH_PROJ } from '../../../../../../config/api';
import { Link } from "react-router-dom";
import axios from 'axios';
import './Search.css'
import { SEARCHBOX_ICON } from '../../../../../../config/svgicons';

var Trie = /** @class */ (function () {
    function Trie(value) {
        this.terminal = false;
        this.value = value;
        this.children = new Map();
    }
    Trie.prototype.add = function (c) {
        var val = this.value == null ? c : this.value + c;
        this.children.set(c, new Trie(val));
    };
    Trie.prototype.insert = function (word) {
        var node = this;
        for (var i = 0; i < word.length; i++) {
            var c = word.charAt(i);
            if (!node.children.has(c)) {
                node.add(c);
            }
            node = node.children.get(c);
        }
        node.terminal = true;
    };
    Trie.prototype.autoComplete = function (prefix) {
        var node = this;
        for (var i = 0; i < prefix.length; i++) {
            var c = prefix.charAt(i);
            if (!node.children.has(c)) {
                return [];
            }
            node = node.children.get(c);
        }
        return this.flatten(node.allPrefixes());
    };
    Trie.prototype.allPrefixes = function () {
        var results = [];
        if (this.terminal) {
            results.push(this.value);
        }
        this.children.forEach(function (value, key) {
            var child = value;
            var childPrefixes = child.allPrefixes();
            results = results.concat([childPrefixes]);
        });
        return results;
    };
    Trie.prototype.flatten = function (arr) {
        var flat = [].concat.apply([], arr);
        return flat.some(Array.isArray) ? this.flatten(flat) : flat;
    };
    return Trie;
}());

var trie = new Trie()
for (let i = 0; i < COLLECTION_VENDORTYPE.length; i++) {
    trie.insert(COLLECTION_VENDORTYPE[i].value.toLowerCase())
}

// exceptional vt : 
// 1 makeup -> beauty, hair and makeup
trie.insert('makeup')
trie.insert('hair')
trie.insert('cake')

function Searchdom(props) {
    const { profileimg_id, username, displayname } = props.item
    return (
        <span key={props.username + 'Searchdom'} className="Searchspanroot">
            <img src={getS3fullpath_withbucketname('VENDOR_PROFILE_IMG', profileimg_id.link)} className="SearchImg" alt={EMPTY} />
            <Link to={'/vendorlookup/' + username} target="_blank" className="SearchLink">{displayname}</Link>
        </span>
    )
}

function Search(props) {

    const device = Checkdevice()
    const [text, setText] = useState(EMPTY)
    const [searching, setSearching] = useState(false)
    const [searchmodal, setSearchmodal] = useState(false)
    const [all, setAll] = useState([])
    const [searchdropdown, setSearchdropdown] = useState(false)
    const [searchobj, setSearchobj] = useState([])
    const [searchtype, setSearchtype] = useState('Vendor') // vendor project or pricelist
    const SEARCH_API = { 'Vendor': SEARCH_V, 'Pricelist': SEARCH_PL, 'Project': SEARCH_PROJ }

    const Searchfunc = () => {
        setAll([])
        if (text.length > 1 && text.length < 50) {
            setSearching(true)
            setSearchmodal(true)

            try {
                const { token, acct_type } = Retrieve_personal_info()
                if (acct_type === 'USER') {
                    axios({
                        method: 'POST',
                        url: SEARCH_API[searchtype],
                        headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
                        data: { text: text.toLowerCase() }
                    }).then((result) => {
                        const { status, data } = result
                        console.log('data', data)
                        const { all, error, } = data
                        if (status === 200 && error === false) {
                            document.body.style.overflow = "hidden"
                            if (device === 'MOBILE') {
                                document.getElementById('root').style.height = '0px'
                                document.getElementById('root').style.overflow = 'hidden'
                            }
                            setAll(all)
                        }
                    })
                }
            }
            catch (e) {
            }
        }
        setSearching(false)
    }

    const Textchange = (e) => {
        // vendortype, taggings, 
        const { value } = e.target
        window.scrollTo(0, 0);

        if (value.length === 0) setSearchobj([])
        else {
            const lowered_case_value = value.toLowerCase()

            var gtfullmatch = false
            var new_searchobj = []

            for (let i = 0; i < COLLECTION_VENDORTYPE.length; i++) {
                if (lowered_case_value === COLLECTION_VENDORTYPE[i].value.toLowerCase()) {
                    gtfullmatch = true
                    break
                }
            }

            // exact match
            if (gtfullmatch) {
                new_searchobj.push([lowered_case_value])
            }
            else {
                // some exceptional : eg, makeup -> beauty, hair and makeup

                // string similarity
                for (let i = 0; i < COLLECTION_VENDORTYPE.length; i++) {
                    var cvt = COLLECTION_VENDORTYPE[i].value // current vendortype
                    var score = str_similarity(lowered_case_value, cvt.toLowerCase())
                    if (score > 0.5) new_searchobj.push(cvt.toLowerCase())
                }

                // prefix graph for autocomplete
                var autocomplete_arr = trie.autoComplete(lowered_case_value)
                var setobj = new Set()
                for (let i = 0; i < autocomplete_arr.length; i++) {
                    var curr = autocomplete_arr[i]
                    if (!new_searchobj.includes(curr.toLowerCase())) {

                        if (curr === 'makeup') {
                            new_searchobj.push('beauty, hair and makeup')
                            setobj.add('beauty, hair and makeup')
                        }
                        else if (curr === 'hair') {
                            new_searchobj.push('beauty, hair and makeup')
                            setobj.add('beauty, hair and makeup')
                        }
                        else if (curr === 'cake') {
                            new_searchobj.push('bakery and desserts')
                            setobj.add('bakery and desserts ( cake )')
                        }
                        else {
                            new_searchobj.push(curr)
                            setobj.add(curr)
                        }

                    }
                }
            }
            setSearchobj(Array.from(setobj))
            setSearchdropdown(true)
        }

        setText(e.target.value)
    }

    const Showdropdown = (e) => {

        window.scrollTo(0, 0);

        if (!searchdropdown) {
            setSearchdropdown(true)

            function watchScroll() { window.addEventListener("scroll", logit); }
            watchScroll();

            window.addEventListener('click', function (e) {

                const { className } = e.target
                try {
                    if (className !== 'SearchInput' && className !== 'SearchBtn' && className !== 'Searchdropdown' + device && className !== 'iteminsearchdom') {
                        setSearchdropdown(false) // Clicked in box
                    }
                }
                catch (e) { }

            });

            return () => {
                window.removeEventListener("scroll", logit);
            };

        }
    }

    const Modalclose = () => {
        setSearchmodal(false)
        setSearching(false)
        setAll([])
        document.body.style.overflow = ""
        if (device === 'MOBILE') {
            document.getElementById('root').style.height = ''
            document.getElementById('root').style.overflow = ''
        }
    }

    function logit() {
        if (window.pageYOffset > 0) setSearchdropdown(false)
    }

    const SearchButton_wt = <button key={'searchwt'} className={'SearchBtnWT'} disabled={searching} onClick={Searchfunc}> Search </button>
    const SearchComp_wt = <input key={'swt'} className={'SearchInputWT'} placeholder='Search' onChange={Textchange} onClickCapture={Showdropdown} />
    const SearchButton_m = <button key={'searchm'} className={'SearchBtnM'} disabled={searching} onClick={Searchfunc}> Search </button>
    const SearchComp_m = <input key={'m'} className={'SearchInputM'} onChange={Textchange} onClickCapture={Showdropdown} />
    const combination_map = {
        'WEB': [SearchComp_wt, SearchButton_wt],
        'TABLET': [SearchComp_wt, SearchButton_wt],
        'MOBILE': [SearchComp_m, SearchButton_m]
    }

    const Searchonclickcomp = searchdropdown // drop down componenet
        ? <div className={'Searchdropdown' + device} >
            {searchobj.map((item, index) => {
                return <div
                    className={'iteminsearchdom'}
                    href={window.location.href}
                    key={index}
                    _id={index}
                    onClick={() => {
                        const { usertype, wedorevent } = props

                        if (usertype === 'Wedding' || usertype === 'Event') {
                            props.history.push('/' + usertype + '/' + item.split(',').join('').split(' ').join(''))
                            refresh()
                        }

                        if (wedorevent === 'Wedding' || wedorevent === 'Event') {
                            props.history.push('/' + wedorevent + '/' + item.split(',').join('').split(' ').join(''))
                            refresh()
                        }

                    }}
                >
                    {item}
                </div>
            })}
        </div>
        : null

    const portalmidcss = { width: { 'WEB': 500, 'TABLET': 450, 'MOBILE': '100%' }[device], height: { 'WEB': 500, 'TABLET': 400, 'MOBILE': '100%' }[device], backgroundColor: 'white', top: '50%', left: '50%', right: '', transform: 'translate(-50%,-50%)' }
    const Searchmodal = <Portalmodal
        portaltype={'commonportal'}
        open={searchmodal}
        onClose={Modalclose}
        floatingclose={false}
        backdropvisible={true}
        modalstyle={portalmidcss}
        closestyle={{ right: 0 }}
        floatingclosecolor='#333333'>
        <div className={device === 'MOBILE' ? 'SearchmodalM' : 'Searchmodal'} >
            {all && all.length > 0
                ? all.map((item, index) => { return <Searchdom {...props} item={item} key={index + 'dialog'} /> })
                : <div className="Searchnoresult">No results found.</div>}
        </div>
    </Portalmodal>


    return <div className={"SearchRoot"} >
        {searchgate
            ? <select className={!searchmodal ? 'SearchfilterVisible' : 'SearchfilterInvisible'} value={searchtype} onChange={(e) => { setSearchtype(e.target.value) }} >
                <option>Vendor</option>
                <option>Pricelist</option>
                <option>Project</option>
            </select>
            : null}
        {props.usertype !== 'Guest'
            ? <div className={!searchmodal ? 'SearchVisible' + device : 'SearchInvisible' + device}>
                {iconwrapper(SEARCHBOX_ICON, { width: 35, height: 35, color: 'var(--maindark)', position: 'absolute', display: 'flex', alignItems: 'center', padding: '0px 8px', placeSelf: 'center', placeContent: 'center' })}
                {combination_map[device]}
                {Searchonclickcomp}
            </div>
            : null}
        {Searchmodal}
    </div>


}

export default Search