
import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { ScreenHeight } from "../../components";
import { Retrieve_personal_info } from "../../config/commonfunctions";
import './Scatterplot.css'

const formatNumber = d3.format(".2s");

const Popup = ({ selectiondn, duration, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose(); // Notify the parent component to hide the popup
    }, duration);

    // Cleanup the timer when the component unmounts or when the duration changes
    return () => clearTimeout(timer);
  }, [duration, onClose]);

  return (
    <div className="Scatterplot_popup">
      <div className="Scatterplot_popupitem">
        <div>{selectiondn}</div>
        <div>Added To Selection</div>
      </div>
    </div>
  )
};

const Scatterplotpopup = ({ selectiondn, duration }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
    const timer = setTimeout(() => {
      setVisible(false);
    }, duration);

    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [duration]);

  return (
    visible && (
      <div className="Scatterplot_popup">
        <div className="Scatterplot_popupitem">
          <div>{selectiondn}</div>
          <div>Added To Selection</div>
        </div>
      </div>
      // <div style={styles.popup}>
      //   {message}
      // </div>
    )
  );
};

const TIERARR = [
  { innercolor: '', outercolor: '' }, // nothing
  { innercolor: 'white', outercolor: 'var(--maindark)' }, // bronze
  // { innercolor: '#ffd0da', outercolor: 'var(--mainpink)' }, // bronze
  { innercolor: '#d9d8d8', outercolor: '#b7b2b2' }, // silver
  { innercolor: '#f0e6cf', outercolor: '#d9a833' } // gold
]

const TIER_DIAMOND_POSITION = [
  { y: 2 }, // nothing
  { y: 0 }, // bronze
  { y: -13 }, // silver
  { y: -27 } // gold
]

const WINDOWCSS_MAP = { 'WEB': { bottom: 0, height: 85 }, 'TABLET': { bottom: 0, height: 85 }, 'MOBILE': {} }
const LOWERWINDOWCSS_MAP = { 'WEB': { bottom: 0, height: 55 }, 'TABLET': { bottom: 0, height: 85 }, 'MOBILE': {} }

function Scatterplot(props) {

  const {
    priceviewmode, scatterwt, scatterht, radius, data, selecteddata, scatterkey, touchscreen, device, favourite, leftmain, allorselected, updateScatterportaldata, min, max,
  } = props // data

  const [plotitem, setPlotitem] = useState(0)
  const [currselectedcircle, setCurrselectedcircle] = useState(null)
  const [dnrn, setDnrn] = useState({}) // displayname : review_num, tier

  const scatterplotRef = useRef();
  const lineRef = useRef()
  const fixedxaxisRef = useRef()
  const windowRef = useRef()
  const ht = ScreenHeight()

  const [showPopup, setShowPopup] = useState(false);
  const [selectiondn, setSelectiondn] = useState('')
  const timerRef = useRef(null);

  const { token, _id, acct_type } = Retrieve_personal_info()

  const handleClosePopup = () => {
    setShowPopup(false);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  function showdialog(scatterplotsvg, d, index, xScale, yScale, height, radius) {
    closedialog()

    // show the dialog when mouse over (non-touchscreen) or mouse click (touch-screen)
    // let check_is_it_near_xaxis = false
    // if (index > (data.length / 2)) { check_is_it_near_xaxis = true }
    // let check_is_it_near_xaxis  = index>(data.length/2)

    d3.select(this).attr("r", radius * 2);

    const lineX = xScale(d.x);
    const lineY = yScale(d.y) + yScale.bandwidth() / 2;

    const lineGroup = scatterplotsvg.append('g').attr('class', 'scatterplotline');

    lineGroup.append('line')
      .attr('x1', lineX)
      .attr('y1', lineY)
      .attr('x2', lineX)
      .attr('y2', height)
      .attr('stroke', d.color)
      .attr('stroke-width', 1)
      .attr('stroke-opacity', 0.5);

    lineGroup.append('line')
      .attr('x1', lineX)
      .attr('y1', lineY)
      .attr('x2', 0)
      .attr('y2', lineY)
      .attr('stroke', d.color)
      .attr('stroke-width', 1)
      .attr('stroke-opacity', 0.5);

    lineRef.current = lineGroup;

    // Update tooltip content based on data associated with the hovered circle
    d3.select("#Scatterplot_tooltip")
      .html(() => {
        let capacitystr = ''
        let color_style = ''
        if (priceviewmode === 'ALL_PRICES') {
          color_style = d.mealofday === 'Lunch' ? 'var(--mainpink )' : 'var(--skyblue)'

          for (let i = 0; i < d.capacity[d.x].length; i++) {
            if (i !== d.capacity[d.x].length - 1) {
              capacitystr += d.capacity[d.x][i] + ', '
            }
            else {
              capacitystr += d.capacity[d.x][i]
            }
          }
        }
        else { // AVG_PRICE

        }

        return `
        <div id=${d.id + d.username} class="Scatterplot_tooltiproot${touchscreen ? 'touchscreen' : ''}" >
          <div>

            <div class="Scatterplot_tooltiptop">Venue</div>
            <div style="margin-bottom:10px;">
              <div class="Comparevenue_dndiamondrow">
                <div>${d.y}</div>
                ${d.tier > 0
            ? `<div class="Comparevenue_diamondroot" style="background-image:url('https://asset.weavebliss.com/assets/sprites/diamonds.png'); background-position-y: ${TIER_DIAMOND_POSITION[d.tier].y + 'px'};" />`
            : ``}
              </div>
              
            </div>

            <div style="display:flex; flex-direction:row; width:100%;">
              <div style="display:flex; flex-direction:column; width:50%">
                <div class="Scatterplot_tooltiptop" style="margin-top:5px;text-transform: capitalize">Menu</div>
                <div style="text-transform:capitalize">${d.culture + ' ' + d.mealtype}</div>
              </div>
            </div>


            <div style="display:flex;flex-direction:column;">
              <div class="Scatterplot_tooltiptop" style="display:flex; flex-direction:row ;margin-top:5px;">
                <div >${d.mealofday} </div>
                <div style="width:10px;height:10px;background-color:${color_style}; border-radius:10px;place-self:center;margin:0px 5px;"></div>
              </div>
              <div>$ ${parseFloat(d.x.toFixed(2))} ++ ${priceviewmode === 'ALL_PRICES' ? '' : d.mealtype === 'buffet' ? '( Avg Price Per Pax )' : '( Avg Price Per Table )'} </div>
            </div>


            <div style="display:flex;flex-direction:column;">

              ${priceviewmode === 'ALL_PRICES'
            ? `<div class="Scatterplot_tooltiptop" style="display:flex; flex-direction:row ;margin-top:5px;">
                  <div>Capacity</div>
                </div>`
            : ``}
              
              <div style="display:flex;flex-direction:row; flex-wrap:wrap;">${capacitystr}</div>
            
            </div>

            

          </div>
        
          ${touchscreen
            ? `<div class="Scatterplot_tooltiprow">
                <button class="Scatterplot_tooltipclose">Close</button>
                <button class="Scatterplot_tooltipaddtoselection">Add To Selection</button>
              </div>`
            : ''}

        </div>
        `
      })
      .style("left", () => {
        // width of the dialog is 270px, check css Scatterplot_tooltiproot
        if (device !== 'WEB') {
          let result = 0
          let tooltipwt = 270
          if ((180 + lineX + tooltipwt) > scatterwt) { // if the tooltip from tablet is located off the screen from the right, make it not so right
            result = (180 + lineX - tooltipwt) + 'px'
          }
          else {
            result = (180 + (lineX)) + 'px'
          }
          return result
        }
        else {
          return (220 + (lineX)) + 'px'
        }
      })
      .style("top", () => {
        if (d3.event.clientY > (ht * 0.7)) {
          return (d3.event.clientY - 200) + 'px'
        }
        else return (d3.event.clientY - 50) + 'px'
      })
      .style("opacity", 1)
      .style('visibility', 'visible')

  }

  function addselection(d) {
    props.circleclick(d)
    try {
      d3.event.stopPropagation();
    }
    catch (e) { }

  }

  function closedialog() {
    d3.select(scatterplotRef.current).selectAll('.scatterplotline').remove()

    d3.select(this).attr("r", radius);

    // Hide tooltip on mouseout
    d3.select("#Scatterplot_tooltip")
      .style("opacity", 0)
      .style('visibility', 'hidden')
  }

  function clearallskeletons() {
    d3.select(scatterplotRef.current).selectAll('.grid').remove()
    d3.select(scatterplotRef.current).selectAll('.Scattercircle').remove()
    d3.select(scatterplotRef.current).selectAll('.line').remove();
    d3.select(scatterplotRef.current).selectAll('.tick').remove();
    d3.select(scatterplotRef.current).selectAll('.Scatterplot_fontlabel').remove();
    d3.select(scatterplotRef.current).selectAll('.Scatterplot_text').remove();
    d3.select(scatterplotRef.current).selectAll('.Scatterplot_reviewnum').remove();
    d3.select(scatterplotRef.current).selectAll('.Scatterplot_reviewrect').remove();
    d3.select(scatterplotRef.current).selectAll('.Scatterplot_border').remove();
    d3.select(scatterplotRef.current).selectAll('.Scatterplotwindow_root').remove();
  }

  useEffect(() => {
    const handleClick = (e) => {
      if (e.target.classList.contains('Scatterplot_tooltipclose')) {
        closedialog();
      }
      else if (e.target.classList.contains('Scatterplot_tooltipaddtoselection')) {
        document.getElementById('mobilebtn').click();
        setShowPopup(true)
        closedialog();
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [])

  useEffect(() => {

    if (data.length > 0) {
      clearallskeletons()
      setPlotitem(plotitem + 1)
    }
  }, [priceviewmode, data, allorselected, favourite, leftmain, selecteddata])

  useEffect(() => { // displayname : review_num
    let newdnrn = {}
    for (let i = 0; i < data.length; i++) {
      const { y, review_num, tier } = data[i]
      newdnrn[y] = { 'review_num': review_num, 'tier': tier }
    }
    setDnrn(newdnrn)
  }, [data])

  useEffect(() => {

    clearallskeletons()

    const margin = { top: 20, right: 30, bottom: 80, left: device === 'WEB' ? 200 : device === 'TABLET' ? 210 : 125 };
    const width = scatterwt - margin.left - margin.right;
    const height = scatterht - margin.top - margin.bottom;
    d3.select(scatterplotRef.current).selectAll('.grid').remove()

    const scatterplotsvg = d3.select(scatterplotRef.current)
      .attr('key', plotitem)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`)

    const fixedxaxissvg = d3.select(fixedxaxisRef.current)
      .attr('key', plotitem + 'fixedxaxissvg')
      .attr('width', width + margin.left + margin.right - 7)
      .attr('height', margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top + margin.bottom})`)

    // price - min-max
    const xScale = d3.scaleLinear()
      .domain([min, max])
      .range([0, width])

    // displayname
    const yScale = d3.scaleBand()
      .domain((data).map(d => d.y))
      .range([height, 0])
      .padding(0.1)

    scatterplotsvg // Append grid lines for x axis
      .append('g')
      .attr('class', 'grid')
      .attr('stroke', 'lightgrey')
      .attr('stroke-opacity', 0.1)
      .attr('transform', `translate(0, ${height})`)
      .call(d3.axisBottom(xScale).tickSize(-height).tickFormat(''));

    scatterplotsvg // Append grid lines for y axis
      .append('g')
      .attr('class', 'grid')
      .attr('stroke', 'lightgrey')
      .attr('stroke-opacity', 0.1)
      .call(d3.axisLeft(yScale).tickSize(-width).tickFormat(''));

    // VENDOR DISPLAYNAME
    scatterplotsvg.append('g')
      .call(d3.axisLeft(yScale))
      .selectAll('text')
      .attr('class', 'Scatterplot_text')
      .each(function (d) {

        // Split the text data by newline
        var arr = d.split("\n");

        // Clear the current text content
        d3.select(this).text('');

        // for wrapping text, check if theres next space, if yes, split to two rows. 
        d3.select(this)
          .selectAll('tspan')
          .data(() => arr)
          .enter()
          .append('tspan')
          .attr('x', -10)
          .attr('dx', -5)
          .attr('dy', (d) => {
            if (arr.length > 1) { return arr[0] === d ? '-0.5em' : '1.3em' }
            else { return '0.3em' }
          })
          .text(function (d) { return d })
          .on("click", () => updateScatterportaldata(d, selecteddata))
          .attr('style', "cursor:pointer;")

        // Append rectangle before the text
        d3.select(this.parentNode)
          .insert('rect', 'text')
          .attr('class', 'Scatterplot_reviewrect')  // Add a class for future removal
          .attr('x', d3.select(this).node().getBBox().x - 4)  // Add some padding
          .attr('y', d3.select(this).node().getBBox().y - 4)
          .attr('width', d3.select(this).node().getBBox().width + 8)
          .attr('height', d3.select(this).node().getBBox().height + 8)
          .attr('rx', 5)
          .attr('ry', 5)
          .attr('style', "white-space: break-spaces; padding-bottom: 3px; cursor:pointer;")
          .style('fill', (d) => {
            return selecteddata.map(item => item.displayname).includes(d) ? '#ffe0e79c' : '#ffffff'
          }) // select affect fill
          .style('stroke', (d) => { // favourite affect the stroke
            for (let i = 0; i < favourite.length; i++) {
              if (favourite[i].length === 4 && favourite[i][3] === d) {
                return 'var(--mainpink)'
              }
            }
          })

        // DIAMOND
        d3.select(this.parentNode)
          .append('polygon')
          .attr('class', 'Scatterplot_diamond')  // Add a class for future removal
          .attr('points', (d) => {
            if (dnrn[d]) {
              const { tier } = dnrn[d]
              if (tier > 0) {
                // const cx = d3.select(this).node().getBBox().x - 8; // Center x
                // const cy = d3.select(this).node().getBBox().y - 12; // Center y
                const cx = d3.select(this).node().getBBox().x - 8; // Center x
                const cy = d3.select(this).node().getBBox().y - 5; // Center y

                const topWidth = 8;   // Width at the top (shorter)
                const bottomWidth = 16; // Width at the bottom (longer)
                const height = 20;    // Full height of the diamond

                // Define the points for the custom 5-sided diamond shape
                const points = [
                  [cx - topWidth / 2, cy - height / 15],   // Top-left (shorter)
                  [cx + topWidth / 2, cy - height / 15],   // Top-right (shorter)
                  [cx + bottomWidth / 2.5, cy + height / 8], // Bottom-right (longer)
                  [cx, cy + height / 2],                  // Bottom (longest point)
                  [cx - bottomWidth / 2.5, cy + height / 8] // Bottom-left (longer)
                ];
                return points.map(p => p.join(',')).join(' '); // Format points as a string for 'points' attribute
              }
            }
          })

          .attr('fill', (d) => {
            if (dnrn[d]) {
              const { tier } = dnrn[d]
              if (tier === 0) return ''
              else return TIERARR[tier].innercolor
            }
          })  // Customize the color as needed
          // .attr('stroke', '#ffc7d2')  // Optional: Add stroke to make the pentagon more visible
          .attr('stroke', () => {
            if (dnrn[d]) {
              const { tier } = dnrn[d]
              if (tier === 0) return ''
              else return TIERARR[tier].outercolor
            }
          })  // Optional: Add stroke to make the pentagon more visible
          .attr('stroke-width', 2);  // Optional: Define the stroke width

      })

    // REVIEW NUMBER
    scatterplotsvg.append('g')
      .call(d3.axisLeft(yScale))
      .selectAll('text')
      .attr('class', 'Scatterplot_reviewnum')
      .each(function (d) {

        // Clear the current text content
        d3.select(this).text('');

        // d3.select(scatterplotRef.current).selectAll('.Scatterplot_reviewnumtext').remove();
        // vendorname for wrapping text, check if theres next space, if yes, split to two rows.
        d3.select(this)
          .selectAll('tspan')
          .attr('class', 'Scatterplot_reviewnumtext')
          .data(() => {
            return dnrn[d] ? [dnrn[d].review_num] : 0
          })
          .enter()
          .append('tspan')
          .attr('x', 5)
          .attr('dx', 15)
          .text((reviewnum) => reviewnum)
          .on("click", () => updateScatterportaldata(d, selecteddata))
          .attr('style', "cursor:pointer; ")

        // d3.select(scatterplotRef.current).selectAll('.Scatterplot_border').remove();

        // Append rectangle before the text
        d3.select(this.parentNode)
          .insert('rect', 'text')
          .attr('class', 'Scatterplot_border')
          .attr('x', d3.select(this).node().getBBox().x - 4)  // Add some padding
          .attr('y', d3.select(this).node().getBBox().y - 4)
          .attr('width', d3.select(this).node().getBBox().width + 8)
          .attr('height', d3.select(this).node().getBBox().height + 8)
          .attr('rx', 5)
          .attr('ry', 5)
          .attr('style', `white-space: break-spaces; padding-bottom: 3px; cursor:pointer; `)
          .style('fill', (d) => {
            return selecteddata.map(item => item.displayname).includes(d) ? '#ffe0e79c' : '#ffffff'
          })
          .style('stroke', (d) => { // favourite affect the stroke
            for (let i = 0; i < favourite.length; i++) {
              if (favourite[i].length === 4 && favourite[i][3] === d) {
                return 'var(--mainpink)'
              }
            }
          })

      })

    // Add y-axis label - Venue(s)
    scatterplotsvg.append("text")
      .attr('class', 'Scatterplot_fontlabel')
      .attr("text-anchor", "middle")
      .attr("x", 0)
      .attr("y", -5)
      .text("Venue(s) | Review(s)")
      .style("font-size", "12px") // Optional: add styling
      .style("font-family", "Lato") // Optional: add styling

    // scatter dot
    scatterplotsvg.selectAll('circle')
      .data(data)
      .enter()
      .append('circle')
      .attr('class', 'Scattercircle')
      .attr('id', d => d.id + d.username + '_id')
      .attr('cx', d => xScale(d.x))
      .attr('cy', d => yScale(d.y) + yScale.bandwidth() / 2) // Adjusting position for centering
      .attr('r', radius)
      .attr('fill', d => d.color)
      .attr('z-index', 1)
      .style('transform', d => d.transform)
      .on("mouseover", handleMouseOver)
      .on("mouseout", handleMouseOut)
      .on("click", handleCircleClick);

    // position fixed x-axis
    fixedxaxissvg.append("g")
      .attr('transform', `translate(0, -${margin.top + margin.bottom})`)
      .call(d3.axisBottom(xScale).tickFormat(formatNumber));

    // Add x-axis label - Price
    fixedxaxissvg.append("text")
      .attr('class', 'Scatterplot_fontlabel')
      .attr("text-anchor", "middle")
      .attr("x", width / 2)
      .attr("y", { 'WEB': -55, 'TABLET': -50, 'MOBILE': 0 }[device]) // Adjust -10 according to your preference
      .text("Price (SGD)")
      .style("font-size", "12px") // Optional: add styling
      .style("font-family", "Lato") // Optional: add styling

    d3
      .select(scatterplotRef.current)
      .selectAll('text')
      .style('fill', (d) => 'var(--maindark)')

    d3
      .select(fixedxaxisRef.current)
      .selectAll('text')
      .style('fill', (d) => 'var(--maindark)')




















    // brush, dont delete
    // const windowsvg = d3.select(windowRef.current)
    //   .attr('transform', `translate(0,0)`)
    //   .attr('width', width)
    //   .attr('height', margin.top + margin.bottom)
    //   .attr('transform', `translate(${margin.left},0)`)

    // const brush = d3.brushX()
    //   .extent([[0, 0], [width, 120 - margin.bottom + 0.5]])
    //   .on('end', () => {
    //     var s = d3.event.selection
    //     if(s){
    //       const brushstartprice = xScale(s[0]) * 100
    //       const brushendprice = xScale(s[1]) * 100
    //       // updatemin(brushstartprice)
    //       // updatemax(brushendprice)
    //     }

    //   })

    // const defaultSelection = [0, width];

    // windowsvg.append("g")
    //   .call(d3.axisBottom(xScale)
    //   .tickFormat(formatNumber));

    // windowsvg.append("g")
    //   .call(brush)
    //   .call(brush.move, defaultSelection);







    function handleCircleClick(d, index) {

      setCurrselectedcircle(d)
      if (touchscreen) {
        showdialog(scatterplotsvg, d, index, xScale, yScale, height, radius)
        setShowPopup(false) // is on useeffect if user uses touchscreen device
      }
      else {
        addselection(d)
        setShowPopup(true)
      }
      setSelectiondn(d.y)
    }

    function handleMouseOver(d, index) {
      if (touchscreen) { }
      else { showdialog(scatterplotsvg, d, index, xScale, yScale, height) }
    }

    function handleMouseOut() {
      if (touchscreen) { }
      else { closedialog() }
    }

  }, [plotitem])

  return <div key={scatterkey} style={device === 'MOBILE' ? { position: 'absolute', left: 0 } : {}}>
    {acct_type === 'USER' && showPopup && <Popup selectiondn={selectiondn} duration={1750} onClose={handleClosePopup} />}
    <svg key={'scatterplotRef'} ref={scatterplotRef} className="Scatterplot_root" />
    <svg key={'fixedxaxisRef'} ref={fixedxaxisRef} className="Scatterfixedxaxis_root" style={WINDOWCSS_MAP[device]} />
    <svg key={'windowRef'} ref={windowRef} className="Scatterplotwindow_root" style={LOWERWINDOWCSS_MAP[device]} />
    <div key={'Scatterplot_tooltip'} id="Scatterplot_tooltip" className="Scatterplot_tooltip" />
    {device === 'MOBILE' ? <button id='mobilebtn' onClick={() => addselection(currselectedcircle)} /> : null}
  </div>
}

export default Scatterplot