import React, { useEffect, useRef, useState } from 'react'
import { Textfit } from 'react-textfit'
import { g_fs, FS_LH_MAP, INVITESOCIALMEDIASIM_ARR, HUMANSIM_ICON, ALLDEFAULTIMGS } from '../../../../../../../../../config/constants'
import { title_0, title_1, title_2 } from '../../../../../constants'
import { Invdate, Timelineobj } from '../../../../../../../../../components'
import { getS3fullpath_withbucketname, iconwrapper } from '../../../../../../../../../config/commonfunctions'
import { GALLERY_ICON, ITINERARY_ICON, RSVP_ICON, SEATINGS_ICON, RSVPTELEPHONE_ICON, GUESTBOOK_ICON, REGISTRY_ICON, SMALLERMORE_ICON, SOCIALMEDIA_ICON, PROMOTE_ICON, ARROWDOWN_ICON, PLAY_ICON, PAUSE_ICON, EXPAND_ICON } from '../../../../../../../../../config/svgicons'
import { allimages } from '../../../../../../../../../assets/invitationcard/index1'
import Jfmap from '../../../../../../../../../components/Jfmap/Jfmap'
import T1border from '../T1border/T1border'
import Runsvg from '../../../../../../../../../components/Runsvg/Runsvg'
import { S3CLOUD_CLOUDFRONT } from '../../../../../../../../../config/s3directories'
import './T1simmobile.css'

const INFINITELOOP_MODE = 2
const EMPTY_SVG = 0

const BG_IMG_SIZE = 500
const debug_guideline = false
const maintext_fs = g_fs
const location_fs = g_fs
const schedule_fs = g_fs
const map_fs = g_fs
const qn_fs = g_fs
const howwemet_fs = maintext_fs
const proposal_fs = maintext_fs

function useLongPress() {
    return function (callback) {
        let timeout;
        let preventClick = false;

        function start() {
            timeout = setInterval(() => {
                preventClick = true;
                callback();
            }, 100);
        }

        function clear() {
            // Get a reference to the last interval + 1
            const interval_id = window.setInterval(function () { }, Number.MAX_SAFE_INTEGER);

            // Clear any timeout/interval up to that id
            for (let i = 1; i < interval_id; i++) {
                window.clearInterval(i);
            }

            timeout && clearInterval(timeout);
            preventClick = false;
        }

        function clickCaptureHandler(e) {
            if (preventClick) {
                e.stopPropagation();
                preventClick = false;
            }
        }

        return {
            onMouseDown: start,
            onTouchStart: start,
            onMouseUp: clear,
            onMouseLeave: clear,
            onTouchMove: clear,
            onTouchEnd: clear,
            onClickCapture: clickCaptureHandler
        };
    }
}

function T1simmobile(props) {

    const { wt, ht, displayseq, displayonoffmap, displaytxtmap, templatecolor, textures, styleborder, styleborderwt, styleborderbr, stylebordercolor, name, spousename, ntm, fc, priff, secff, locationff, btmbarff, datetimeff, images, itype, maintext, css_map, location, mapmode, lat, long, date_w, date_e, time, dt_type, whowwemet, stylesep, wproposal, schedules, qnas, locdescript, locurl, styletype, rsvpactive, socialmedias, promote, menutype, svgoverlay_map, imgcss_map, styleorientationmobile, styleformationmobile, menuicongate } = props

    const scrollcontainerref = useRef(null)
    const [menustyle, setMenustyle] = useState({})
    const [startrunsvg, setStartrunsvg] = useState(false)
    const [stylenavupdate, setStylenavupdate] = useState(-1)
    const [svgstate, setSvgstate] = useState('PLAYING') // PLAYING, PAUSE
    const [nameposchange, setNameposchange] = useState(false) // name position change due to background
    const onLongPress = useLongPress();

    useEffect(() => {
        setStartrunsvg(true)
    }, [])

    useEffect(() => {
        setMenustyle(props.menustyle)
        setStylenavupdate(stylenavupdate + 1)
    }, [props])

    function scrollup() {
        scrollcontainerref.current.scrollTop = scrollcontainerref.current.scrollTop > 1
            ? scrollcontainerref.current.scrollTop - 10
            : 0
    }

    function scrolldown() {
        scrollcontainerref.current.scrollTop = scrollcontainerref.current.scrollTop + 10;
    }

    const sep_dom = stylesep && stylesep.length > 0 ? <div style={{ width: '100%', height: 20, marginTop: 20, color: props.fc, alignSelf: 'center', backgroundImage: 'url(' + stylesep + ')', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }} /> : null

    useEffect(() => {
        const { bgformat } = allimages.get(styletype)

        let curr_bgformat = bgformat[parseInt(styleformationmobile)]
        const { affectnamepos } = curr_bgformat['MOBILE']
        if (affectnamepos && affectnamepos.state) {
            setNameposchange(true)
        }
        else {
            setNameposchange(false)
        }

    }, [styleformationmobile, styleorientationmobile])

    const bg = () => {

        const { background, bgformat } = allimages.get(styletype)

        let curr_bgformat = bgformat[parseInt(styleformationmobile)]
        let curr_orientation = curr_bgformat['orientation_allow_list'][parseInt(styleorientationmobile)]

        let firstimg_orientation = curr_orientation['img1']
        let secondimg_orientation = curr_orientation['img2']

        let firstimg_positionincard = curr_bgformat['MOBILE']['bg1']
        let secondimg_positionincard = curr_bgformat['MOBILE']['bg2']

        let image1_url = firstimg_positionincard ? 'url(' + background[firstimg_positionincard]['url'][firstimg_orientation].replace('lookup', 'edit') + ')' : null
        let image2_url = secondimg_positionincard ? 'url(' + background[secondimg_positionincard]['url'][secondimg_orientation].replace('lookup', 'edit') + ')' : null

        return [
            <div
                key={'tsim_a'}
                className={`${firstimg_positionincard}_m_simulator`}
                style={{ backgroundImage: image1_url, position: 'absolute', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', zIndex: 998, }}
            />,
            <div
                key={'tsim_b'}
                className={`${secondimg_positionincard}_m_simulator`}
                style={{ backgroundImage: image2_url, position: 'absolute', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', zIndex: 998, }}
            />
        ]

    }

    const mainimg_dom = <div style={{ width: '100%', height: 'auto', zIndex: 999, position: 'relative' }}>

        <div style={{ height: 'calc( 100% - 40px )', width: '100%', position: 'absolute', display: 'flex', margin: '20px 0px', zIndex: 1 }}>
            <Runsvg startrunsvg={startrunsvg} svgoverlay_map={svgoverlay_map} svgstate={svgstate} device={'MOBILE'} />
        </div>

        {/* {svgoverlay_map && svgoverlay_map['mainimg']['replay']
            ? <button style={{ color: svgoverlay_map['mainimg']['color'], border: 'none', bottom: 0, right: 0, position: 'absolute', display: 'flex', alignItems: 'center', placeContent: 'center', padding: 0, margin: 5, backgroundColor: 'transparent', zIndex: 1 }}>
                {iconwrapper(PLAY_ICON, { width: 12, height: 12, display: 'flex' })}
            </button>
            : null} */}

        {(svgoverlay_map && svgoverlay_map['mainimg'] && svgoverlay_map['mainimg']['replay'] && svgoverlay_map['mainimg']['mode'] === INFINITELOOP_MODE && svgoverlay_map['mainimg']['svgitem'] !== EMPTY_SVG)
            ? <button
                onClick={() => {
                    setSvgstate(svgstate === 'PAUSE' ? 'PLAYING' : 'PAUSE')
                }}
                style={{ color: svgoverlay_map['mainimg']['color'], border: 'none', bottom: 0, right: 0, position: 'absolute', display: 'flex', alignItems: 'center', placeContent: 'center', padding: 0, margin: 5, backgroundColor: 'transparent', zIndex: 1 }}
            >
                {{
                    'PAUSE': iconwrapper(PLAY_ICON, { width: 15, height: 15, display: 'flex' }),
                    'PLAYING': iconwrapper(PAUSE_ICON, { width: 15, height: 15, display: 'flex' })
                }[svgstate]}
            </button>
            : null}


        <img
            alt={''}
            style={{ position: 'relative', width: '100%', height: 'auto', display: 'block', overflow: 'hidden' }}
            src={(images && images[0])
                ? (ALLDEFAULTIMGS.indexOf(images[0]) !== -1 || images[0].indexOf(S3CLOUD_CLOUDFRONT) !== -1)
                    ? images[0]
                    : getS3fullpath_withbucketname('USER_INVITATIONCARD_IMG', images[0])
                : null}
        />
    </div>

    const maintext_dom = (maintext && maintext.length > 0)
        ? <div key={'mt'} style={{ fontSize: maintext_fs, lineheight: FS_LH_MAP[maintext_fs], color: fc, width: '100%', display: 'flex', justifyContent: 'center', alignSelf: 'center', textAlign: 'center', padding: 15, paddingBottom: 10, flexDirection: 'column', ...css_map['maintext'] }}>{maintext}</div>
        : <div key='mt' style={{ width: 0, height: 0, visibility: 'hidden' }} />

    const location_dom = location ?
        <div
            key={'loc'}
            style={{ fontSize: location_fs * 2.0625, color: fc, fontFamily: locationff, width: '100%', cursor: 'pointer', fontWeight: 'bold', zIndex: 999, display: 'flex', justifyContent: 'center', textAlign: 'center', padding: 15, flexDirection: 'row', whiteSpace: 'pre' }}
            onClick={() => { if (lat.length > 0 && long.length > 0) window.open("https://www.google.com/maps/search/?api=1&query=" + lat + "," + long, "_blank") }}>
            {location}
        </div>
        : <div key='loc' style={{ width: 0, height: 0, visibility: 'hidden' }} />

    const datetime_dom = React.cloneElement(
        <Invdate
            date_e={date_e}
            date_w={date_w}
            itype={itype}
            dt_type={dt_type}
            time={time}
            fc={fc}
            bgcolor={templatecolor}
            from={'T1simmobile'}
            datetimeff={datetimeff}
        />,
        { key: 'datedom' }
    )

    const howwemet_dom = () => {
        const lh = FS_LH_MAP[howwemet_fs]
        const dt = (whowwemet && whowwemet.length === 2) ? whowwemet[0] : ''
        const txt = (whowwemet && whowwemet.length === 2) ? whowwemet[1] : ''
        // return dt.length > 0 || txt.length > 0
        return displayonoffmap['howwemet']
            ? <div key='howwemet' style={{ width: '100%', fontSize: howwemet_fs, color: fc, display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '0px 25px 10px 25px' }}>
                {sep_dom}
                <div style={{ fontSize: howwemet_fs * 1.625, lineHeight: lh, fontFamily: secff, fontWeight: 'bold', display: 'flex', justifyContent: 'center', margin: 10, padding: '0px 5px', borderRadius: 5, alignSelf: 'center' }}>
                    {displaytxtmap['howwemet']}
                </div>
                <div>{dt}</div>
                <div style={{ lineHeight: lh, ...css_map['howwemet'] }}>{txt}</div>
            </div>
            : null
    }

    const proposal_dom = () => {
        const dt = (wproposal && wproposal.length === 2) ? wproposal[0] : ''
        const txt = (wproposal && wproposal.length === 2) ? wproposal[1] : ''
        // return dt.length > 0 || txt.length > 0
        return displayonoffmap['proposal']
            ? <div key='proposal' style={{ width: '100%', fontSize: proposal_fs, color: fc, display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '0px 25px 10px 25px' }}>
                {sep_dom}
                <div style={{ fontSize: proposal_fs * 1.625, fontFamily: secff, fontWeight: 'bold', display: 'flex', justifyContent: 'center', margin: 10, padding: '0px 5px', borderRadius: 5, alignSelf: 'center' }}>
                    {displaytxtmap['proposal']}
                </div>
                <div>{dt}</div>
                <div style={{ lineHeight: FS_LH_MAP[proposal_fs], ...css_map['proposal'] }}>{txt}</div>
            </div>
            : null
    }

    const qns_dom = displayonoffmap['qna']
        ? <div key='qns' style={{ width: '100%', color: fc, display: 'flex', flexDirection: 'column', justifyContent: 'center', userSelect: 'none' }}>
            {sep_dom}
            <div style={{ fontSize: qn_fs * 1.625, fontFamily: secff, lineHeight: FS_LH_MAP[qn_fs], fontWeight: 'bold', display: 'flex', justifyContent: 'center', margin: 10, padding: '0px 5px', borderRadius: 5, alignSelf: 'center' }}>
                {displaytxtmap['qna']}
            </div>
            {qnas && qnas.map((qna, i) => {
                if (i % 2 === 0) {
                    return <div key={'qnas_doms' + i} style={{ color: fc, display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'center', flexWrap: 'wrap', padding: i === 0 ? '0px 25px 10px 25px' : '10px 25px' }}>
                        <div style={{ fontSize: qn_fs, fontWeight: 'bold', lineHeight: FS_LH_MAP[qn_fs], userSelect: 'none' }}>{qnas[i]}</div>
                        <div style={{ fontSize: qn_fs, lineHeight: FS_LH_MAP[qn_fs], userSelect: 'none' }}>{qnas[i + 1]}</div>
                    </div>
                }
                else {
                    return null
                }
            })}
        </div>
        : null


    // const schedule_dom = schedules && schedules.length > 0
    const schedule_dom = displayonoffmap['schedule']
        ? <div key='schedule' style={{ width: '100%', color: fc, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            {sep_dom}
            <div style={{ fontSize: schedule_fs * 1.625, fontFamily: secff, lineHeight: FS_LH_MAP[schedule_fs], fontWeight: 'bold', display: 'flex', justifyContent: 'center', margin: 10, padding: '0px 5px', borderRadius: 5, alignSelf: 'center' }}>
                {displaytxtmap['schedule']}
            </div>
            {schedules.map((item, index) => {
                return (
                    <Timelineobj
                        from="Invitationsimulator"
                        key={'timeline' + index}
                        index={index}
                        bb={index !== schedules.length - 1 ? `0.5px solid ${fc}` : 'none'} // borderBottom
                        date_s={item[0]}
                        date_e={item[1]}
                        time_s={item[2]}
                        time_e={item[3]}
                        name={item[4]}
                        content={item[5]}
                        mapobj={item[8]}
                        itineraryobj={item[9]}
                        pri_fs={schedule_fs}
                        sec_fs={schedule_fs}
                        wt={'100%'}
                        dttimebox_wt={'max-content'}
                        content_ht={'max-content'}
                        fc={fc}
                        bgc={templatecolor}
                        mapmode={mapmode}
                        expandbtn={true}
                    />
                )
            })}
        </div>
        : null


    // const map_dom = locurl && locurl.length > 0
    const map_dom = displayonoffmap['map']
        ? <div key='map' style={{ width: '100%', color: fc, display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingBottom: 20, alignItems: 'center' }}>
            {sep_dom}
            <div style={{ fontSize: map_fs * 1.625, lineHeight: FS_LH_MAP[map_fs], fontFamily: secff, fontWeight: 'bold', display: 'flex', justifyContent: 'center', margin: 10, padding: '0px 5px', borderRadius: 5, alignSelf: 'center' }}>
                {displaytxtmap['map']}
            </div>
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                {iconwrapper(
                    EXPAND_ICON,
                    { transform: 'scale(0.75)', width: 21, height: 21, display: 'flex', borderRadius: 4, padding: 5, placeContent: 'center', alignItems: 'center', backgroundColor: 'white', boxShadow: 'rgba(0, 0, 0, 0.65) 0px 1px 5px', color: 'var(--maindark)', position: 'absolute', right: 21, zIndex: 401, margin: 5 }
                )}
                <Jfmap markersData={[{ latLng: { lat: props.lat, lng: props.long } }]} type={'small'} smallstyle={{ height: 150, width: 'calc( 100% - 40px )', margin: '0px 20px' }} zc={false} gpsiconsize={[12, 20]} mapmode={mapmode} />
                <div style={{ fontSize: map_fs, margin: '10px 20px 0px 20px' }}>{props.locdescript}</div>
            </div>
        </div>
        : null

    const rsvp_dom = rsvpactive
        ? <button key='rsvp' style={{ width: `calc( 100% - 40px )`, color: fc, fontFamily: secff, padding: '5px 0px', margin: '10px 20px', backgroundColor: templatecolor, border: `0.5px solid ${fc}`, borderRadius: 5 }}>RSVP</button>
        : null

    // inactive
    const socialmedia_dom = <div style={{ color: fc, display: 'flex', flexDirection: 'row', placeContent: 'center', paddingBottom: rsvpactive ? 0 : 30 }}>
        {socialmedias.map((media, i) => media.length > 0 ? <div key={'socialmedia' + i} style={{ padding: 3, margin: 3 }}>{INVITESOCIALMEDIASIM_ARR[i]}</div> : null)}
        {(promote && promote.length > 0) ? <div style={{ padding: 3, margin: 3, borderLeft: 'thin solid rgba(192, 192, 192, 0.37)' }}>{HUMANSIM_ICON}</div> : null}
    </div>

    const devicedim = 20
    const GENERALICON_CSS = { width: devicedim, height: devicedim, display: 'flex', margin: 4 }

    const LOGO_MAP = {
        'RSVP': iconwrapper(RSVP_ICON, GENERALICON_CSS),
        'Gallery': iconwrapper(GALLERY_ICON, GENERALICON_CSS),
        'Itinerary': iconwrapper(ITINERARY_ICON, GENERALICON_CSS),
        'Contact': iconwrapper(RSVPTELEPHONE_ICON, GENERALICON_CSS),
        'Seatings': iconwrapper(SEATINGS_ICON, GENERALICON_CSS),
        'Guestbook': iconwrapper(GUESTBOOK_ICON, GENERALICON_CSS),
        'More': iconwrapper(SMALLERMORE_ICON, GENERALICON_CSS),
        'Share': iconwrapper(SOCIALMEDIA_ICON, GENERALICON_CSS),
        'Promote': iconwrapper(PROMOTE_ICON, GENERALICON_CSS),
        'Registry': iconwrapper(REGISTRY_ICON, GENERALICON_CSS),
    }

    // btm bar navigation  -  menutype ,menuseq, menustyle
    // const nav_dom = menutype === 'btmnavbar' ? <div key={stylenavupdate} className='T1sim_nav' style={{ width: '100%', fontFamily: props.btmbarff, backgroundColor: menustyle.backgroundColor, border: `0.5px solid ${menustyle.borderColor}`, borderBottom: 'none', placeContent: 'center' }}>
    //     {props.menuseq.map((ms, index) => {
    //         if (ms === 'RSVP' && !rsvpactive) {
    //             return null
    //         }
    //         // return <div className='ilookup_btmbaritem' key={index + 'ms'} style={{ border: menustyle.borderColor, color: menustyle.color, width: 37, height: 50 }}>
    //         //     {LOGO_MAP[ms]}
    //         //     <div className='ilookup_btmtxt' style={{ fontSize: 6, height: 'max-content' }}>{ms}</div>
    //         // </div>
    //         return <div className='T1simmobile_navitem' key={index + 'ms'} style={{ border: menustyle.borderColor, color: menustyle.color, }}>
    //             {LOGO_MAP[ms]}
    //             <div className='ilookup_btmtxt' style={{ fontSize: 6, height: 'max-content' }}>{ms}</div>
    //         </div>
    //     })}
    // </div> : null

    // btm bar navigation  -  menutype ,menuseq, menustyle
    const nav_dom = menutype === 'btmnavbar'
        ? <div key={stylenavupdate} className='T1sim_m_nav' style={{ width: '100%', fontFamily: props.btmbarff, backgroundColor: menustyle.backgroundColor, border: `0.5px solid ${menustyle.borderColor}`, borderBottom: 'none', placeContent: 'center' }}>
            {props.menuseq.map((ms, index) => {
                if (ms === 'RSVP' && !rsvpactive) {return null}

                if (menuicongate) {
                    return <div className='T1simmobile_navitem' key={index + 'ms'} style={{ border: menustyle.borderColor, color: menustyle.color, }}>
                        {menuicongate ? LOGO_MAP[ms] : null}
                        <div className='ilookup_btmtxt' style={{ fontSize: 6, height: 'max-content' }}>{ms}</div>
                    </div>

                }
                else {
                    return <div className='T1simmobile_navitem_withouticon' key={index + 'ms'} style={{ border: menustyle.borderColor, color: menustyle.color, }}>
                        <div className='ilookup_btmtxt' style={{ fontSize: 9, height: 'max-content' }}>
                            {ms}
                        </div>
                    </div>

                }
            })}
        </div>
        : menutype === 'stickytopnav' ?
            <div key={stylenavupdate} className='T1sim_m_topnav' style={{ width: '100%', fontFamily: props.btmbarff, backgroundColor: menustyle.backgroundColor, borderBottom: `0.5px solid ${menustyle.borderColor}`, placeContent: 'center' }}>
                {props.menuseq.map((ms, index) => {
                    if (ms === 'RSVP' && !rsvpactive) {
                        return null
                    }
                    return <div
                        key={index + 'ms'}
                        className='T1simmobile_topnavitem'
                        style={{ border: menustyle.borderColor, color: menustyle.color, }}
                    >
                            {ms}
                    </div>
                })}
            </div>
            : menutype === 'none'
                ? null
                : null


    const title_dom = nameposchange
        ? <div style={{ height: '50%', alignItems: 'center', display: 'flex' }}>{
            [
                <Textfit key={"m" + ntm + wt + nameposchange} style={{ top: nameposchange ? `calc( 30% - ${(title_1.mobilesimulator_ht) / 2}px - 10px )` : `calc( 50% - ${(title_0.mobilesimulator_ht) / 2}px - 10px )`, height: title_0.mobilesimulator_ht, width: wt - 60, fontFamily: secff, color: fc, margin: '10px 30px', display: 'flex', flexDirection: 'column', placeContent: 'center', fontWeight: 'normal', position: 'relative', lineHeight: 'normal', alignItems: 'center', border: debug_guideline ? '1px solid black' : 'none' }}>
                    <div style={{ height: title_0.mobilesimulator_ht * title_0.title_layer1, width: ((wt - 20) * 0.95) - 50, placeContent: 'center', whiteSpace: 'nowrap', display: 'flex', alignItems: 'flex-end' }}>{name}</div>
                    <Textfit style={{ height: title_0.mobilesimulator_ht * title_0.title_layer2, width: ((wt - 20) * 0.95) - 50, placeContent: 'center', whiteSpace: 'nowrap', display: 'flex', alignItems: 'baseline' }}>{'&'}</Textfit>
                    <div style={{ height: title_0.mobilesimulator_ht * title_0.title_layer3, width: ((wt - 20) * 0.95) - 50, placeContent: 'center', whiteSpace: 'nowrap', display: 'flex', alignItems: 'flex-start' }}>{spousename}</div>
                </Textfit>,
                <Textfit key={"m" + ntm + wt + nameposchange} style={{ top: nameposchange ? `calc( 35% - ${(title_1.mobilesimulator_ht) / 2}px - 10px )` : `calc( 50% - ${(title_1.mobilesimulator_ht) / 2}px - 10px )`, height: title_1.mobilesimulator_ht, width: wt - 60, fontFamily: secff, color: fc, margin: '10px 30px', display: 'flex', flexDirection: 'column', placeContent: 'center', position: 'relative', lineHeight: 'normal', alignItems: 'center', border: debug_guideline ? '1px solid black' : 'none' }}>
                    <div style={{ height: title_1.mobilesimulator_ht * title_1.title_layer1, width: ((wt - 20) * 0.95) - 50, placeContent: 'center', whiteSpace: 'nowrap', display: 'flex', alignItems: 'flex-end' }}>{name}</div>
                    <div style={{ height: title_1.mobilesimulator_ht * title_1.title_layer2, width: ((wt - 20) * 0.95) - 50, placeContent: 'center', whiteSpace: 'nowrap', display: 'flex', alignItems: 'flex-start' }}>{"& " + spousename}</div>
                </Textfit>,
                <Textfit key={"m" + ntm + wt + nameposchange} style={{ top: nameposchange ? `calc( 35% - ${(title_1.mobilesimulator_ht) / 2}px - 10px )` : `calc( 50% - ${(title_2.mobilesimulator_ht) / 2}px - 10px )`, height: title_2.mobilesimulator_ht, width: wt - 60, fontFamily: secff, color: fc, margin: '10px 30px', padding: '0px 10px', placeContent: 'center', whiteSpace: 'nowrap', display: 'flex', position: 'relative', alignItems: 'center', border: debug_guideline ? '1px solid black' : 'none' }}>
                    {name + ' & ' + spousename}
                </Textfit>
            ][ntm]
        }
        </div>
        : [
            <Textfit key={"m" + ntm + wt + nameposchange} style={{ top: nameposchange ? `calc( 30% - ${(title_1.mobilesimulator_ht) / 2}px - 10px )` : `calc( 50% - ${(title_0.mobilesimulator_ht) / 2}px - 10px )`, height: title_0.mobilesimulator_ht, width: wt - 60, fontFamily: secff, color: fc, margin: '10px 30px', display: 'flex', flexDirection: 'column', placeContent: 'center', fontWeight: 'normal', position: 'relative', lineHeight: 'normal', alignItems: 'center', border: debug_guideline ? '1px solid black' : 'none' }}>
                <div style={{ height: title_0.mobilesimulator_ht * title_0.title_layer1, width: ((wt - 20) * 0.95) - 50, placeContent: 'center', whiteSpace: 'nowrap', display: 'flex', alignItems: 'flex-end' }}>{name}</div>
                <Textfit style={{ height: title_0.mobilesimulator_ht * title_0.title_layer2, width: ((wt - 20) * 0.95) - 50, placeContent: 'center', whiteSpace: 'nowrap', display: 'flex', alignItems: 'baseline' }}>{'&'}</Textfit>
                <div style={{ height: title_0.mobilesimulator_ht * title_0.title_layer3, width: ((wt - 20) * 0.95) - 50, placeContent: 'center', whiteSpace: 'nowrap', display: 'flex', alignItems: 'flex-start' }}>{spousename}</div>
            </Textfit>,
            <Textfit key={"m" + ntm + wt + nameposchange} style={{ top: nameposchange ? `calc( 35% - ${(title_1.mobilesimulator_ht) / 2}px - 10px )` : `calc( 50% - ${(title_1.mobilesimulator_ht) / 2}px - 10px )`, height: title_1.mobilesimulator_ht, width: wt - 60, fontFamily: secff, color: fc, margin: '10px 30px', display: 'flex', flexDirection: 'column', placeContent: 'center', position: 'relative', lineHeight: 'normal', alignItems: 'center', border: debug_guideline ? '1px solid black' : 'none' }}>
                <div style={{ height: title_1.mobilesimulator_ht * title_1.title_layer1, width: ((wt - 20) * 0.95) - 50, placeContent: 'center', whiteSpace: 'nowrap', display: 'flex', alignItems: 'flex-end' }}>{name}</div>
                <div style={{ height: title_1.mobilesimulator_ht * title_1.title_layer2, width: ((wt - 20) * 0.95) - 50, placeContent: 'center', whiteSpace: 'nowrap', display: 'flex', alignItems: 'flex-start' }}>{"& " + spousename}</div>
            </Textfit>,
            <Textfit key={"m" + ntm + wt + nameposchange} style={{ top: nameposchange ? `calc( 35% - ${(title_1.mobilesimulator_ht) / 2}px - 10px )` : `calc( 50% - ${(title_2.mobilesimulator_ht) / 2}px - 10px )`, height: title_2.mobilesimulator_ht, width: wt - 60, fontFamily: secff, color: fc, margin: '10px 30px', padding: '0px 10px', placeContent: 'center', whiteSpace: 'nowrap', display: 'flex', position: 'relative', alignItems: 'center', border: debug_guideline ? '1px solid black' : 'none' }}>
                {name + ' & ' + spousename}
            </Textfit>
        ][ntm]

    const seq_dom = {
        'maintext': maintext_dom,
        'location': location_dom,
        'date': datetime_dom,
        'howwemet': howwemet_dom(),
        'proposal': proposal_dom(),
        'schedule': schedule_dom,
        'qna': qns_dom,
        'map': map_dom,
        'rsvp': rsvp_dom,
    }

    return <div className="simulator_noselect" style={{ width: 'max-content', height: 'max-content', display: 'flex', flexDirection: 'row' }}>
        <div className="T1simmobile_root" style={{ paddingTop:menutype==='stickytopnav'?30:0, width: wt, height: ht, backgroundColor: templatecolor, backgroundImage: `url(${textures[0]})`, display: 'flex', flexDirection: 'column', borderRadius: 5, boxShadow: 'rgba(0, 0, 0, 0.13) 0px 10px 50px', position: 'relative', overflow: 'hidden', fontFamily: css_map['othertext'].fontFamily }}>
            <div className="T1simmobile_root2" ref={scrollcontainerref} style={{ width: '100%', height: ht, overflowY: 'overlay', overflowX: 'hidden', paddingBottom: 80 }}>
                <div style={{ width: `100%`, height: ht, overflowWrap: 'break-word', position: 'relative', display: 'flex' }}>
                    {title_dom}
                    <T1border styleborder={styleborder} simulatordevice={'MOBILE'} styleborderwt={styleborderwt} styleborderbr={styleborderbr} stylebordercolor={stylebordercolor} />
                    {/* <div style={{ width: `calc( 100% - 20px ) `, height: 'calc( 100% - 20px )', border: styleborder === 0 ? '0.5px solid transparent' : '0.5px solid #707070', position: 'absolute', borderRadius: 10, margin: 10, top: 0, left: 0 }} /> */}
                    {bg()}
                </div>
                {mainimg_dom}
                {displayseq.map((seqkey) => seq_dom[seqkey])}

                {/* {maintext_dom}
                {location_dom}
                {datetime_dom()}
                {howwemet_dom()}
                {proposal_dom()}
                {schedule_dom}
                {qns_dom}
                {map_dom}
                {rsvp_dom} */}
            </div>
            {nav_dom}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', width: 40, placeContent: 'flex-end' }}>
            <button className="simulator_mobileclickscroll simulator_noselect" onClick={scrollup} {...onLongPress(scrollup)} >
                {iconwrapper(ARROWDOWN_ICON, { width: 25, height: 25, marginBottom: 5, transform: 'rotate(180deg)' })}
            </button>
            <button className="simulator_mobileclickscroll simulator_noselect" onClick={scrolldown} {...onLongPress(scrolldown)} >
                {iconwrapper(ARROWDOWN_ICON, { width: 25, height: 25 })}
            </button>
        </div>
    </div>
}

export default T1simmobile
