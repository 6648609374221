import android from './android.png'
import ios from './ios.png'
import {  production } from '../../config/constants'
import { S3CLOUD_CLOUDFRONT_USERLOGIN } from "../../config/s3directories"

let first, fav, host, rsvp, msg, budget, timelineimg, imguserloginmobile, ultxtbgimg

imguserloginmobile = S3CLOUD_CLOUDFRONT_USERLOGIN + 'mobiletopclear.png'
if (production) {
    ultxtbgimg = S3CLOUD_CLOUDFRONT_USERLOGIN + 'txtbg.png'
    first = S3CLOUD_CLOUDFRONT_USERLOGIN + "1.png"
    fav = S3CLOUD_CLOUDFRONT_USERLOGIN + "fav.png"
    host = S3CLOUD_CLOUDFRONT_USERLOGIN + "host.png"
    rsvp = S3CLOUD_CLOUDFRONT_USERLOGIN + "RSVP.gif"
    msg = S3CLOUD_CLOUDFRONT_USERLOGIN + "msg.png"
    budget = S3CLOUD_CLOUDFRONT_USERLOGIN + '4.png'
    timelineimg = S3CLOUD_CLOUDFRONT_USERLOGIN + "3.png"
}
else {
    ultxtbgimg = "https://i.ibb.co/0GwQ8q0/1-2.png"
    first = "https://mir-s3-cdn-cf.behance.net/project_modules/disp/6584cd100691491.5f0e5d2fe586b.png"
    fav = "https://mir-s3-cdn-cf.behance.net/project_modules/disp/429257100691491.5f0e5d2fe5e3a.png"
    host = "https://mir-s3-cdn-cf.behance.net/project_modules/disp/dfac2b100691491.5f0e5d96a87e0.png"
    rsvp = "https://pro2-bar-s3-cdn-cf3.myportfolio.com/2e0edf85-5483-4156-b927-8a4f2f97f191/4713e3ef-211d-4491-a85f-3fc2db8f146e_rw_600.gif?h=e1b0c8c7746ecf73a848883ba2f87953"
    msg = "https://mir-s3-cdn-cf.behance.net/project_modules/disp/726de1100691491.5f0e5d2fe53d8.png"
    budget = 'https://mir-s3-cdn-cf.behance.net/project_modules/disp/4f1979100691491.5f0e5a87a6bbc.png'
    timelineimg = "https://mir-s3-cdn-cf.behance.net/project_modules/disp/ddf2da100691491.5f0e5a87a73d9.png"
}
export { android, ios, first, fav, host, rsvp, msg, budget, timelineimg, ultxtbgimg, imguserloginmobile }